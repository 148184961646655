"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.product = void 0;
var getRegionAndLang = function (region, lang) {
    if (region === void 0) { region = null; }
    if (lang === void 0) { lang = null; }
    var defaultRegion = 'es';
    var defaultLang = 'es';
    var language = lang ? "/".concat(lang.toLowerCase()) : null;
    if (!region || (region === null || region === void 0 ? void 0 : region.toLowerCase()) === defaultRegion) {
        return (lang === null || lang === void 0 ? void 0 : lang.toLowerCase()) === defaultLang ? '' : language !== null && language !== void 0 ? language : '';
    }
    return "/".concat(region.toLowerCase()).concat(language !== null && language !== void 0 ? language : "/".concat(defaultLang));
};
var product = function (data, region, lang, productPage) {
    var _a, _b, _c, _d;
    if (region === void 0) { region = 'es'; }
    if (lang === void 0) { lang = 'es'; }
    if (productPage === void 0) { productPage = true; }
    var id = data.id, brand = data.brand, name = data.name, detail = data.detail, variant = data.variant, variants = data.variants, slug = data.slug, sku = data.sku, pack = data.pack;
    // const media = currentVariant?.media
    var image = productPage ? (_d = (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.header) === null || _a === void 0 ? void 0 : _a.image) === null || _b === void 0 ? void 0 : _b.default) === null || _c === void 0 ? void 0 : _c['1x']) === null || _d === void 0 ? void 0 : _d.regular : null;
    var offers = function (item) {
        var _a;
        return (__assign(__assign(__assign(__assign({ "@type": "Offer" }, (slug && { "url": "www.nexia.com/".concat(getRegionAndLang(region, lang), "/").concat(slug[lang]) })), { "priceCurrency": 'EUR' }), (item.price && { "price": "".concat(item.price) })), { 
            // ...(item.price.validUntil && { "priceValidUntil": `${item.price.validUntil}` }),
            "itemCondition": "https://schema.org/NewCondition", "availability": "https://schema.org/".concat(((_a = item.stock) !== null && _a !== void 0 ? _a : 2 < 1) ? 'OutOfStock' : 'InStock'), 
            // "inventoryLevel": `${item.stock}`,
            "sku": "".concat(item.sku), "seller": {
                "@type": "Organization",
                "name": "Nexia"
            } }));
    };
    var productSchema = __assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (productPage && { "@context": "https://schema.org/" })), { "@type": "Product", "name": name }), (image && { "image": image })), ((detail === null || detail === void 0 ? void 0 : detail.description) && { "description": "".concat(detail === null || detail === void 0 ? void 0 : detail.description) })), (data.mpn && { "mpn": "".concat(data.mpn) })), (brand && { "brand": { "@type": "Brand", "name": "".concat(brand.name) } })), { "sku": id });
    return productPage ? JSON.stringify(productSchema) : productSchema;
};
exports.product = product;
