"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRegularButton = exports.getInvertedButtonUnderline = exports.getButtonUnderline = void 0;
var styled_components_1 = require("styled-components");
var _1 = require(".");
var getButtonUnderline = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.color, color = _c === void 0 ? 'black' : _c;
    return (0, styled_components_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    ", "\n    color: ", ";\n    position: relative;\n\n    a {\n      color: ", ";\n      display: inline-block;\n    }\n\n    span {\n      position: absolute;\n      bottom: 0;\n      height: 0.03rem;\n      opacity: 0.4;\n      width: 0;\n      background-color: ", ";\n      left: unset;\n      right: 0;\n      transition: width 300ms ease, left 300ms ease, right 300ms ease;\n      bottom: -", ";\n\n      ", "\n    }\n\n    &:hover,\n    &.active {\n      span {\n        left: 0;\n        right: unset;\n        width: 100%;\n      }\n    }\n  "], ["\n    ", "\n    color: ", ";\n    position: relative;\n\n    a {\n      color: ", ";\n      display: inline-block;\n    }\n\n    span {\n      position: absolute;\n      bottom: 0;\n      height: 0.03rem;\n      opacity: 0.4;\n      width: 0;\n      background-color: ", ";\n      left: unset;\n      right: 0;\n      transition: width 300ms ease, left 300ms ease, right 300ms ease;\n      bottom: -", ";\n\n      ", "\n    }\n\n    &:hover,\n    &.active {\n      span {\n        left: 0;\n        right: unset;\n        width: 100%;\n      }\n    }\n  "])), (0, _1.getP18)(), function (_a) {
        var theme = _a.theme;
        return theme.colors[color];
    }, function (_a) {
        var theme = _a.theme;
        return theme.colors[color];
    }, function (_a) {
        var theme = _a.theme;
        return theme.colors[color];
    }, (0, _1.getRelative)(4, 'mobile'), _1.mediaQuery.greaterThan('tablet')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        bottom: -", ";\n        opacity: 0.2;\n      "], ["\n        bottom: -", ";\n        opacity: 0.2;\n      "])), (0, _1.getRelative)(4, 'desktop')));
};
exports.getButtonUnderline = getButtonUnderline;
var getInvertedButtonUnderline = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.color, color = _c === void 0 ? 'black' : _c;
    return (0, styled_components_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    ", "\n    color: ", ";\n    position: relative;\n\n    a {\n      color: ", ";\n      display: inline-block;\n    }\n\n    span {\n      position: absolute;\n      bottom: 0;\n      height: 0.03rem;\n      opacity: 0.4;\n      width: 100%;\n      background-color: ", ";\n      left: 0;\n      right: unset;\n      transition: width 300ms ease, left 300ms ease, right 300ms ease;\n      bottom: -", ";\n\n      ", "\n    }\n\n    &:hover,\n    &.active {\n      span {\n        left: unset;\n        right: 0;\n        width: 0;\n      }\n    }\n\n    &:disabled {\n      opacity: .4;\n    }\n  "], ["\n    ", "\n    color: ", ";\n    position: relative;\n\n    a {\n      color: ", ";\n      display: inline-block;\n    }\n\n    span {\n      position: absolute;\n      bottom: 0;\n      height: 0.03rem;\n      opacity: 0.4;\n      width: 100%;\n      background-color: ", ";\n      left: 0;\n      right: unset;\n      transition: width 300ms ease, left 300ms ease, right 300ms ease;\n      bottom: -", ";\n\n      ", "\n    }\n\n    &:hover,\n    &.active {\n      span {\n        left: unset;\n        right: 0;\n        width: 0;\n      }\n    }\n\n    &:disabled {\n      opacity: .4;\n    }\n  "])), (0, _1.getP18)(), function (_a) {
        var theme = _a.theme;
        return theme.colors[color];
    }, function (_a) {
        var theme = _a.theme;
        return theme.colors[color];
    }, function (_a) {
        var theme = _a.theme;
        return theme.colors[color];
    }, (0, _1.getRelative)(4, 'mobile'), _1.mediaQuery.greaterThan('tablet')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        bottom: -", ";\n        opacity: 0.2;\n      "], ["\n        bottom: -", ";\n        opacity: 0.2;\n      "])), (0, _1.getRelative)(4, 'desktop')));
};
exports.getInvertedButtonUnderline = getInvertedButtonUnderline;
var getRegularButton = function () {
    return (0, styled_components_1.css)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    ", "\n    color: ", ";\n    background-color: ", ";\n    height: ", ";\n    border-radius: ", ";\n    padding: 0 ", " ", ";\n\n    ", "\n\n    &:hover {\n      background-color: ", ";\n    }\n\n    &:disabled {\n      background-color: ", ";\n    }\n\n    a {\n      color: ", ";\n      display: inline-block;\n    }\n  "], ["\n    ", "\n    color: ", ";\n    background-color: ", ";\n    height: ", ";\n    border-radius: ", ";\n    padding: 0 ", " ", ";\n\n    ", "\n\n    &:hover {\n      background-color: ", ";\n    }\n\n    &:disabled {\n      background-color: ", ";\n    }\n\n    a {\n      color: ", ";\n      display: inline-block;\n    }\n  "])), (0, _1.getP18)(), function (_a) {
        var theme = _a.theme;
        return theme.colors.white;
    }, function (_a) {
        var theme = _a.theme;
        return theme.colors.black;
    }, (0, _1.getRelative)(40, 'mobile'), (0, _1.getRelative)(20, 'mobile'), (0, _1.getRelative)(40, 'mobile'), (0, _1.getRelative)(5, 'mobile'), _1.mediaQuery.greaterThan('tablet')(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      height: ", ";\n      border-radius: ", ";\n      padding: 0 ", " ", ";\n    "], ["\n      height: ", ";\n      border-radius: ", ";\n      padding: 0 ", " ", ";\n    "])), (0, _1.getRelative)(40, 'desktop'), (0, _1.getRelative)(20, 'desktop'), (0, _1.getRelative)(40, 'desktop'), (0, _1.getRelative)(5, 'desktop')), function (_a) {
        var theme = _a.theme;
        return (0, _1.hex2Rgba)(theme.colors.black, .7);
    }, function (_a) {
        var theme = _a.theme;
        return (0, _1.hex2Rgba)(theme.colors.black, .4);
    }, function (_a) {
        var theme = _a.theme;
        return theme.colors.white;
    });
};
exports.getRegularButton = getRegularButton;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
