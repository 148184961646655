import LOCALES_ES from '../public/locales/es.json'
import LOCALES_EN from '../public/locales/en.json'
import LOCALES_IT from '../public/locales/it.json'
import LOCALES_FR from '../public/locales/fr.json'

const DEFAULT_LOCALES = {
  'es': LOCALES_ES,
  'en': LOCALES_EN,
  'fr': LOCALES_FR,
  'it': LOCALES_IT,
  'default': LOCALES_EN
}

export default DEFAULT_LOCALES