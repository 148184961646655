"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.products = void 0;
var products = function (data) {
    var locale = data.locale, products = data.products;
    var productsPage = {
        "@context": "https://schema.org",
        "@type": "ItemList",
        "itemListElement": [
            products.map(function (product, idx) { return ({
                "@type": "ListItem",
                "position": "".concat(idx + 1),
                "url": "https://nexia.es/".concat(locale === 'es' ? '' : "".concat(locale, "/"), "product/").concat(product.slug[locale])
            }); })
        ]
    };
    return JSON.stringify(productsPage);
};
exports.products = products;
