import { memo, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import { mediaQuery, createGrid, getRelative, getTransition, getP50, getP24 } from '@/styles'
import { SliderFlickity } from '@dy/commons/components'
import { ProductCard } from '../commons'
import { useI18n } from '@/i18n'

interface ISectionFavourites {
  data: any,
  isSmallHeadline?: any
}

const titleVariants = {
  initial: {
    y: '20px',
    opacity: 0,
    transition: {
      ...getTransition()
    }
  },
  animateText: {
    y: '0px',
    opacity: 1,
    transition: {
      ...getTransition(),
      ease: [.32,0,.29,.99],
      duration: 1
    }
  },
  exit: {
    y: '20px',
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .5
    }
  },
}

const Section = styled.section<any>`
  ${createGrid()}

  h2 {
    ${getP50()}
    grid-area: 1 / 1 / span 1 / span 6;
    margin-bottom: ${getRelative(60, 'mobile')};
    text-align: left;

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 1 / 1 / span 1 / span 6;
      margin-bottom: ${getRelative(90, 'desktop')};
      margin-left: ${getRelative(40, 'desktop')};
    `}
  }

  &.isSmallHeadline h2 {
    ${getP24()}
    margin-bottom: ${getRelative(40, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 1 / 1 / span 1 / span 3;
      margin-bottom: ${getRelative(40, 'desktop')};
    `}
  }

  > div {
    grid-area: 2 / 1 / span 1 / span 6;
    margin-right: -${getRelative(23, 'mobile')};
    margin-left: -${getRelative(23, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 2 / 1 / span 1 / span 12;
      margin: 0;
    `}

    article {
      margin-right: ${getRelative(10, 'mobile')};

      ${mediaQuery.greaterThan<any>('tablet')`
        margin-right: ${getRelative(27, 'desktop')};
      `}
    }

    .flickity-slider article:first-of-type {
      margin-right: 0;
      overflow: hidden;
      width: ${getRelative(23, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        width: ${getRelative(40, 'desktop')};
        margin-right: 0;
      `}

      div {
        .product-card-image.no-img {
          height: 100%;
          opacity: 0;
          user-select: none;
          width: 100%;
        }
      }
    }
  }
`

const getFavourites = (products) => products.map((product, idx) => {
  return (<ProductCard key={product.name + idx} data={product}/>)
})

export const SectionFavourites = memo<ISectionFavourites>(({ data, isSmallHeadline = false }) => {
  const Favourites = useMemo(() => getFavourites([{}, ...data]), [data])

  const { locales: { home: { favourites } } } = useI18n()
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: '0px 0px -100px 0px'
  })

  useEffect(() => {
    if(inView) {
      controls.start('animateText')
    }
  }, [inView])

  return (
    <Section className={isSmallHeadline ? 'isSmallHeadline' : ''}>
        <motion.h2 ref={ref} variants={titleVariants} animate={controls} initial={'initial'}>{favourites}</motion.h2>
        <div>
          <SliderFlickity className={'slider--product-card'} loop={false} pageDots={false} cellAlign='left'>
            {Favourites}
          </SliderFlickity>
        </div>
    </Section>
  )
})
