// const createFunction = (fn, number) => () => fn(number)

const parseSearchData = (data) => {
  const _data = {}
  const _countries = {}
  let isOnline = false

  for (let idx = 0; idx < data.length; idx++) {
    const { country, city, direction } = data[idx]

    if (typeof direction === 'undefined') {
      isOnline = true
    }

    if (!_countries[country]) {
      _countries[country] = new Set([ city ])
    } else {
      _countries[country].add(city)
    }

    const keyData = isOnline ? country : country + city
    if (!_data[keyData]) {
      _data[keyData] = [ data[idx] ]
    } else {
      _data[keyData].push(data[idx])
    }
  }

  return [{ shop: { data: _data, countries: _countries }, type: isOnline ? 'shop-online' : 'shop' }]
}

const findFirstLevelUrl = ( links) => {
  let firstLevelLink = null
  
  firstLevelLink = links.find((link) => {
    return link.url !== null
  }) 

  if (!firstLevelLink) {
    firstLevelLink = links[0].links.find((link) => {
      return link.url !== null
    })
  }
  return firstLevelLink?.url ?? null
}

const parseLevel = (dataObj, mediaType, currentObj, queue, maxLevel, fn) => {
  const { levelBackwards, ownLevel, data, type } = dataObj
  let nextLevel = maxLevel
  currentObj[ownLevel] = type === 'shop' ? parseSearchData(data) : data.map(({ type, title, links, url = '/#', media, caption }) => {    
    if (type === 'button'){
      nextLevel += 1
      
      let firstLevelUrl = null
      if (ownLevel === 0) {
        firstLevelUrl = findFirstLevelUrl(links)
      }

      queue.push({ levelBackwards: ownLevel, data: links, ownLevel: nextLevel })

      return { type, title, url: firstLevelUrl ? firstLevelUrl : null, media, caption, childPosition: nextLevel }
    }

    if (type === 'simple') {
      if (links) {
        nextLevel += 1
        queue.push({ levelBackwards: ownLevel, data: links, ownLevel: nextLevel })

        return { type, title, url, media, caption, childPosition: nextLevel }
      } else {
        const object: any = { type, title, url, media, caption }
  
        return object
      }
    }

    if (type === 'alternative') {
      nextLevel += 1
      queue.push({ levelBackwards: ownLevel, data: links, ownLevel: nextLevel })

      return { type, title, url: null, media, caption, childPosition: nextLevel }
    }

    if (type === 'title') {
      nextLevel += 1
      queue.push({ levelBackwards: ownLevel, data: links, ownLevel: nextLevel })

      return { type, title, url: null, media, caption, childPosition: nextLevel }
    }
  })

  if (levelBackwards !== null && mediaType === 'mobile') currentObj[ownLevel].unshift({ title: 'arrow', url: null, childPosition: levelBackwards })

  if (queue.length > 0) {
    const dataObj = queue.pop()

    return parseLevel(dataObj, mediaType, currentObj, queue, nextLevel, fn)
  }

  return currentObj
}

export const parseData = (data, mediaType = null, fn = null) => {
  const dataObj = { levelBackwards: null, data, ownLevel: 0 }
  return parseLevel(dataObj, mediaType, {}, [], 0, fn)
}
