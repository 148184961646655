import { memo, useEffect } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { Figure } from '@dy/commons/components'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import { ContentTypeImage } from '@dy/commons/src/schema'
import { imgVariants } from '../../commons'
import { parserMedia } from '@dy/commons/utils'
import { getP50 } from '@dy/commons/styles'

const Wrapper = styled.div`
  overflow: hidden;
  height: inherit;
  background-color: #EFEFEF;
  width: 100%;

  > div {
    height: inherit;
    width: 100%;
    position: relative;
  }

  a {
    width: 100%;
    height: 100%;
  }

  figure {
    img {
      transition: 1000ms transform ${({ theme: { ease } }) => ease};
    }

    > span {
      height: 100%;
    }
  }

  &:hover {
    a img {
      transform: scale(1.1);
    }
  }

  .text-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    > span {
      color: white;
      ${getP50()}
    }
  }
`
interface IContentImage {
  data: ContentTypeImage
}

const sizes = '(min-width: 768px) 100vw, 90vw'
export const ContentImage = memo(({ data }:IContentImage) => {
  // @ts-ignore
  const { media, link_path, link_title } = data
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: `0px 0px 0px 0px`
  })

  useEffect(() => {
    if(inView) {
      controls.start('animate')
    }
  }, [inView])

  return (link_path ?
    <Wrapper>
      <Link scroll={false} href={link_path}>
        <a>
          <motion.div ref={ref} variants={imgVariants} animate={'animate'} initial={'initial'}>
            <Figure media={parserMedia(media)} layout='fill' quality={99} sizes={sizes} />
            <div className='text-wrapper'>
              {link_title && <span>{link_title}</span>}
            </div>
          </motion.div>
        </a>
      </Link>
    </Wrapper>
    :
    <Wrapper>
      <motion.div ref={ref} variants={imgVariants} animate={'animate'} initial={'initial'}>
        <Figure media={parserMedia(media)} layout='fill' quality={99} sizes={sizes} />
        <div className='text-wrapper'>
          {link_title && <span>{link_title}</span>}
        </div>
      </motion.div>
    </Wrapper>
  )
})
