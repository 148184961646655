import { memo, forwardRef, useState } from 'react'
import styled from 'styled-components'
import { mediaQuery, getRelative, hex2Rgba, getP18 } from '@/styles'

interface IFileUpload {
  name?: any,
  label: any,
  labelId: any,
  registerProps?: any,
  ref?: any
}

const Div = styled.div`
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: ${getRelative(20, 'mobile')};
  height: ${getRelative(40, 'mobile')};
  display: flex;
  place-content: center;
  transition: 300ms background-color ${({ theme: { ease } }) => ease}, 300ms color ${({ theme: { ease } }) => ease}, 300ms border ${({ theme: { ease } }) => ease};
  margin-bottom: ${getRelative(55, 'mobile')};

  ${mediaQuery.greaterThan('tablet')`
    height: ${getRelative(40, 'desktop')};
    border-radius: ${getRelative(20, 'desktop')};
    max-width: ${getRelative(321, 'desktop')};
    margin-bottom: ${getRelative(60, 'desktop')};
  `}

  &:hover {
    background-color: ${({ theme }) => hex2Rgba(theme.colors.black, .7)};
    border: 1px solid ${({ theme }) => hex2Rgba(theme.colors.black, 0)};
    color: ${({ theme }) => theme.colors.white};
  }

  &.active {
    background-color: ${({ theme }) => hex2Rgba(theme.colors.black, .4)};
    border: 1px solid ${({ theme }) => hex2Rgba(theme.colors.black, 0)};
  }
`

const Input = styled.input<any>`
  opacity: 0;
  width: 0;
`

const Label = styled.label<any>`
  ${getP18()}
  cursor: pointer;
  display: block;
  text-align: center;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 ${getRelative(93, 'mobile')};

  ${mediaQuery.greaterThan('tablet')`
    padding: 0 ${getRelative(93, 'desktop')};
  `}
`

export const FileUpload = memo<IFileUpload>(forwardRef(({ name = '', label, labelId, registerProps = null}, ref) => {
  const [currentFile, setCurrentFile] = useState(label)

  const handleOnChange = (e) => {
    if(e.target.value) {
      setCurrentFile(e.target.value)
    } else {
      setCurrentFile(label)
    }
  }

  return (
    <Div className={currentFile === label ? '' : 'active'}>
      <Label htmlFor={labelId}>{currentFile}</Label>
      <Input type='file' id={labelId} name={name} ref={ref} {...(registerProps && { ...registerProps })} onChange={handleOnChange} />
    </Div>
  )
}))
