"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.pushAnalyticsEvent = void 0;
var getSelectItemObject = function (data) {
    var _a;
    var name = data.name, brand = data.brand, categories = data.categories, sku = data.sku, variant = data.variant, idx = data.idx, ga_list = data.ga_list, locale = data.locale;
    categories === null || categories === void 0 ? void 0 : categories.reverse();
    var categoriesKeys = __assign(__assign(__assign(__assign({}, ((categories === null || categories === void 0 ? void 0 : categories[0]) && { 'item_category': categories[0].name })), ((categories === null || categories === void 0 ? void 0 : categories[1]) && { 'item_category2': categories[1].name })), ((categories === null || categories === void 0 ? void 0 : categories[2]) && { 'item_category3': categories[2].name })), ((categories === null || categories === void 0 ? void 0 : categories[3]) && { 'item_category4': categories[2].name }));
    return {
        'event': 'select_item',
        'ecommerce': {
            'items': [__assign(__assign({ 'item_name': name, 'item_id': variant ? variant.sku : sku, 'discount': variant.prices.retail_price_inc_tax > 0 ? (variant.prices.retail_price_inc_tax - variant.prices.price_inc_tax) : 0, 'index': idx, 'item_list_name': ga_list.name, 'item_list_id': ga_list.id, 'affiliation': "Nexia ".concat(locale, " Store"), 'item_brand': (_a = brand === null || brand === void 0 ? void 0 : brand.name) !== null && _a !== void 0 ? _a : 'Packs' }, (categories && categoriesKeys)), { 'item_variant': variant.name, 'price': variant.prices.price_inc_tax, 'currency': 'EUR', 'quantity': 1 })],
            'item_list_name': ga_list.name,
            'item_list_id': ga_list.id
        }
    };
};
var getViewItemObject = function (data) {
    var name = data.name, brand = data.brand, categories = data.categories, id = data.id, idx = data.idx, ga_list = data.ga_list;
    categories === null || categories === void 0 ? void 0 : categories.reverse();
    var categoriesKeys = __assign(__assign(__assign(__assign({}, ((categories === null || categories === void 0 ? void 0 : categories[0]) && { 'item_category': categories[0].name })), ((categories === null || categories === void 0 ? void 0 : categories[1]) && { 'item_category2': categories[1].name })), ((categories === null || categories === void 0 ? void 0 : categories[2]) && { 'item_category3': categories[2].name })), ((categories === null || categories === void 0 ? void 0 : categories[3]) && { 'item_category4': categories[2].name }));
    return {
        'event': 'view_item',
        'ecommerce': {
            'items': [__assign(__assign(__assign(__assign({ 'item_name': name, 'item_id': id, 
                    // 'price': price,
                    'item_brand': brand === null || brand === void 0 ? void 0 : brand.name }, (categories && categoriesKeys)), (ga_list && {
                    'item_list_name': ga_list.name,
                    'item_list_id': ga_list.id
                })), (idx && { 'index': idx })), { 'quantity': 1 })]
        }
    };
};
var getViewItemListObject = function (data) {
    var products = data.products, ga_list = data.ga_list;
    var items = products.map(function (product, idx) {
        var _a;
        var name = product.name, brand = product.brand, categories = product.categories, variant = product.variant;
        categories === null || categories === void 0 ? void 0 : categories.reverse();
        var categoriesKeys = __assign(__assign(__assign(__assign({}, ((categories === null || categories === void 0 ? void 0 : categories[0]) && { 'item_category': categories[0].name })), ((categories === null || categories === void 0 ? void 0 : categories[1]) && { 'item_category2': categories[1].name })), ((categories === null || categories === void 0 ? void 0 : categories[2]) && { 'item_category3': categories[2].name })), ((categories === null || categories === void 0 ? void 0 : categories[3]) && { 'item_category4': categories[2].name }));
        return __assign(__assign({ 'item_name': name, 'item_id': variant.sku, 'price': variant.prices.price_inc_tax, 'item_brand': (_a = brand === null || brand === void 0 ? void 0 : brand.name) !== null && _a !== void 0 ? _a : 'Packs' }, (categories && categoriesKeys)), { 'item_variant': variant.name, 'item_list_name': ga_list.name, 'item_list_id': ga_list.id, 'index': idx + 1, 'quantity': 1 });
    });
    return {
        'event': 'view_item_list',
        'ecommerce': {
            'items': items
        }
    };
};
var getCartItemObject = function (data, add) {
    var _a;
    if (add === void 0) { add = true; }
    var name = data.name, brand = data.brand, categories = data.categories, variant = data.variant, units = data.units;
    categories === null || categories === void 0 ? void 0 : categories.reverse();
    var categoriesKeys = __assign(__assign(__assign(__assign({}, ((categories === null || categories === void 0 ? void 0 : categories[0]) && { 'item_category': categories[0].name })), ((categories === null || categories === void 0 ? void 0 : categories[1]) && { 'item_category2': categories[1].name })), ((categories === null || categories === void 0 ? void 0 : categories[2]) && { 'item_category3': categories[2].name })), ((categories === null || categories === void 0 ? void 0 : categories[3]) && { 'item_category4': categories[2].name }));
    return {
        'event': add ? 'add_to_cart' : 'remove_from_cart',
        'ecommerce': {
            'items': [__assign(__assign(__assign(__assign(__assign({ 'item_name': name, 'item_id': variant.sku, 'price': variant.prices.price_inc_tax, 'item_brand': (_a = brand === null || brand === void 0 ? void 0 : brand.name) !== null && _a !== void 0 ? _a : 'Packs' }, (categories && categoriesKeys)), { 'item_variant': variant.name }), (variant.ga_list && {
                    'item_list_name': variant.ga_list.name,
                    'item_list_id': variant.ga_list.id
                })), (variant.idx && { 'index': variant.idx })), { 'quantity': units !== null && units !== void 0 ? units : 1 })]
        }
    };
};
// Data from BC
var getCheckoutObj = function (data) {
    var items = data.line_items.physical_items.map(function (item) {
        var name = item.name, extended_sale_price = item.extended_sale_price, sku = item.sku, variant_id = item.variant_id, quantity = item.quantity;
        return {
            'item_name': name,
            'item_id': sku,
            'price': extended_sale_price,
            'item_variant': variant_id,
            'quantity': quantity
        };
    });
    return {
        'event': 'begin_checkout',
        'ecommerce': {
            'items': items
        }
    };
};
// Data from BC
var getPurchaseObj = function (data) {
    var _a;
    var id = data.id, cart_amount = data.cart_amount, locale = data.locale, line_items = data.line_items, currency = data.currency;
    var items = line_items.physical_items.map(function (item) {
        var name = item.name, extended_sale_price = item.extended_sale_price, sku = item.sku, variant_id = item.variant_id, quantity = item.quantity;
        return {
            'item_name': name,
            'item_id': sku,
            'price': extended_sale_price,
            'item_variant': variant_id,
            'quantity': quantity
        };
    });
    return {
        'event': 'purchase',
        'ecommerce': {
            'transaction_id': id,
            'affiliation': "Nexia ".concat(locale, " Store"),
            'value': cart_amount,
            // 'tax': '4.90',
            // 'shipping': '5.99',
            'currency': (_a = currency === null || currency === void 0 ? void 0 : currency.code) !== null && _a !== void 0 ? _a : 'EUR',
            'items': items
        }
    };
};
var events = {
    'view_item_list': function (data) { return getViewItemListObject(data); },
    'select_item': function (data) { return getSelectItemObject(data); },
    'view_item': function (data) { return getViewItemObject(data); },
    'add_to_cart': function (data) { return getCartItemObject(data, true); },
    'remove_from_cart': function (data) { return getCartItemObject(data, false); },
    'begin_checkout': function (data) { return getCheckoutObj(data); },
    'purchase': function (data) { return getPurchaseObj(data); },
};
var pushAnalyticsEvent = function (event_type, data) {
    if (typeof window === 'object') {
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({ ecommerce: null });
        window['dataLayer'].push(events[event_type](data));
    }
};
exports.pushAnalyticsEvent = pushAnalyticsEvent;
