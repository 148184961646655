
type TypeRegion = 'es' | 'en' | 'it' | 'fr' | string
type TypeLang = 'es' | 'en' | 'it' | 'fr' | string
type TypeGetRegionAndLang = (region: TypeRegion, lang: TypeLang) => string

export const getRegionAndLang:TypeGetRegionAndLang = (region = null, lang = null) => {
  const defaultRegion = 'es'
  const defaultLang = 'es'
  const language = lang ? `/${lang.toLowerCase()}` : null
  if(!region || region?.toLowerCase() === defaultRegion) {
    return lang?.toLowerCase() === defaultLang ? '' : language ?? ''
  }
  return `/${region.toLowerCase()}${language ?? `/${defaultLang}`}`
}


const DEFAULT_INTERPOLATION  = {
  prefix: '\{{( ?',
  sufix: ' ?)\}}',
  globalRegex: /\{{(.*?)\}}/
}

const createRegex = (key) => new RegExp(DEFAULT_INTERPOLATION.prefix + key + DEFAULT_INTERPOLATION.sufix, 'g')

export const getFormattedLocal = (locale: string, interpolationObject: Object) => {
  let formattedLocal = locale + ''
  const keys = Object.keys(interpolationObject)
  const values = Object.values(interpolationObject)

  if (!keys.length) throw new Error(`Interpolation object can't be empty`)

  keys.forEach((item, idx) => {
    const re = createRegex(item)
    const formattedText = formattedLocal.replace(re, values[idx])

    if (formattedText === formattedLocal) throw new Error(`No interpolation found with key "${item}"`)
    formattedLocal = formattedText + ''
  })

  return formattedLocal
}
