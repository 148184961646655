import { memo, useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { mediaQuery, createGrid, getRelative, getTransition, getP24_3 } from '@/styles'
import { TransitionSlider } from '../commons'
import { useI18n } from '@/i18n'
import { Anchor } from '../product/AnchorPoints'
import { titleVariants, basicVariantsProps } from '../commons'

interface ISectionExplore {
  data: any,
  background?: any,
  onInView?: any
}

const Section = styled.section<any>`
  overflow: hidden;
  ${createGrid()}
  height: ${getRelative(486, 'mobile')};
  background-color: ${({ theme, background }) => background ? theme.colors.creme : 'transparent'};
  position: relative;

  ${mediaQuery.greaterThan('tablet')`
    height: ${getRelative(680, 'desktop')};
  `}

  .text-wrapper {
    z-index: 1;
    height: max-content;
    grid-area: 1 / 1 / span 1 / span 6;
    margin: ${getRelative(60, 'mobile')} 0 ${getRelative(20, 'mobile')};
    grid-area: 1 / 1 / span 1 / span ;

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 1 / 1 / span 1 / span 3;
      margin: ${getRelative(60, 'desktop')} 0 0 ${getRelative(40, 'desktop')};
    `}

    h2 {
      ${getP24_3()}
      text-align: left;
      color: unset;
    }
  }

  figure {
    overflow: hidden;
    position: relative;
  }

  img {
    transition: 1000ms transform ${({ theme: { ease } }) => ease};
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  &.half {
    h2 { color: ${({ theme }) => theme.colors.white}; }
    p { color: unset; }
    figure {
      margin: ${getRelative(132, 'mobile')} auto 0;

      /* WAS 150 */
      ${mediaQuery.greaterThan<any>('tablet')`
        margin: ${getRelative(120, 'desktop')} auto 0;
      `}
    }
  }
`

const changeColorVariants = {
  animate: parentState => ({
    color: parentState === 'full' ? '#FFF' : '#4D4D4D',
    transition: {
      ...getTransition(),
      duration: 1
    }
  })
}

export const SectionExplore = memo<ISectionExplore>(({ data, background = true, onInView = null }) => {
  const [parentState, setParentState] = useState()
  const { locales: { section_explore } } = useI18n()
  const controls = useAnimation()

  const [ref, inView] = useInView({
    rootMargin: '40% 0% 0% 0%',
    threshold: 0.3,
    triggerOnce: false
  })

  const [textRef, textInView] = useInView({
    rootMargin: '0px 0px -100px 0px'
  })

  useEffect(() => {
    if (onInView && inView) {
      onInView('#explore')
    }
  }, [inView])

  useEffect(() => {
    if(textInView) {
      controls.start('animate')
    }
  }, [textInView])

  const wrapperSetParentState = useCallback(val => {
    setParentState(val);
  }, [setParentState]);

  return (
    <Section className={parentState} background={background} ref={ref}>
        <Anchor top={-90} id={'explore'}/>
        <motion.div className='text-wrapper' ref={textRef} variants={titleVariants} {...basicVariantsProps} animate={controls}>
          <AnimatePresence exitBeforeEnter>
            <motion.h2 key={parentState} custom={parentState} variants={changeColorVariants} animate='animate' dangerouslySetInnerHTML={{ __html: section_explore.title }} />
          </AnimatePresence>
        </motion.div>
        <TransitionSlider data={data} parentState={parentState}
        parentStateSetter={wrapperSetParentState} />
    </Section>
  )
})
