"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.home = void 0;
var home = function (data) {
    return JSON.stringify({
        "@context": "https://schema.org",
        "@type": "WebPage",
        "@id": "",
        "name": "Nexia",
        "url": "https://nexia.es",
        "isPartOf": {
            "@type": "WebSite"
        },
        "publisher": __assign(__assign(__assign({ "@type": "Store" }, (data.seo && { "name": "".concat(data.seo.title) })), (data.seo && { "description": "".concat(data.seo.description) })), { "brand": "Nexia", "email": "hola@nexia.es", "url": "https://nexia.es", "sameAs": [
                "https://www.instagram.com/nexialighting/",
                "https://es-es.facebook.com/NexiaLighting/",
                "https://es.linkedin.com/company/nexialighting",
                "https://twitter.com/nexialighting",
                "https://wa.me/0034676081574",
                "https://www.pinterest.es/nexialighting/"
            ], "image": "https://nexia.es/images/two-cols_downloads.jpg", "priceRange": "1 - 1000", "contactPoint": [
                {
                    "@type": "ContactPoint",
                    "email": "holao@nexia.es",
                    "contactType": "customer service",
                    "availableLanguage": ["Spanish", "English", "French", "Catalan", "Italian"]
                }
            ], "logo": "https://nexia.es/images/contact.jpg", "openingHours": "Mo-Fr 09:00-18:30", "currenciesAccepted": "EUR", "paymentAccepted": "Credit Card, Visa" })
    });
};
exports.home = home;
