import { memo, useEffect, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import styled from 'styled-components'
import Link from 'next/link'
import { useI18n } from '@/i18n'
import { mediaQuery, getRelative, getButtonUnderline, getP50, getMargin40 } from '@dy/commons/styles'
import { SliderFlickity, Figure } from '@dy/commons/components'
import { imgVariants } from '../../commons'
import { parserMedia } from '@dy/commons/utils'

const Div = styled.div`
  width: 100%;
`

const Slide = styled.article<any>`
  height: ${getRelative(266, 'mobile')};
  padding: 0 ${getRelative(5, 'mobile')};
  position: relative;

  ${mediaQuery.greaterThan('tablet')`
    height: ${getRelative(530, 'desktop')};
    padding: 0 ${getRelative(12.5, 'desktop')};
  `}

  &:first-child {
    padding-left: ${getRelative(23, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      padding-left: ${getRelative(270, 'desktop')};
    `}
  }

  &:last-child {
    padding-right: ${getRelative(23, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      padding-right: ${getRelative(270, 'desktop')};
    `}
  }

  div.wrapper {
    height: 100%;
    position: relative;
    width: 100%;
  }

  figure {
    align-items: center;
    background-color: ${({ theme }) => theme.colors.background};
    display: flex;
    height: ${getRelative(266, 'mobile')};
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: ${({ size }) => size.width && size.height ? getRelative(266 * size.width / size.height, 'mobile') : 'fit-content'};

    ${mediaQuery.greaterThan('tablet')`
      height: ${getRelative(530, 'desktop')};
      width: ${({ size }) => size.width && size.height ? getRelative(530 * size.width / size.height, 'desktop') : 'fit-content'};
    `}

    img {
      height: 100%;
      mix-blend-mode: multiply;
      object-fit: cover;
      object-position: center;
      width: auto;
      transition: 1000ms transform ${({ theme: { ease } }) => ease};
    }
  }

  &:hover {
    a img {
      transform: scale(1.1);
    }
    .text-wrapper button span {
      left: 0;
      right: unset;
      width: 100%;
    }
  }

  .text-wrapper {
    align-items: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    > span {
      color: white;
      display: inline-block;
      margin-bottom: ${getRelative(10, 'mobile')};
      text-align: center;
      ${getP50()}

      ${mediaQuery.greaterThan('tablet')`
        ${getMargin40()}
      `}
    }

    button {
      ${getButtonUnderline({ color: 'white'})}
    }
  }
`

const Wrapper = styled.div`
  overflow: hidden;
  height: fit-content;
  background-color: #EFEFEF;
`

interface IContentSlider {
  data: any
}

const SlidesSizes = `(min-width: 768px) 80vw, 90vw`
const getSlides = (data, content_page, controls) => data.map((item, idx) => {
  const { slide, text, link, size } = item

  return (
    <Slide key={idx + text + size + 'link'} size={size}>
      {link ?
        <Link scroll={false} href={link}>
          <a>
            <Wrapper>
              <motion.div className='wrapper' variants={imgVariants} animate={controls} initial={'initial'}>
                <Figure lazy={idx > 5} media={parserMedia(slide)} sizes={SlidesSizes} />
                <div className='text-wrapper'>
                  {text && <span>{text}</span>}
                  <button>{content_page.view_more}<span></span></button>
                </div>
              </motion.div>
            </Wrapper>
          </a>
        </Link>
      :
        <Wrapper>
        <motion.div className='wrapper' variants={imgVariants} animate={controls} initial={'initial'}>
          <Figure lazy={idx > 5} media={parserMedia(slide)} sizes={SlidesSizes} />
          <div className='text-wrapper'>
            {text && <span>{text}</span>}
          </div>
        </motion.div>
      </Wrapper>
      }
    </Slide>
  )
})

export const ContentSlider = memo(({ data }:IContentSlider) => {
  const controls = useAnimation()
  const { locales: { content_page } } = useI18n()

  const Slides = useMemo(() => getSlides(data, content_page, controls), [data])

  const [ref, inView] = useInView({
    rootMargin: '0px 0px',
    threshold: 0,
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView) {
      controls.start('animate')
    }
  }, [inView])

  if(!Slides || Slides.length < 1) return null

  return (
    <Div ref={ref}>
      <SliderFlickity className={'slider--content-5-cols'} loop={false} pageDots={false} buttons={false} cellAlign='left'>
        {Slides}
      </SliderFlickity>
    </Div>
  )
})
