"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getP10 = exports.getP12 = exports.getP18 = exports.getP20 = exports.getP20_1 = exports.getP24_3 = exports.getP24_2 = exports.getP24 = exports.getP50 = exports.getP90 = exports.getRelativeREM = void 0;
var styled_components_1 = require("styled-components");
var _1 = require("./");
/***
 * 1rem mobile -> 15px
 * 1rem desktop -> 18px
 */
/***
 * hairlane -> 100
 * ultrathin -> 200
 * ultralight -> 300
 * thin -> 400
 * light -> 500
 */
var REM_MOBILE = 15;
var REM_DESKTOP = 18;
var getRelativeREM = function (value, factor) { return "".concat(value / factor, "rem"); };
exports.getRelativeREM = getRelativeREM;
var getP90 = function () {
    return (0, styled_components_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-size: ", ";\n    font-weight: 200;\n    line-height: ", ";\n    letter-spacing: -.1px;\n\n    ", "\n  "], ["\n    font-size: ", ";\n    font-weight: 200;\n    line-height: ", ";\n    letter-spacing: -.1px;\n\n    ", "\n  "])), (0, exports.getRelativeREM)(44, REM_MOBILE), (0, exports.getRelativeREM)(48, REM_MOBILE), _1.mediaQuery.greaterThan('tablet')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      font-weight: 100;\n      font-size: ", ";\n      line-height: ", ";\n    "], ["\n      font-weight: 100;\n      font-size: ", ";\n      line-height: ", ";\n    "])), (0, exports.getRelativeREM)(90, REM_DESKTOP), (0, exports.getRelativeREM)(94, REM_DESKTOP)));
};
exports.getP90 = getP90;
var getP50 = function () {
    return (0, styled_components_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-size: ", ";\n    font-weight: 300;\n    line-height: ", ";\n    letter-spacing: -.1px;\n\n    ", "\n  "], ["\n    font-size: ", ";\n    font-weight: 300;\n    line-height: ", ";\n    letter-spacing: -.1px;\n\n    ", "\n  "])), (0, exports.getRelativeREM)(30, REM_MOBILE), (0, exports.getRelativeREM)(36, REM_MOBILE), _1.mediaQuery.greaterThan('tablet')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      font-weight: 200;\n      font-size: ", ";\n      line-height: ", ";\n      letter-spacing: -.06px;\n    "], ["\n      font-weight: 200;\n      font-size: ", ";\n      line-height: ", ";\n      letter-spacing: -.06px;\n    "])), (0, exports.getRelativeREM)(50, REM_DESKTOP), (0, exports.getRelativeREM)(54, REM_DESKTOP)));
};
exports.getP50 = getP50;
var getP24 = function () {
    return (0, styled_components_1.css)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-size: ", ";\n    font-weight: 300;\n    line-height: ", ";\n    letter-spacing: -.1px;\n\n    ", "\n  "], ["\n    font-size: ", ";\n    font-weight: 300;\n    line-height: ", ";\n    letter-spacing: -.1px;\n\n    ", "\n  "])), (0, exports.getRelativeREM)(20, REM_MOBILE), (0, exports.getRelativeREM)(26, REM_MOBILE), _1.mediaQuery.greaterThan('tablet')(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      font-size: ", ";\n      line-height: ", ";\n      letter-spacing: .27px;\n    "], ["\n      font-size: ", ";\n      line-height: ", ";\n      letter-spacing: .27px;\n    "])), (0, exports.getRelativeREM)(24, REM_DESKTOP), (0, exports.getRelativeREM)(30, REM_DESKTOP)));
};
exports.getP24 = getP24;
var getP24_2 = function () {
    return (0, styled_components_1.css)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    font-size: ", ";\n    font-weight: 300;\n    line-height: ", ";\n    letter-spacing: -.1px;\n\n    ", "\n  "], ["\n    font-size: ", ";\n    font-weight: 300;\n    line-height: ", ";\n    letter-spacing: -.1px;\n\n    ", "\n  "])), (0, exports.getRelativeREM)(24, REM_MOBILE), (0, exports.getRelativeREM)(26, REM_MOBILE), _1.mediaQuery.greaterThan('tablet')(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      font-size: ", ";\n      line-height: ", ";\n    "], ["\n      font-size: ", ";\n      line-height: ", ";\n    "])), (0, exports.getRelativeREM)(24, REM_DESKTOP), (0, exports.getRelativeREM)(30, REM_DESKTOP)));
};
exports.getP24_2 = getP24_2;
var getP24_3 = function () {
    return (0, styled_components_1.css)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    font-size: ", ";\n    font-weight: 300;\n    line-height: ", ";\n    letter-spacing: -.1px;\n\n    ", "\n  "], ["\n    font-size: ", ";\n    font-weight: 300;\n    line-height: ", ";\n    letter-spacing: -.1px;\n\n    ", "\n  "])), (0, exports.getRelativeREM)(20, REM_MOBILE), (0, exports.getRelativeREM)(26, REM_MOBILE), _1.mediaQuery.greaterThan('tablet')(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      font-size: ", ";\n      line-height: ", ";\n    "], ["\n      font-size: ", ";\n      line-height: ", ";\n    "])), (0, exports.getRelativeREM)(24, REM_DESKTOP), (0, exports.getRelativeREM)(30, REM_DESKTOP)));
};
exports.getP24_3 = getP24_3;
var getP20_1 = function () {
    return (0, styled_components_1.css)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    font-size: ", ";\n    font-weight: 500;\n    line-height: ", ";\n    letter-spacing: -0.36px;\n    font-family: ", ";\n\n    ", "\n  "], ["\n    font-size: ", ";\n    font-weight: 500;\n    line-height: ", ";\n    letter-spacing: -0.36px;\n    font-family: ", ";\n\n    ", "\n  "])), (0, exports.getRelativeREM)(18, REM_MOBILE), (0, exports.getRelativeREM)(15, REM_MOBILE), function (_a) {
        var theme = _a.theme;
        return theme.fonts.basier;
    }, _1.mediaQuery.greaterThan('tablet')(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n      font-size: ", ";\n      line-height: ", ";\n      letter-spacing: -0.5px;\n    "], ["\n      font-size: ", ";\n      line-height: ", ";\n      letter-spacing: -0.5px;\n    "])), (0, exports.getRelativeREM)(20, REM_DESKTOP), (0, exports.getRelativeREM)(26, REM_DESKTOP)));
};
exports.getP20_1 = getP20_1;
var getP20 = function () {
    return (0, styled_components_1.css)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    font-size: ", ";\n    font-weight: 700;\n    line-height: ", ";\n    letter-spacing: .5px;\n\n    ", "\n  "], ["\n    font-size: ", ";\n    font-weight: 700;\n    line-height: ", ";\n    letter-spacing: .5px;\n\n    ", "\n  "])), (0, exports.getRelativeREM)(20, REM_MOBILE), (0, exports.getRelativeREM)(24, REM_MOBILE), _1.mediaQuery.greaterThan('tablet')(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n      font-size: ", ";\n      line-height: ", ";\n      letter-spacing: .5px;\n    "], ["\n      font-size: ", ";\n      line-height: ", ";\n      letter-spacing: .5px;\n    "])), (0, exports.getRelativeREM)(20, REM_DESKTOP), (0, exports.getRelativeREM)(24, REM_DESKTOP)));
};
exports.getP20 = getP20;
var getP18 = function () {
    return (0, styled_components_1.css)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    font-size: ", ";\n    font-weight: 500;\n    line-height: ", ";\n    letter-spacing: .15px;\n\n    ", "\n  "], ["\n    font-size: ", ";\n    font-weight: 500;\n    line-height: ", ";\n    letter-spacing: .15px;\n\n    ", "\n  "])), (0, exports.getRelativeREM)(16, REM_MOBILE), (0, exports.getRelativeREM)(22, REM_MOBILE), _1.mediaQuery.greaterThan('tablet')(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n      font-weight: 300;\n      font-size: ", ";\n      line-height: ", ";\n      letter-spacing: -.1px;\n    "], ["\n      font-weight: 300;\n      font-size: ", ";\n      line-height: ", ";\n      letter-spacing: -.1px;\n    "])), (0, exports.getRelativeREM)(18, REM_DESKTOP), (0, exports.getRelativeREM)(24, REM_DESKTOP)));
};
exports.getP18 = getP18;
var getP12 = function () {
    return (0, styled_components_1.css)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n    font-size: ", ";\n    font-weight: 500;\n    line-height: ", ";\n    letter-spacing: 0.3px;\n\n    ", "\n  "], ["\n    font-size: ", ";\n    font-weight: 500;\n    line-height: ", ";\n    letter-spacing: 0.3px;\n\n    ", "\n  "])), (0, exports.getRelativeREM)(10, REM_MOBILE), (0, exports.getRelativeREM)(12, REM_MOBILE), _1.mediaQuery.greaterThan('tablet')(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n      font-size: ", ";\n      line-height: ", ";\n    "], ["\n      font-size: ", ";\n      line-height: ", ";\n    "])), (0, exports.getRelativeREM)(12, REM_DESKTOP), (0, exports.getRelativeREM)(15, REM_DESKTOP)));
};
exports.getP12 = getP12;
var getP10 = function () {
    return (0, styled_components_1.css)(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n    font-size: ", ";\n    font-weight: 700;\n    line-height: ", ";\n    letter-spacing: .25px;\n\n    ", "\n  "], ["\n    font-size: ", ";\n    font-weight: 700;\n    line-height: ", ";\n    letter-spacing: .25px;\n\n    ", "\n  "])), (0, exports.getRelativeREM)(10, REM_MOBILE), (0, exports.getRelativeREM)(15, REM_MOBILE), _1.mediaQuery.greaterThan('tablet')(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n      font-size: ", ";\n      line-height: ", ";\n      letter-spacing: .25px;\n    "], ["\n      font-size: ", ";\n      line-height: ", ";\n      letter-spacing: .25px;\n    "])), (0, exports.getRelativeREM)(10, REM_DESKTOP), (0, exports.getRelativeREM)(15, REM_DESKTOP)));
};
exports.getP10 = getP10;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20;
