import { useMemo, memo } from 'react'
import styled from 'styled-components'
import { ContentImage, ContentText, ContentVideo, ContentSlider, ContentFullScreen } from './content-types'
import { mediaQuery, getRelative, getP50, getP24, getP18, getP90, getP12 } from '@dy/commons/styles'

const Article = styled.article<any>`
  display: flex;
  grid-column: 1 / span 6;
  justify-content: center;

  h1, h2 {
    ${getP90()}
  }

  h3 {
    ${getP50()}
  }

  h4 {
    ${getP18()}
  }

  h5, h6 {
    ${getP12()}
  }

  p, ul, ol, dl {
    ${getP24()}
  }

  &.spacer {
    height: ${getRelative(50, 'mobile')};

    ${mediaQuery.greaterThan<any>('tablet')`
      height: ${getRelative(50, 'desktop')};
    `}
  }

  &.text {
    grid-column: 1 / span 6;

    ${mediaQuery.greaterThan<any>('tablet')`
      grid-column: ${({ col }) =>`${col.from} / span ${col.expand}`};
    `}
  }

  &.media,
  &.fullscreen {
    margin: 0 auto;
    position: relative;

    figure {
      background-color: #EFEFEF;
    }
  }

  &.slider,
  &.slideshow,
  &.fullscreen {
    grid-column: 1 / span 6;

    ${mediaQuery.greaterThan<any>('tablet')`
      grid-column: 1 / span 12;
    `}
  }

  &.media {
    grid-column: ${({ singleMediaPortrait, singleMediaLandscape }) => singleMediaPortrait ? '2 / span 4' : singleMediaLandscape ? '1 / span 6' : 'auto / span 3'};

    ${mediaQuery.greaterThan<any>('tablet')`
      grid-column: ${({ col }) =>`${col.from} / span ${col.expand}`};
    `}

    figure {
      height: 100%;
      width: 100%;
    }

    img {
      height: 100%;
      object-fit: cover;
    }

    &.portrait {
      height: ${({ columns }) => `${getRelative(columns === 1 ? 354 : 257, 'mobile')}`};

      ${mediaQuery.greaterThan<any>('tablet')`
        height: ${({ columns }) => `${getRelative(columns === 1 ? 823 : 650, 'desktop')}`};
      `}

      &.center {
        ${mediaQuery.greaterThan<any>('tablet')`
          transform: translateX(${getRelative(45 + 12.5, 'desktop')});
        `}
      }

      div {
        align-items: center;
        display: flex;
        height: 100%;
      }
    }

    &.landscape {
      height: ${({ columns }) => `${getRelative(columns === 1 ? 245 : 115, 'mobile')}`};

      ${mediaQuery.greaterThan<any>('tablet')`
        height: ${({ columns }) => `${getRelative(columns === 1 ? 597 : 367, 'desktop')}`};
      `}

      div {
        align-items: center;
        display: flex;
        height: 100%;
      }
    }

    &.fullscreen {
      grid-column: 1 / span 6;
      height: ${getRelative(233, 'mobile')};

      ${mediaQuery.greaterThan<any>('tablet')`
        grid-column: 1 / span 12;
        height: ${getRelative(810, 'desktop')};
      `}
    }
  }
`

const MediaTypes = {
  'image': (data) => (<ContentImage data={data} />),
  'video': (data) => (<ContentVideo data={data} />),
  'embed': () => (<p>un embed por aquí</p>),
}

const ContentsTypes = {
  'text': (data) => (<ContentText data={data} />),
  'media': (data) => (MediaTypes[data.media.type](data)),
  'fullscreen': (data) => (<ContentFullScreen data={data} />),
  'slideshow': (data) => (<ContentSlider data={data.media} />),
  'empty': () => (<div className='empty'></div>),
  'spacer': () => (<div className='spacer'></div>)
}

const getContentByType = (content) => {
  const { type } = content
  if (!type || !ContentsTypes[type]) return <></>
  if(type === 'media' && content.fullscreen) return (ContentsTypes['fullscreen'](content))
  return (ContentsTypes[type](content))
}

interface IContent {
  columns: number,
  content: any
}

export const Content = memo(({ content, columns }:IContent) => {
  const ContentByType = useMemo(() => getContentByType(content), [content])
  const singleColumn = columns === 1
  const orientation = content.medium_orientation ?? 'landscape'
  const singleMediaPortrait = singleColumn && orientation === 'portrait'
  const singleMediaLandscape = singleColumn && orientation === 'landscape'

  return (
    <Article className={`${content.type} ${content.fullscreen ? 'fullscreen' : ''} ${content.type === 'media' ? `${orientation} ${content.horizontal_alignment}` : ''}`} col={content.col} columns={columns} singleMediaLandscape={singleMediaLandscape} singleMediaPortrait={singleMediaPortrait} orientation={orientation}>
      {ContentByType}
    </Article>
  )
})
