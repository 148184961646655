import { memo } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { useI18n } from '@/i18n'
import Image from 'next/image'
import { motion } from 'framer-motion'
// import { useModal, modalContactId } from '@/modals'
import { mediaQuery, getRelative, getTwoColumnGrid, getInvertedButtonUnderline, getP50 } from '@/styles'
import { staggerChildVariants, staggerWrapperVariants, imgVariants, basicVariantsProps } from '../commons'

interface ISectionContact {
  isProjects?: boolean
}

const Section = styled.section<any>`
  ${getTwoColumnGrid()}
  background-color: ${({ theme }) => theme.colors.creme};
`

const MediaWrapper = styled.div<any>`
  overflow: hidden;

  figure {
    height: ${getRelative(377, 'mobile')};
    position: relative;

    ${mediaQuery.greaterThan('tablet')`
      height: ${getRelative(510, 'desktop')};
    `}

    img { height: 100%; }
  }
`

const TextWrapper = styled(motion.div)`
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 0 ${getRelative(65, 'mobile')};

  ${mediaQuery.greaterThan('tablet')`
    display: block;
    margin: 0;
  `}

  h2 {
    ${getP50()}
    margin-bottom: ${getRelative(40, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      margin: ${getRelative(129, 'desktop')} ${getRelative(128, 'desktop')} ${getRelative(60, 'desktop')} ${getRelative(156, 'desktop')};
    `}
  }

  > div {
    display: flex;
    justify-content: space-evenly;

    &.single {
      justify-content: center;
    }

    ${mediaQuery.greaterThan('tablet')`
      justify-content: center;

      button {
        margin-right: ${getRelative(20, 'desktop')};
      }
    `}

    button {
      ${getInvertedButtonUnderline()}
    }
  }
`

export const SectionContact = memo<ISectionContact>(({ isProjects = false }) => {
  const { locales: { contact_section } } = useI18n()
  // const { open: openModalContact } = useModal(modalContactId)

  return (
    <Section>
        <TextWrapper variants={staggerWrapperVariants} {...basicVariantsProps} animate={'animate'}>
            <motion.h2 variants={staggerChildVariants}>{contact_section.title}</motion.h2>
            <motion.div variants={staggerChildVariants} className={isProjects ? 'single' : ''}>
              <button><Link scroll={false} href='/contact'><a>{contact_section.contact}</a></Link><span /></button>
              {/* <button onClick={openModalContact}>{contact_section.contact}<span/></button> */}
              {!isProjects && <button><Link scroll={false} href='/projects/all'><a>{contact_section.projects}</a></Link><span/></button>}
            </motion.div>
        </TextWrapper>
        <MediaWrapper>
          <motion.figure variants={imgVariants} animate={'animate'} initial={'initial'}>
            <Image src='/images/contact.jpg' alt='contact background' layout='fill' lazyBoundary='-200px'/>
          </motion.figure>
        </MediaWrapper>
    </Section>
  )
})
