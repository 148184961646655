import { useCallback, memo, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import styled from 'styled-components'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { Figure } from '@dy/commons/components'
import { parserMedia } from '@dy/commons/utils'
import { mediaQuery, getRelative, getTransition, getP18 } from '@/styles'

import { useSlider } from '@dy/commons/hooks'
import { imgVariants } from '../commons'

interface ITransitionSlider {
  data: any,
  imgSize?: any
  parentState?: any
  parentStateSetter?: any,
  zoom?: boolean,
}

const SliderStyled = styled.div`
  grid-area: 1 / 1 / span 1 / span 6;
  margin: 0 -${getRelative(23, 'mobile')};
  position: relative;
  overflow: hidden;

  ${mediaQuery.greaterThan<any>('tablet')`
    grid-area: 1 / 1 / span 1 / span 12;
    margin: 0;
  `}

  figure {
    height: ${getRelative(486, 'mobile')};
    max-width: 100%;
    position: relative;
    width: 100%;

    ${mediaQuery.greaterThan<any>('tablet')`
      height: ${getRelative(804, 'desktop')};
    `}

    img {
      height: 100% !important;
      position: relative !important;
      width: 100% !important;
      object-fit: cover;
    }
  }

  .title-wrapper {
    overflow: hidden;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: ${getRelative(40, 'mobile')};

    ${mediaQuery.greaterThan<any>('tablet')`
      bottom: ${getRelative(60, 'desktop')};
    `}
  }

  p {
    ${getP18()}
    opacity: 0;
    color: ${({ theme }) => theme.colors.white};

    ${mediaQuery.greaterThan<any>('tablet')`
      letter-spacing: -0.29px;
    `}
  }

  .control-btn {
    position: absolute;
    top: 45%;
    padding: ${getRelative(24, 'mobile')};
    left: 0;

    &.next {
      right: 0;
      left: auto;
    }

    ${mediaQuery.greaterThan<any>('tablet')`
      padding: ${getRelative(40, 'desktop')};
      top: calc(50% - ${getRelative(20, 'desktop')});
    `}
  }

  .half {
    figure {
      width: ${getRelative(326, 'mobile')};
      height: ${getRelative(250, 'mobile')};
      
      ${mediaQuery.greaterThan<any>('tablet')`
        width: ${getRelative(667, 'desktop')};
        height: ${getRelative(510, 'desktop')};

      `}
    }

    img { object-fit: contain; }
  }
`

const MediaWrapper = styled.div<any>`
  overflow: hidden;
`

const textVariants = {
  initial: {
    y: '20px',
    opacity: 0,
    transition: {
      ...getTransition()
    }
  },
  animateText: {
    y: '0px',
    opacity: 1,
    transition: {
      ...getTransition(),
      ease: [.32,0,.29,.99],
      duration: 1
    }
  },
  exit: {
    y: '20px',
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .5
    }
  },
}

const slideVariants = {
  initial: {
    opacity: 0,
    scale: 1.02,
    transition: {
      ...getTransition(),
      delay: 0,
    }
  },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      ...getTransition(),
      duration: 1,
      delay: .3,
    }
  },
  exit: {
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .2,
      delay: 0,
    }
  },
}

const titleVariants = {
  initial: {
    opacity: 0,
    transition: {
      ...getTransition(),
      delay: 0,
    }
  },
  animate: {
    opacity: 1,
    transition: {
      ...getTransition(),
      duration: 1,
      delay: .6,
    }
  },
  exit: {
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .2,
      delay: 0,
    }
  },
}

const changeColorVariants = {
  animate: imgSize => ({
    stroke: imgSize === 'full' ? '#FFF' : '#4D4D4D',
    transition: {
      ...getTransition(),
      duration: 1
    }
  })
}

const Svgs = {
  'back': imgSize => (
    <svg width="38px" height="34px" viewBox="0 0 38 34" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>i--arrow back</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <motion.g id="Home" transform="translate(-39.000000, -2076.000000)" stroke={imgSize === 'full' ? '#4D4D4D' : '#FFFFFF'} strokeWidth="0.5" custom={imgSize} variants={changeColorVariants} animate='animate'>
                <g id="Projects" transform="translate(0.000000, 1689.000000)">
                    <g id="i--arrow-back" transform="translate(40.000000, 387.972100)">
                        <line x1="0" y1="16.8033" x2="38" y2="16.8033" id="Stroke-8-Copy"></line>
                        <polyline id="Stroke-9-Copy" points="16.8035 33.607 0.0005 16.803 16.8035 0"></polyline>
                    </g>
                </g>
            </motion.g>
        </g>
    </svg>
  ),
  'next': imgSize => (
    <svg width="38px" height="34px" viewBox="0 0 38 34" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>i--arrow next</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <motion.g id="Home" transform="translate(-1362.000000, -2077.000000)" stroke={imgSize === 'full' ? '#4D4D4D' : '#FFFFFF'} strokeWidth="0.5" custom={imgSize} variants={changeColorVariants} animate='animate'>
                <g id="Projects" transform="translate(0.000000, 1689.000000)">
                    <g id="i--arrow-next" transform="translate(1362.000000, 388.420900)">
                        <line x1="38" y1="16.8037" x2="0" y2="16.8037" id="Stroke-6-Copy"></line>
                        <polyline id="Stroke-7-Copy" points="21.1968 0 37.9998 16.804 21.1968 33.607"></polyline>
                    </g>
                </g>
            </motion.g>
        </g>
    </svg>
  )
}

const getSlide = (data, idx, locale, zoom) => {
  if(!data) return {}
  if (zoom) {
    return { url: '#', image: data?.[idx]?.zoom, name: '', display: 'half' }
  }

  const {name, slug, display, header: { image }} = data[idx]

  return { url: `/project/${slug[locale]}`, image, name, display }
}

export const TransitionSlider = memo<ITransitionSlider>(({ data, parentStateSetter, parentState = 'small', zoom = false }) => {
  const { locale } = useRouter()
  const controls = useAnimation()
  const { currentSlide, triggerRef, nextSlide, prevSlide, setSlide, startAnimation } = useSlider(data.length, true)
  const { display, url, image, name } = useMemo(() => getSlide(data, currentSlide, locale, zoom), [data, currentSlide, zoom])

  const [ref, inView] = useInView({
    rootMargin: '0px 0px -100px 0px'
  })

  const [textRef, textInView] = useInView({
    rootMargin: '0px 0px -100px 0px'
  })

  useEffect(() => {
    if(inView) {
      controls.start('animate')
    }
  }, [inView])

  useEffect(() => {
    if(textInView) {
      controls.start('animateText')
    }
  }, [textInView])

  useEffect(() => {
    parentStateSetter(display)
    if (!zoom) {
      startAnimation()
    }

    setSlide(0)
  }, [data])

  const handleComplete = useCallback(() => {
    parentStateSetter(display)
  }, [display])

  if(!data) return <SliderStyled></SliderStyled>

  return (
    <SliderStyled className='transition-slider' ref={triggerRef}>
      <div>
        <Link href={url} scroll={false}>
          <a onClick={url === '#' ? (e) => e.preventDefault() : null} >
            <MediaWrapper ref={ref}>
              <motion.div variants={imgVariants} animate={controls} initial={'initial'}>
                <AnimatePresence onExitComplete={handleComplete} exitBeforeEnter>
                  <motion.div className={`${display} ${currentSlide}`} key={currentSlide} variants={slideVariants} initial='initial' exit='exit' animate='animate'>
                    <Figure lazy={false} media={parserMedia(image)} size={'90vw'}/>
                  </motion.div>
                </AnimatePresence>
              </motion.div>
            </MediaWrapper>
            <div className='title-wrapper' ref={textRef}>
              <motion.div variants={textVariants} animate={controls} initial={'initial'}>
                <AnimatePresence exitBeforeEnter>
                  <motion.p key={currentSlide} variants={titleVariants} initial='initial' exit='exit' animate='animate'>{name}</motion.p>
                </AnimatePresence>
              </motion.div>
            </div>
          </a>
        </Link>
      </div>
      {data.length > 1 &&
        <>
          <button onClick={() => prevSlide()} className='control-btn prev' key={'btn-back' + parentState}>{Svgs.back(parentState)}</button>
          <button onClick={() => nextSlide()} className='control-btn next' key={'btn-next' + parentState}>{Svgs.next(parentState)}</button>
        </>
      }
    </SliderStyled>)
})
