import { memo, forwardRef } from 'react'
import styled from 'styled-components'
import { mediaQuery, getRelative, getP18 } from '@/styles'
import { useState } from 'react'

interface IRadioCheckBoxes {
  title?: any,
  option: any,
  registerProps?: any,
  onBlur?: any,
  onChange?: any,
  ref?: any,
  active?: boolean
}

const Input = styled.input`
  opacity: 0;
  padding: 0;
  width: 0;
  pointer-events: none;

  ${mediaQuery.lessThan('phablet')`
    font-size: 16px;
  `}

  + span {
    ${getP18()}
    margin-top: -${getRelative(22, 'mobile')};
    display: flex;
    place-items: center;
    cursor: pointer;
    transition: opacity 300ms ${({ theme }) => theme.ease};
    overflow: initial !important;

    ${mediaQuery.greaterThan('tablet')`
      margin-bottom: ${getRelative(3, 'desktop')};
      margin-top: -${getRelative(24, 'desktop')};
    `}

    ${mediaQuery.lessThan('phablet')`
      font-size: 16px;
    `}

    span { 
      display: inline !important;
      overflow: initial !important;
    
      &:first-of-type {
        align-self: baseline;
        margin-right: ${getRelative(14, 'mobile')};
        
        ${mediaQuery.greaterThan('tablet')`
          margin-right: ${getRelative(14, 'desktop')};
        `}
      }
    }

    svg {
      margin-bottom: ${getRelative(2, 'mobile')};
      width: ${getRelative(10, 'mobile')};
      height: ${getRelative(10, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        margin-bottom: ${getRelative(2, 'desktop')};
        width: ${getRelative(10, 'desktop')};
        height: ${getRelative(10, 'desktop')};
      `}

      #Symbols {
        fill: transparent;
        fill-rule: evenodd;
        transition: fill 100ms ${({ theme }) => theme.ease};
      }
    }
  }

  &.active + span svg #Symbols, &:checked + span svg #Symbols {
    fill: #4D4D4D;
  }

  &:not(&.active)&:not(:checked):not([disabled]):hover + span svg #Symbols {
    fill: #4d4d4d33;
  } 

  &[disabled] + span {
    opacity: .4;
    pointer-events: none;
    user-select: none;
  }
`

export const RadioCheckBoxes = memo<IRadioCheckBoxes>(forwardRef(({ active = true, title = '', option, registerProps = null, onBlur = null, onChange = null }, ref) => {

  return (
    <label>
      <Input type='radio' disabled={!active} name={title} value={option} onBlur={onBlur} onChange={onChange} ref={ref} {...(registerProps && { ...registerProps })}/>
      <span>
        <span>
          <svg viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>Oval</title>
            <g id="Symbols" stroke="none" strokeWidth="1">
                <g id="Radiobutton" transform="translate(0.000000, -9.000000)" stroke="#4D4D4D" strokeWidth="0.5">
                    <circle id="Oval" cx="5" cy="14" r="4.75"></circle>
                </g>
            </g>
          </svg>
        </span>
        <span>{option}</span>
      </span>
    </label>
  )
}))

export const RadioCheckBoxes2 = ({ title = '', option, onChange = null, active, disabled = false }) => {

  const [isActive, setActive] = useState(active)

  const handleChange = (e) => {
    e.stopPropagation()
    setActive((old) => {
      onChange(!old)
      return !old
    })
  }

  return (
    <label className={disabled ? 'disabled' : ''}>
      <Input disabled={disabled} name={title} readOnly className={`${isActive ? 'active' : ''}`} value={option}/>
      <span onClick={handleChange}>
        <span>
          <svg viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>Oval</title>
            <g id="Symbols" stroke="none" strokeWidth="1">
                <g id="Radiobutton" transform="translate(0.000000, -9.000000)" stroke="#4D4D4D" strokeWidth="0.5">
                    <circle id="Oval" cx="5" cy="14" r="4.75"></circle>
                </g>
            </g>
          </svg>
        </span>
        <span>{option}</span>
      </span>
    </label>
  )
}
