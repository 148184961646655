import { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
// import { useModal, modalContactId } from '@/modals'
import { useI18n } from '@/i18n'
import { callAPI } from '@/api'
import { mediaQuery, getRelative, getInvertedButtonUnderline, createGrid, getTransition, getP24 } from '@/styles'

const titleVariants = {
  initial: {
    y: '20px',
    opacity: 0,
    transition: {
      ...getTransition()
    }
  },
  animateText: {
    y: '0px',
    opacity: 1,
    transition: {
      ...getTransition(),
      ease: [.32,0,.29,.99],
      duration: 1
    }
  },
  exit: {
    y: '20px',
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .5
    }
  },
}

const Section = styled.section`
  ${createGrid()}
  grid-row-gap: ${getRelative(60, 'mobile')};
  background-color: ${({ theme }) => theme.colors.creme};
  padding: ${getRelative(60, 'mobile')} 0;
  width: 100%;

  ${mediaQuery.greaterThan('tablet')`
    display: flex;
    padding: ${getRelative(90, 'desktop')} ${getRelative(40, 'desktop')};
  `}

  > div {
    &:nth-child(1) { grid-area: 1 / 1 / span 1 / span 3; }
    &:nth-child(2) { grid-area: 1 / 4 / span 1 / span 3; }
    &:nth-child(3) { grid-area: 2 / 1 / span 1 / span 3; }
    &:nth-child(4) { grid-area: 2 / 4 / span 1 / span 3; }
    &:nth-child(even) { margin-left: ${getRelative(12, 'mobile')}; }

    ${mediaQuery.greaterThan('tablet')`
      width: ${getRelative(320, 'desktop')};
      margin-right: ${getRelative(26, 'desktop')};
      flex-shrink: 0;

      &:nth-child(even) { margin-left: 0; }
    `}
  }

  p, a {
    ${getP24()}
  }

  button {
    ${getInvertedButtonUnderline()}
  }

  p {
    margin-bottom: ${getRelative(15, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      margin-bottom: ${getRelative(30, 'desktop')};
    `}
  }
`

export const PrePreFooter = memo(() => {
  const { locales: { preprefooter } } = useI18n()
  const { locale } = useRouter()
  // const { open: openModalContact } = useModal(modalContactId)
  const [articleLink, setArticleLink] = useState()
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: '0px 0px -100px 0px'
  })

  useEffect(() => {
    if(inView) {
      controls.start('animateText')
    }
  }, [inView])

  useEffect(() => {
    async function getProducts() {
      const [data, error] = await callAPI({ type: 'page', params: { locale, slug: 'article' } })
      if(error) return []
      setArticleLink(data[0].slug[locale])
    }

    getProducts()
  }, [])

  return (
    <Section ref={ref}>
      <motion.div variants={titleVariants} animate={controls} initial={'initial'}>
        <p dangerouslySetInnerHTML={{ __html: preprefooter.contact.text }} />
        {/* <button onClick={openModalContact}>{preprefooter.contact.link}</button> */}
        <button>
          <Link scroll={false} href={'/contact'}><a>{preprefooter.contact.link}<span></span></a></Link>
        </button>
      </motion.div>
      {/* <motion.div variants={titleVariants} animate={controls} initial={'initial'}>
        <p dangerouslySetInnerHTML={{ __html: preprefooter.faq.text }} />
        <button>
          <Link scroll={false} href={'/faqs'}><a>{preprefooter.faq.link}<span></span></a></Link>
        </button>
      </motion.div> */}
      <motion.div variants={titleVariants} animate={controls} initial={'initial'}>
        <p dangerouslySetInnerHTML={{ __html: preprefooter.location.text }} />
        <button>
          <Link scroll={false} href={'/wheretobuy'}><a>{preprefooter.location.link}<span></span></a></Link>
        </button>
      </motion.div>
      <motion.div variants={titleVariants} animate={controls} initial={'initial'}>
        <p dangerouslySetInnerHTML={{ __html: preprefooter.culture.text }} />
        <button>
          <Link scroll={false} href={`/article/${articleLink}`}><a>{preprefooter.culture.link}<span></span></a></Link>
        </button>
      </motion.div>
    </Section>
  )
})
