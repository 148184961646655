import { memo, useCallback, useState, useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { ArrowSelect } from './ArrowSelect'
import { Input } from './Input'
import { useDropdown } from '../dropdown'

import { getTransition, getRelative, mediaQuery, getP18 } from '@/styles'

interface IInputSelect {
  options: any[],
  className?: string,
  label?: string,
  name?: string,
  initialValue?: string,
  customReset?: boolean
  inputRef?: any,
  onChange?: any,
  disabled?: boolean,
  error?: boolean,
  readOnly?: boolean,
  errorMessage?: any,
  registerProps?: any,
  onBlur?: any,
  placeholder?: any,
  placeHolderAlwaysActive?: any,
  hook_setValue?: any,
  light?: any
}

export const OptionsList = styled(motion.ul)<any>`
  background-color: ${({ theme, $light }) => $light ? theme.colors.background : theme.colors.creme};
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  width: 100%;
  opacity: 0;
  padding: 0;
  z-index: 10;
  overflow-y: auto;
  max-height: ${getRelative(198, 'mobile')};
  padding: ${getRelative(10, 'mobile')};
  margin-top: 1px;

  ${mediaQuery.greaterThan('tablet')`
    max-height: ${getRelative(198, 'desktop')};
    padding: ${getRelative(10, 'desktop')};
  `}

  li {
    ${getP18()}
    opacity: .4;
    cursor: pointer;
    width: 100%;
    transition: opacity 300ms ${({ theme }) => theme.ease};

    &:not(:last-of-type) {
      margin-bottom: ${getRelative(5, 'mobile')};
    }

    ${mediaQuery.greaterThan('tablet')`
      &:not(:last-of-type) {
        margin-bottom: ${getRelative(5, 'desktop')};
      }
    `}

    &:active,
    &:hover,
    &.active {
      opacity: 1;
    }
  }
`

const Close = styled(motion.div)<any>`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;

  span {
    position: absolute;
    background-image: url('/images/svg/input-close.svg');
    background-repeat: no-repeat;
    background-size: contain;
    top: ${getRelative(10, 'mobile')};
    right: ${getRelative(10, 'mobile')};
    width: ${getRelative(18, 'mobile')};
    height: ${getRelative(18, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      top: ${getRelative(10, 'desktop')};
      right: ${getRelative(10, 'desktop')};
      width: ${getRelative(18, 'desktop')};
      height: ${getRelative(18, 'desktop')};
    `}
  }
`

export const optionsListVariants = {
  collapsed: {
    height: '0',
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
    transition: { ...getTransition() }
  },
  expanded: {
    height: 'auto',
    opacity: 1,
    display: 'block',
    transition: { ...getTransition() }
  }
}

const getOptions = (options, currentValue) => {
  return options.map((option, idx) => (
    <li data-value={option} key={idx} className={currentValue === option ? 'active' : ''}>{option}</li>
  ))
}

let TIMEOUT_ID = null
export const InputSelect = memo<IInputSelect>(({ options, customReset = false, className = '', label = '', name = '', inputRef = null, onChange = null, initialValue = '', disabled = false, error = false, placeholder = null, placeHolderAlwaysActive = false, errorMessage = '', registerProps = null, hook_setValue = null, light = false }) => {
  const [currentValue, setValue] = useState(initialValue)
  const [currentOptions, setOptions] = useState([...options])
  const allOptions = useRef(options)

  const handleSearch = useCallback(async ({ value, valid }) => {
    if (valid) {
      const data = allOptions.current.filter(option => option.name.includes(value))
      setOptions(data)
    } else {
      setOptions(allOptions.current)
    }
  }, [])

  const handleInputChange = (value) => {
    const valid = value.length > 1
    TIMEOUT_ID && clearTimeout(TIMEOUT_ID)
    TIMEOUT_ID = setTimeout(() => handleSearch({value, valid}), 300)
  }

  const hangleChange = useCallback((value) => {
    onChange && onChange(value)
    setValue(value)
  }, [])

  const [triggerRef, dropdownRef, expanded, toggleExpanded] = useDropdown({ onChange: hangleChange })

  const refFunc = (ref) => {
    if (ref) {
      triggerRef.current = ref
      inputRef && inputRef(ref)
    }
  }

  const Options = useMemo(() => getOptions(currentOptions, currentValue), [currentOptions, currentValue])

  useEffect(() => {
    if (typeof initialValue === 'string') {
      setValue(initialValue)
    } else {
      setValue(null)
    }
  }, [initialValue])

  useEffect(() => {
    allOptions.current = [...options]
    setOptions(options)
  }, [options])

  useEffect(() => {
    if(customReset) {
      setValue('')
    }
  }, [customReset])

  return (
    <Input autofocus={false} onChange={handleInputChange} error={error} errorMessage={errorMessage} icon={<ArrowSelect/>} disabled={disabled} initialValue={currentValue} name={name} onFocus={toggleExpanded} placeholder={placeholder} placeHolderAlwaysActive={placeHolderAlwaysActive} className={className} label={label} readOnly={true} registerProps={registerProps} ref={refFunc} hook_setValue={hook_setValue}>
      <OptionsList ref={dropdownRef} initial='collapsed' variants={optionsListVariants} animate={expanded ? 'expanded' : 'collapsed'} $light={light}>
        {Options}
        <Close onClick={toggleExpanded}><span></span></Close>
      </OptionsList>
    </Input>
  )
})
