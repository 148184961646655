import { memo, useMemo, useState, useEffect} from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import Link from 'next/link'
import { useI18n } from '@/i18n'

import { mediaQuery, createGrid, getRelative, getTransition, getInvertedButtonUnderline, getP18, getP20_1, getP24_3 } from '@/styles'
import { Anchor } from './AnchorPoints'

interface ISectionDownload {
  data: any,
  name: any,
  onInView: any
}

const Section = styled.section`
  ${createGrid()}
  grid-template-rows: auto 1fr auto;
  min-height: ${getRelative(350, 'mobile')};
  background-color: ${({ theme }) => theme.colors.creme};
  padding-top: ${getRelative(60, 'mobile')};
  padding-bottom: ${getRelative(100, 'mobile')};

  ${mediaQuery.greaterThan('tablet')`
    padding-top: ${getRelative(60, 'desktop')};
    padding-bottom: ${getRelative(100, 'desktop')};
    min-height: ${getRelative(460, 'desktop')};
    position: relative;
  `}

  h2 {
    ${getP24_3()}
    font-weight: bold;
    text-align: left;
  }

  h2 {
    grid-area: 1 / 1 / span 1 / span 6;
    margin-bottom: ${getRelative(20, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 1 / 1 / span 1 / span 12;
      margin-bottom: ${getRelative(30, 'desktop')};
      margin-left: ${getRelative(40, 'desktop')};
    `}
  }

  li, button, a {
    ${getP18()}
  }

  .variants {
    grid-area: 2 / 1 / span 1 / span 6;
    margin: ${getRelative(60, 'mobile')} 0 ${getRelative(30, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 2 / 1 / span 1 / span 3;
      margin: 0;
      margin-left: ${getRelative(40, 'desktop')};
    `}

    li {
      display: inline-block;
      margin-right: ${getRelative(20, 'mobile')};
      opacity: 0.4;
      transition: 300ms opacity ${({ theme: { ease } }) => ease};

      ${mediaQuery.greaterThan('tablet')`
        display: block;
        margin-right: 0;
      `}

      button {
        text-align: left;
      }
    }

    li.active,
    li:hover {
      opacity: 1;
    }
  }

  .variants,
  .variant-info {
    .ul-t {
      ${getP20_1()}
      border-bottom: 1px solid rgba(0, 0, 0, .3);
      margin-bottom: 10px;
      opacity: 1;
      padding-bottom: 5px;
      pointer-events: none;
      width: 100%;
    }
  }


  .variant-info {
    grid-area: 3 / 1 / span 1 / span 6;

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 2 / 4 / span 1 / span 6;
      margin-left: ${getRelative(26, 'desktop')};
    `}

    li {
      transition: 300ms opacity ${({ theme: { ease } }) => ease};

      &:hover {
        opacity: .4;
      }
    }
  }

  > .download-link {
    grid-area: 4 / 1 / span 1 / span 6;
    margin: ${getRelative(50, 'mobile')} 0 ${getRelative(100, 'mobile')};
    text-align: right;

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 4 / 8 / span 1 / span 4;
      margin: ${getRelative(80, 'desktop')} 0 0;
    `}

    button {
      ${getInvertedButtonUnderline()}
    }
  }
`

const imageVariants = {
  initial: {
    opacity: 0,
    transition: {
      ...getTransition(),
      delay: 0,
    }
  },
  animate: {
    opacity: 1,
    transition: {
      ...getTransition(),
      duration: 1,
      delay: .3,
    }
  },
  exit: {
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .2,
      delay: 0,
    }
  },
}

const getVariants = (variants, name, setVariant, selectedVariant) => variants.map((variant, idx) => {
  return (
    <li key={idx+name} className={idx === selectedVariant ? 'active' : ''}>
      <button onClick={() => setVariant(idx)}>{variant.name}</button>
    </li>
  )
})

const getVariantInfos = (infos) => infos.map(({ name, url }, idx) => {
  return (
    <li key={idx+name}><a href={url} target='_blank' rel='noreferrer'>{name}<span></span></a></li>
  )
})

export const SectionDownload = memo<ISectionDownload>(({ data, name, onInView }) => {
  const [selectedVariant, setVariant] = useState(0)
  const { locales: { product: { downloads, all_downloads, single_model, pick_model, available_download_for_selected_model } } } = useI18n()

  const Variants = useMemo(() => getVariants(data, name, setVariant, selectedVariant), [data, selectedVariant])
  const VariantInfo = useMemo(() => getVariantInfos(data[selectedVariant].downloads), [selectedVariant])

  const [ref, inView] = useInView({
    rootMargin: '40% 0% 0% 0%',
    threshold: 0.3,
    triggerOnce: false
  })

  useEffect(() => {
    if (inView) {
      onInView('#downloads')
    }
  }, [inView])

  return (
    <Section ref={ref}>
      <Anchor top={-90} id={'downloads'}/>
      <h2>{downloads}</h2>
      <ul className='variants'>
        <li className='ul-t'>1. {Variants?.length > 1 ? pick_model : single_model}</li>
        {Variants}
      </ul>
      <AnimatePresence exitBeforeEnter>
        <motion.ul className='variant-info' key={selectedVariant} variants={imageVariants} initial='initial' exit='exit' animate='animate'>
          <li className='ul-t'>2. {available_download_for_selected_model}: {data[selectedVariant]?.name}</li>
          {VariantInfo}
        </motion.ul>
      </AnimatePresence>
      <div className='download-link'>
        <button><Link href={'/downloads'} scroll={false}><a>{all_downloads}</a></Link><span></span></button>
      </div>
    </Section>
  )
})
