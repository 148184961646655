import { memo, useEffect } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import { mediaQuery, getRelative, hex2Rgba, getMargin20, getP20, getP18, getButtonUnderline } from '@dy/commons/styles'
import { IContentTypeText } from '@dy/commons/src/schema'
import { titleVariants, basicVariantsProps } from '../../commons'

const TextWrapper = styled(motion.div)<any>`
  color: ${({ color, theme }) => color ? color : theme.colors.text};
  text-align: ${({ align }) => align && align.h ? align.h : 'left'};
  word-break: break-word;
  width: 100%;

  a {
    ${getButtonUnderline()}
    &::after {
      content: ' ';
      width: 100%;
      height: 0.03rem;
      opacity: 0.2;
      position: absolute;
      bottom: -${getRelative(4, 'mobile')};
      background-color: ${({ theme }) => theme.colors.black};
      left: 0;
      right: unset;
      transition: width 300ms ease, left 300ms ease, right 300ms ease;

      ${mediaQuery.greaterThan('tablet')`
        bottom: -${getRelative(4, 'desktop')};
      `}
    }
    &:hover,
    &.active {
      &::after {
        left: unset;
        right: 0;
        width: 0;
      }
    }
  }

  h1, h2, h3, h4, h5, h6,
  p, ul, ol, dl,
  q, a {
    color: ${({ theme }) => theme.colors.text};
    text-align: inherit;
    word-break: inherit;

    &:last-child {
      margin-bottom: 0 !important;
    }

    strong {
      opacity: .4;
      font-weight: inherit;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    ${getMargin20()}
  }

  p, ul, ol, dl {
    ${getMargin20()}

    &:last-child {
      margin-bottom: 0;
    }

    br {
      + p, ul, ol, dl, h2, h3, h4, h5, h6, a {
        margin-top:  ${getRelative(10, 'mobile')};

        ${mediaQuery.greaterThan('tablet')`
          margin-top: ${getRelative(15, 'desktop')};
        `}
      }
    }
  }

  li, dd {
    ${getMargin20()}

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul, ol {
    padding-left: 18px;
  }

  ul {
    li {
      list-style-type: disc;
    }
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }

  q, blockquote {
    border-left: 0;
    display: block;
    margin: auto;
    ${getMargin20()}
    text-align: center;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    > p {
      ${getP20()}
      display: inline;
      font-style: italic;
      font-weight: normal;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;

    th,
    td {
      ${getP18()}
      padding: ${getRelative(20, 'mobile')} ${getRelative(5, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        padding: ${getRelative(30.5, 'desktop')}vw ${getRelative(10, 'desktop')};
      `}

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    th {
      color: ${({ theme }) => theme.colors.text};
      border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.text, .6)};
    }

    tbody, tfoot {
      td {
        border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.text, .1)};
        color: ${({ theme }) => hex2Rgba(theme.colors.text, .6)};

        ${mediaQuery.greaterThan('tablet')`
          max-width: ${getRelative(490, 'desktop')};
          vertical-align: initial;
        `}
      }

      tr {
        &:last-child {
          td {
            border-color: ${({ theme }) => hex2Rgba(theme.colors.text, 0)};
          }
        }
      }
    }
  }
`

// TO ADD ONCE API RETURN data-label attr in the 'tbody td' for a fully responsive solution
// tbody {
//   &:before {
//     ${mediaQuery.lessThan('tablet')`
//       ${getP18()}
//       color: ${({ theme }) => theme.colors.text};
//       content: attr(data-label);
//       float: left;
//     `}
//   }
// }

// thead {
//   ${mediaQuery.lessThan('tablet')`
//     border: none;
//     clip: rect(0 0 0 0);
//     height: 1px;
//     margin: -1px;
//     overflow: hidden;
//     padding: 0;
//     position: absolute;
//     width: 1px;
//   `}
// }



interface IContentText {
  data: IContentTypeText
}

export const ContentText = memo(({ data }:IContentText) => {
  const { text, color, align } = data
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: '0px 0px -100px 0px'
  })

  useEffect(() => {
    if(inView) {
      controls.start('animate')
    }
  }, [inView])

  return (
    <TextWrapper ref={ref} variants={titleVariants} {...basicVariantsProps} animate={controls} className={'text-wrapper'} color={color} align={align} dangerouslySetInnerHTML={{ __html: text }} />
  )
})
