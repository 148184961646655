import { useState, memo, useRef, useEffect, useCallback } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { callAPI } from '@/api'
import { useMediaType } from '@/hooks'
import { useI18n } from '@/i18n'
import { Input } from '../../../commons'
import { mediaQuery, getRelative, createGrid, getTransition, getP24, getP12 } from '@/styles'

const _SearchList = styled<any>(motion.div)`
  ${createGrid()}
  background-color: ${({ theme }) => theme.colors.white};
  height: 100vh;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 1002;

  .s-input {
    grid-area: 1 / 1 / span 1 / span 6;
    place-self: center;
    margin-bottom: 0;
    width: calc(100% - ${getRelative(54, 'mobile')});

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 1 / 4 / span 1 / span 6;
      width: calc(100% - ${getRelative(54, 'desktop')});
    `}

    input {
      color: ${({ theme }) => theme.colors.text};
      text-align: center;
      line-height: 1;
      ${getP24()}

      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        text-align: center;
        opacity: 1;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        text-align: center;
        opacity: 1;
      }
      :-ms-input-placeholder { /* IE 10+ */
        text-align: center;
        opacity: 1;
      }
      :-moz-placeholder { /* Firefox 18- */
        text-align: center;
        opacity: 1;
      }
    }

    span {
      background-color: ${({ theme }) => theme.colors.text};
    }
  }

  .close-button {
    position: absolute;
    top: ${getRelative(21, 'mobile')};
    right: ${getRelative(26, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      top: ${getRelative(30, 'desktop')};
      right: ${getRelative(30, 'desktop')};
    `}

    img {
      width: ${getRelative(18, 'mobile')};
      height: ${getRelative(18, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        width: ${getRelative(18, 'desktop')};
        height: ${getRelative(18, 'desktop')};
      `}
    }
  }
`

const ButtonGoSearchPage = styled(motion.a)`
  ${getP12()}
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,calc(-50% + ${getRelative(42, 'mobile')}));
  opacity: .4;
  transition: opacity 300ms ${({ theme }) => theme.ease};

  ${mediaQuery.greaterThan('tablet')`
    transform: translate(-50%,calc(-50% + ${getRelative(42, 'desktop')}));
  `}

  &:hover { opacity: 1; }
`

const searchVariants = {
  active: {
    opacity: 1,
    y: 0,
    userSelect: 'initial',
    pointerEvents: 'initial',
    transition: {
      ...getTransition()
    }
  },
  inactive: {
    opacity: 0,
    y: '-50%',
    userSelect: 'none',
    pointerEvents: 'none',
    transition: {
      ...getTransition()
    }
  }
}

export const Overlay = styled.div`
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  opacity: 0;
  background-color: rgba(0,0,0,.6);
  user-select: none;
  pointer-events: none;
  transition: opacity 300ms ${({ theme }) => theme.ease};
  z-index: -1;

  &.active {
    opacity: 1;
    pointer-events: initial;
    user-select: initial;
  }
`

let TIMEOUT_ID = null
const emptySearchResult = {
  products: [],
  products_length: 0,
  brands: [],
  brands_length: 0,
  categories: [],
  categories_length: 0
}

const SearchList = memo<any>(({ isActive, setQuery, close, search }) => {
  const [searchResult, setSearchData] = useState({ data: emptySearchResult, valid: false })
  const [searchLength, setSearchLength] = useState(0)
  const { locale, push, pathname } = useRouter()
  const listRef = useRef(null)
  const inputRef = useRef(null)
  const mediaType = useMediaType()

  const handleSearch = async ({ value, valid }) => {
    if (valid) {
      const [data] = await callAPI({ type: 'search', query: { q: value }, params: { locale } })
      setSearchData({ data: { ...data }, valid })
    } else {
      setSearchData({ data: emptySearchResult, valid })
    }

    setQuery(value)
  }

  const handleClose = useCallback(() => {
    close()
    inputRef.current.value = ''
    handleChange('')
  }, [])

  const onKeyUp = useCallback((e) => {
    if(e.code === 'Enter' || e.key === 'Enter' || e.keyCode === 13) {
      const href = `/search?q=${inputRef.current.value}`
      if(pathname === '/search') {
        push(href, undefined, { shallow: true })
      } else {
        push(href)
      }

      handleClose()
    }
  }, [])

  const handleChange = (value) => {
    setSearchLength(value.length)
    const valid = value.length > 2
    TIMEOUT_ID && clearTimeout(TIMEOUT_ID)

    TIMEOUT_ID = setTimeout(() => handleSearch({value, valid}), 300)
  }

  useEffect(() => {
    if (!listRef.current) return

    if (mediaType === 'mobile') {
      listRef.current.style.height = `${window.innerHeight}px`
    } else {
      listRef.current.style.height = ''
    }
  }, [mediaType, listRef.current])

  useEffect(() => {
    if (isActive && inputRef.current) inputRef.current.focus()
  }, [isActive])


  return <>
    <_SearchList initial={'inactive'} variants={searchVariants} animate={isActive ? 'active' : 'inactive'} ref={listRef}>
      <Input ref={inputRef} placeHolderAlwaysActive={true} className='s-input' onChange={handleChange} placeholder={search.placeholder} onKeyUp={onKeyUp}/>
      <Link scroll={false} href={`/search?q=${inputRef.current?.value}`} passHref>
        <ButtonGoSearchPage key={'nav-btn--go-search-page'} onClick={handleClose}>
          {search.results}
        </ButtonGoSearchPage>
      </Link>
      <button onClick={handleClose} className='close-button'>
        <img aria-label='button close' src={'/images/svg/close_modal.svg'}/>
      </button>
    </_SearchList>
    <Overlay onClick={handleClose} className={isActive ? 'active' : 'inactive'} />
  </>
})


export const Search = ({setMenuActive = null, isActiveSearch, setActiveSearch}) => {
  const { locale } = useRouter()
  const { locales: { search } } = useI18n()
  const [queryInput, setQuery] = useState('')

  const closeOverlayAndMenu = () => {
    setMenuActive(false)
    setActiveSearch(false)
  }

  return (
    <SearchList close={() => closeOverlayAndMenu()} isActive={isActiveSearch} setQuery={setQuery} search={search}/>
  )
}
