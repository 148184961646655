"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTwoColumnGrid = exports.getRelative = exports.getRawRelative = exports.createGrid = exports.defaultGridOptions = void 0;
var styled_components_1 = require("styled-components");
var _1 = require("./");
exports.defaultGridOptions = {
    mobile: {
        columns: 6,
        columnWidth: 62,
        gutterWidth: 0,
        maxWidth: 414,
    },
    tablet: {
        columns: 12,
        columnWidth: 120,
        gutterWidth: 0,
        maxWidth: 1440
    },
    desktop: {
        columns: 12,
        columnWidth: 120,
        gutterWidth: 0,
        maxWidth: 1440
    }
};
var defaultRowOptions = {
    mobile: { rows: 'auto-flow', gutter: 0 },
    desktop: { rows: 'auto-flow', gutter: 0 },
};
//@ts-ignore
var createGrid = function (_a) {
    var _b = _a === void 0 ? {} : _a, columns = _b.columns, rows = _b.rows;
    var _c = columns ? columns : exports.defaultGridOptions, mobile = _c.mobile, desktop = _c.desktop;
    var _rows = rows ? rows : defaultRowOptions;
    return (0, styled_components_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: grid;\n    width: 100%;\n    grid: ", " / repeat(", ", ", ");\n    column-gap: ", ";\n    row-gap: ", ";\n    justify-content: center;\n\n    ", "\n  "], ["\n    display: grid;\n    width: 100%;\n    grid: ", " / repeat(", ", ", ");\n    column-gap: ", ";\n    row-gap: ", ";\n    justify-content: center;\n\n    ", "\n  "])), _rows.mobile.rows, mobile.columns, (0, exports.getRelative)(mobile.columnWidth, 'mobile', true), (0, exports.getRelative)(mobile.gutterWidth, 'mobile', true), (0, exports.getRelative)(_rows.mobile.gutter, 'mobile', true), _1.mediaQuery.greaterThan('tablet')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      grid: ", " / repeat(", ", ", ");\n      column-gap:", ";\n      row-gap:", ";\n    "], ["\n      grid: ", " / repeat(", ", ", ");\n      column-gap:", ";\n      row-gap:", ";\n    "])), _rows.desktop.rows, desktop.columns, (0, exports.getRelative)(desktop.columnWidth, 'desktop', true), (0, exports.getRelative)(desktop.gutterWidth, 'desktop', true), (0, exports.getRelative)(_rows.desktop.gutter, 'desktop', true)));
};
exports.createGrid = createGrid;
var getRelativeCreator = function (mobileValue, desktopValue) {
    var VALUES = {
        'mobile': mobileValue,
        'desktop': desktopValue
    };
    return function (from, type, custom) {
        if (custom === void 0) { custom = false; }
        return "".concat((from / VALUES[type]) * 100).concat(custom ? '%' : 'vw');
    };
};
var getRawRelative = function (from, to) {
    return "".concat((from / to) * 100);
};
exports.getRawRelative = getRawRelative;
exports.getRelative = getRelativeCreator(exports.defaultGridOptions.mobile.maxWidth, exports.defaultGridOptions.desktop.maxWidth);
var getTwoColumnGrid = function () {
    return (0, styled_components_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: grid;\n    grid-template: 1fr 1fr / auto;\n\n    > *:first-child {\n      grid-area: 1 / 1 / span 1 / span 1;\n    }\n\n    > *:last-child {\n      grid-area: 2 / 1 / span 1 / span 1;\n    }\n\n    ", "\n  "], ["\n    display: grid;\n    grid-template: 1fr 1fr / auto;\n\n    > *:first-child {\n      grid-area: 1 / 1 / span 1 / span 1;\n    }\n\n    > *:last-child {\n      grid-area: 2 / 1 / span 1 / span 1;\n    }\n\n    ", "\n  "])), _1.mediaQuery.greaterThan('tablet')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      grid-template: auto / 1fr 1fr;\n\n      > *:last-child {\n        grid-area: 1 / 2 / span 1 / span 1;\n      }\n    "], ["\n      grid-template: auto / 1fr 1fr;\n\n      > *:last-child {\n        grid-area: 1 / 2 / span 1 / span 1;\n      }\n    "]))));
};
exports.getTwoColumnGrid = getTwoColumnGrid;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
