import { memo, ReactNode, forwardRef } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { getTransition, hideScrollbar, createGrid } from '@/styles'

export const Aside = styled.aside<any>`
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  z-index: 150;

  ${({ $isActive  }) => !$isActive && `pointer-events: none; user-select: none`}
`

const _Body = styled(motion.div)<any>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  margin-bottom: 40px;
  margin-top: 0;
  overflow: auto;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  z-index: 10;
  padding-top: ${({ theme }) => theme.height.navbar.mobile}px;

  main {
    ${hideScrollbar()}
    ${createGrid()}
    transition: opacity 300ms 50ms ${({ theme }) => theme.ease};
    opacity: ${({ $isActive }) => $isActive ? 1 : 0};
    flex-grow: 2;
    overflow: auto;
    pointer-events: auto;
    width: 100%;
  }
`

const variants_div = {
  animate: {
    x: '0%',
    transition: {
      ...getTransition(),
    }
  },
  exit: {
    x: '-100%',
    transition: {
      ...getTransition(),
    }
  },
}

interface IBody {
  className?: string,
  children: ReactNode,
  isActive: boolean,
  ref?: any,
}

export const Body = memo<IBody>(forwardRef(({ children, className = '', isActive }, ref) => {
  return (
    <_Body ref={ref} $isActive={isActive} initial='exit' animate={isActive ? 'animate' : 'exit'} variants={variants_div} className={className}>
      {children}
    </_Body>)
}))
