import { memo } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { InputSelect } from '../commons'
import { useI18n } from '@/i18n'
import { mediaQuery, getRelative, getTransition } from '@/styles'

interface IWhereToBuyFilter {
  data: any,
  selectedCountry: any,
  setCountry: any,
  selectedCity: any,
  setCity: any,
  filterResults: any,
  online?: any
}

const Wrapper = styled(motion.div)<any>`
  opacity: 0;
  grid-area: 2 / 1 / span 1 / span 6;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-columns: ${({ online }) => online ? '1fr' : '1fr 1fr'};
  grid-column-gap: ${getRelative(23, 'mobile')};
  margin: 0 0 ${getRelative(28, 'mobile')};
  position: sticky;
  top: ${getRelative(114, 'mobile')};
  background-color: ${({ theme }) => theme.colors.background};
  z-index: 10;

  ${mediaQuery.greaterThan('tablet')`
    grid-area: 2 / 1 / span 1 / span 3;
    margin: 0 0 0 ${getRelative(40, 'desktop')};
    display: block;
    padding: 0;
    position: static;
  `}

  + .variant-info {
    margin-top: ${getRelative(16, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      margin-top: 0;
    `}
  }

  > div[class*='Input__Div'] {
    margin-bottom: 0;

    ${mediaQuery.greaterThan('tablet')`
      margin-bottom: ${getRelative(60, 'desktop')};
    `}
  }
`

const Result = styled.div`
  display: none;

  ${mediaQuery.greaterThan('tablet')`
    display: block;
    margin-top: ${getRelative(76, 'desktop')};
  `}

  .filter-results {
    opacity: .4;
  }
`

const imageVariants = {
  'initial': {
    opacity: 0,
    transition: {
      ...getTransition(),
    }
  },
  'animate': {
    opacity: 1,
    transition: {
      ...getTransition(),
    }
  },
  'exit': {
    opacity: 0,
    transition: {
      ...getTransition(),
    }
  }
}

const getCountryNames = countries => countries.map(country => {
  return country.name
})

const getAllCities = countries => {
  const allCities = []
  countries.map(country => {
    allCities.push(...country.cities)
  })
  return allCities
}

const getCitiesByCountry = (selectedCountry, countries) => {
  const allCitiesByCountry = []
  countries.map(country => {
    if(country.name === selectedCountry) allCitiesByCountry.push(...country.cities)
  })
  return allCitiesByCountry
}

export const WhereToBuyFilter = memo<IWhereToBuyFilter>(({ data, setCountry, selectedCountry, setCity, selectedCity, filterResults, online = false }) => {
  const { locales: { wheretobuy_page } } = useI18n()
  const countryFilter = getCountryNames(data.countries)
  const cityFilter = !online && (selectedCountry === 'Country' ? getAllCities(data.countries) : getCitiesByCountry(selectedCountry, data.countries))

  const handleCountrySelect = (value) => {
    setCountry(value)
    setCity('City')
  }

  const handleCitySelect = (value) => {
    setCity(value)
  }

  return (
    <Wrapper variants={imageVariants} initial='initial' exit='exit' animate='animate' online={online}>
      <InputSelect options={countryFilter} key={selectedCountry} name='country' initialValue={selectedCountry} onChange={handleCountrySelect} light/>
      {!online && <InputSelect options={cityFilter} key={selectedCity} name='city' initialValue={selectedCity} onChange={handleCitySelect} light/>}
      <Result>
        <div>{wheretobuy_page.results}</div>
        {(selectedCountry !== 'Country' || selectedCity !== 'City') && <div className='filter-results'>{filterResults} {!online ? wheretobuy_page.distributors : wheretobuy_page.online}</div>}
      </Result>
    </Wrapper>
  )
})
