"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.parserMedia = void 0;
__exportStar(require("./pdf_downloader"), exports);
__exportStar(require("./grid"), exports);
__exportStar(require("./cookies"), exports);
__exportStar(require("./parseString"), exports);
__exportStar(require("./analytics"), exports);
var parserMedia = function (data) {
    var _a, _b, _c, _d, _e, _f;
    if (!(data === null || data === void 0 ? void 0 : data.default))
        return data;
    var media = {
        type: data.type,
        alt: data.alt,
        default: {
            src: (_b = (_a = data === null || data === void 0 ? void 0 : data.default) === null || _a === void 0 ? void 0 : _a['2x']) === null || _b === void 0 ? void 0 : _b.regular,
            size: {
                height: (_d = (_c = data === null || data === void 0 ? void 0 : data.default) === null || _c === void 0 ? void 0 : _c.size) === null || _d === void 0 ? void 0 : _d.height,
                width: (_f = (_e = data === null || data === void 0 ? void 0 : data.default) === null || _e === void 0 ? void 0 : _e.size) === null || _f === void 0 ? void 0 : _f.width,
            }
        }
    };
    return media;
};
exports.parserMedia = parserMedia;
