import { memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import styled from 'styled-components'
import { SliderFlickity } from '@dy/commons/components'
import { getRelative, mediaQuery, hex2Rgba, getP18 } from '@dy/commons/styles'
import { useMediaType } from '@/hooks'
import { useRecoilValue } from 'recoil'
import { activeFiltersIdSelector } from '@/recoil'

type SubMenuItemType = {
  type: 'link' | 'button' | string,
  name: string,
  onClick?: () => void,
  pathname?: string
}

type SubMenuType = {
  data: SubMenuItemType[],
  className?: string,
  grid?: boolean
}

const Section = styled.section`
  align-items: center;
  display: flex;
  height: ${getRelative(30, 'mobile')};
  justify-content: center;
  margin-bottom: ${getRelative(30, 'mobile')};
  overflow: hidden;
  position: sticky;
  top: ${({ theme }) => getRelative(theme.height.navbar.mobile, 'mobile')};
  background-color: ${({ theme }) => theme.colors.background};
  z-index: 100;

  &.submenu {
    margin-top: ${getRelative(30, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      margin-top: ${getRelative(60, 'desktop')};
    `}
  }

  ${mediaQuery.greaterThan('tablet')`
    height: ${getRelative(30, 'desktop')};
    margin-bottom: ${getRelative(60, 'desktop')};
    top: ${({ theme }) => getRelative(theme.height.navbar.desktop, 'desktop')};
  `}

  &:empty {
    height: 0;
    
    ${mediaQuery.greaterThan('tablet')`
      height: 0;
    `}
  }

  &.no-m {
    margin-bottom: 0;
  }

  &.grid {
    position: relative;
    top: unset!important;
  }

  .list--submenu {
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100vw;

    .flickity-viewport {
      height: ${getRelative(60, 'mobile')};
      width: 100%;

      ${mediaQuery.greaterThan('tablet')`
        height: ${getRelative(60, 'desktop')};
      `}
    }

    .flickity-slider {
      height: ${getRelative(30, 'mobile')};
      width: 100%;
      left: 10px !important;

      ${mediaQuery.greaterThan('tablet')`
        height: ${getRelative(30, 'desktop')};
        left: 0px !important;
      `}
    }

    .item {
      align-items: center;
      display: flex;
      justify-content: center;
      height: 100%;
      padding: 0 ${getRelative(12.5, 'mobile')} ${getRelative(10, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        padding: 0 ${getRelative(25, 'desktop')} ${getRelative(10, 'desktop')};
      `}

      a, button {
        ${getP18()}
        font-weight: 300;
        align-items: center;
        color: ${({ theme }) => hex2Rgba(theme.colors.black, .4)};
        display: flex;
        height: 100%;
        justify-content: center;
        padding: 0 ${getRelative(12.5, 'mobile')};
        transition: 300ms color ease-out;
        white-space: nowrap;

        ${mediaQuery.greaterThan('tablet')`
          padding: 0 ${getRelative(25, 'desktop')};
        `}

        &:active,
        &.active {
          color: ${({ theme }) => hex2Rgba(theme.colors.black, 1)};
        }

        &:hover {
          color: ${({ theme }) => hex2Rgba(theme.colors.black, .8)};
        }
      }
    }
  }
`

const getUrl = (origin, filters) => {
  const query = new URLSearchParams(filters)
  let value = query.toString()
  if (value) value = '?' + value

  return origin + value
}

let checker = (arr, target) => target.every(v => arr.includes(v));

const getItemsGrid = (data, asPath, query, filters_ids) => {
  const Items = []
  for (let idx = 0; idx < data.length; idx++) {
    const { type, onClick, name, pathname, filters } = data[idx]

    if (!checker(filters, filters_ids)) continue
  
    const _query = { ...query }
    delete _query.slug

    const url = getUrl(pathname, _query)

    Items.push(
      <div key={name + idx} className='item'>
        <Link scroll={false} href={url}>
          <a className={asPath.includes(pathname) ? 'active' : ''}>{name}</a>
        </Link>
      </div>
    )
  }

  return Items
}

const getItems = (data, asPath) => data.map((item, idx) => {
  const { type, onClick, name, pathname } = item

  return (
    <div key={name + idx} className='item'>
      {type === 'button' ?
        <button onClick={onClick}>
          {name}
        </button>
      :
        <Link scroll={false} href={pathname}>
          <a className={asPath === pathname ? 'active' : ''}>{name}</a>
        </Link>
      }
    </div>
  )
})

export const SubMenu = memo(({ data, className = '', grid = false }:SubMenuType) => {
  const { asPath, query } = useRouter()
  const mediaType = useMediaType()
  const activeFilters = useRecoilValue(activeFiltersIdSelector)

  const Items = useMemo(() => grid ? getItemsGrid(data, asPath, query, activeFilters) : getItems(data, asPath), [data, asPath, query, activeFilters])

  const draggable = useMemo(() => mediaType === 'mobile' ? Items.length > 3 ? true : false : Items.length > 7 ? true : false, [mediaType, Items.length])

  return (
    <Section className={className}>
      {data.length === 1 ? <></> :
        <SliderFlickity className='list--submenu' cellAlign={`${mediaType === 'mobile' ? 'left' : 'center'}`} pageDots={false} draggable={draggable} loop={false}>
          {Items}
        </SliderFlickity>
      }
    </Section>
  )
})
