import { useCallback, useEffect, useRef, useState } from 'react'

export const useDropdown = ({ onChange } = { onChange: null }) => {
  const triggerRef  = useRef(null)
  const dropdownRef = useRef(null)
  const [expanded, setExpanded] = useState(false)

  const onTriggerClick = useCallback(() => {
    setExpanded((state) => !state)
  }, [])

  const onMouseClick = useCallback(({ target }) => {
    const triggerClicked = triggerRef.current?.contains(target)
    const dropdownClicked = dropdownRef.current?.contains(target)

    if (!triggerClicked && !dropdownClicked) { // outside click
      setExpanded(false)
    } else if (dropdownClicked) { // dropdown list click
      if (target instanceof HTMLAnchorElement || (target instanceof HTMLButtonElement && target.dataset.dismiss) || target.dataset.value) {
        const value = target.dataset.value
        const text = target.textContent
        onChange && onChange(value, text)
        setExpanded(false)
      }
    }
  }, [])

  const onEsc = useCallback(e => {
    if (e.keyCode === 27 && expanded === true) {
      setExpanded(false);
    }
  }, [expanded]);


  useEffect(() => {
    if (expanded) {
      document.addEventListener('mousedown', onMouseClick)
      window.addEventListener('keyup', onEsc);
    } else {
      document.removeEventListener('mousedown', onMouseClick)
      window.removeEventListener('keyup', onEsc);
    }
  }, [expanded])

  return [triggerRef, dropdownRef, expanded, onTriggerClick] as const
}

