import { memo, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { ProductCard } from '../commons'
import { Anchor } from './AnchorPoints'

import { mediaQuery, createGrid, getRelative, getP18 } from '@/styles'
import { useI18n, getFormattedLocal } from '@/i18n'

interface ISectionRecommended {
  data: any,
  onInView: any,
  name: string
}

const Section = styled.section`
  ${createGrid()}

  ${mediaQuery.greaterThan('tablet')`
    position: relative;
  `}

  h2 {
    ${getP18()}
    text-align: left;
    grid-area: 1 / 1 / span 1 / span 6;
    margin-bottom: ${getRelative(30, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 1 / 1 / span 1 / span 3;
      margin-bottom: ${getRelative(40, 'desktop')};
      margin-left: ${getRelative(40, 'desktop')};
    `}
  }

  > div {
    grid-area: 2 / 1 / span 1 / span 6;
    grid-template-columns: 1fr 1fr;
    display: grid;
    grid-column-gap: ${getRelative(23, 'mobile')};
    grid-row-gap: ${getRelative(20, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 2 / 1 / span 1 / span 12;
      margin: 0 ${getRelative(40, 'desktop')};
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: ${getRelative(23, 'desktop')};
      grid-row-gap: ${getRelative(30, 'desktop')};
    `}
  }

  .product-card-image {
    img {
      mix-blend-mode: multiply;
    }
  }
`

const getRecommended = products => products.map((product, idx) => {
  return <ProductCard key={idx+product.model} data={{...product, slug: null, product_type: 'accessory' }} isSmall />
})

export const SectionRecommended = memo<ISectionRecommended>(({ data, onInView, name }) => {
  const Recommended = useMemo(() => getRecommended(data), [data])
  const { locales: { product: { recommended_accesories } } } = useI18n()

  const [ref, inView] = useInView({
    rootMargin: '40% 0% 0% 0%',
    threshold: 0.3,
    triggerOnce: false
  })

  useEffect(() => {
    if (inView) {
      onInView('#recommended')
    }
  }, [inView])

  return (
    <>
      <Section ref={ref}>
        <Anchor top={-90} id={'recommended'}/>
        <h2>{getFormattedLocal(recommended_accesories, { product_name: name })}</h2>
        <div>
          {Recommended}
        </div>
      </Section>
    </>
  )
})
