"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contact = void 0;
var contact = function (data) {
    var seo = data.seo, locale = data.locale;
    var contactPage = {
        "@context": "http://schema.org",
        "@type": "ContactPage",
        "url": "https://nexia.es/".concat(locale === 'es' ? '' : "".concat(locale, "/"), "contact"),
        "name": seo.name,
        "description": seo.description,
    };
    return JSON.stringify(contactPage);
};
exports.contact = contact;
