import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { ProductCard } from '../commons'
import { useI18n } from '@/i18n'
import { mediaQuery, createGrid, getRelative, getP24 } from '@/styles'

interface ISectionRelatedProject {
  data: any
}

const Section = styled.section`
  ${createGrid()}

  h2 {
    ${getP24()}
    text-align: left;
    grid-area: 1 / 1 / span 1 / span 6;
    margin-bottom: ${getRelative(30, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 1 / 1 / span 1 / span 3;
      margin-bottom: ${getRelative(40, 'desktop')};
      margin-left: ${getRelative(40, 'desktop')};
    `}
  }

  > div {
    grid-area: 2 / 1 / span 1 / span 6;
    grid-template-columns: 1fr 1fr;
    display: grid;
    grid-column-gap: ${getRelative(23, 'mobile')};
    grid-row-gap: ${getRelative(20, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 2 / 1 / span 1 / span 12;
      grid-template-columns: repeat(4, 1fr);
      margin: 0 ${getRelative(40, 'desktop')};
      grid-column-gap: ${getRelative(23, 'desktop')};
      grid-row-gap: 0;
    `}
  }
`

const getRelated = (projects) => projects.map((project, idx) => {
  return (<ProductCard key={idx+project.name} data={project} isLarge isProject/>)
})

export const SectionRelatedProject = memo<ISectionRelatedProject>(({ data }) => {
  const { locales: { content_page: { related_projects } } } = useI18n()
  const projects = data.slice(0, 2)
  const Related = useMemo(() => getRelated(projects), [data])

  return (
    <Section>
      <h2>{related_projects}</h2>
      <div>
        {Related}
      </div>
    </Section>
  )
})
