import { useCallback, useMemo, useState, useEffect, memo } from 'react'
import type { ChangeEvent, ReactNode } from 'react'
import type { UseFormRegister as TypeRegister, FieldError } from 'react-hook-form'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { noRegister } from './Input2'
import { useDropdownSelect } from '../dropdown'
import { ArrowSelect } from './ArrowSelect'

import { mediaQuery, getRelative, hex2Rgba, getP18 } from '@/styles'
import { parsePlaceHolder } from './Input'
import { OptionsList, optionsListVariants } from './InputSelect'
type onChangeType = (event: ChangeEvent<HTMLInputElement>) => void
type TypeOption = {
  value: string
  name: string
  [key:string]:string
}
interface iSelect {
  options: TypeOption[]
  readonly name: string
  readonly label?: string
  placeholder?: string
  defaultValue?: string
  readonly required?: boolean
  readonly disabled?: boolean
  readonly autoComplete?: string
  readonly readOnly?: boolean
  readonly onChange?: onChangeType
  readonly register?:TypeRegister<any>
  error?: FieldError | any
  setFormValue?: any
  readonly icon?: string | boolean | ReactNode
  readonly className?: string
  readonly placeholderVisible?: boolean
}


const Div = styled.div`
  margin-bottom: ${getRelative(55, 'mobile')};
  position: relative;

  ${mediaQuery.greaterThan('tablet')`
    margin-bottom: ${getRelative(60, 'desktop')};
  `}

  ul {
    box-shadow: 0 2px 4px 0px ${({ theme }) => hex2Rgba(theme.colors.black, .4)};
    overflow-y:scroll;
    top: 35px;
  }

  button {
    background-color: transparent;
  }
`


const Label = styled.label<any>`
 input {
    ${getP18()}
    font-family: ${({ theme }) => theme.fonts.family};
  }
`
const InputStyled = styled.input<any>`
  width: 100%;
  border-bottom: 1px solid ${({ theme, error }) => error ? theme.colors.input_error : hex2Rgba(theme.colors.black, .4)};
  background-color: transparent;
  color: ${({ theme, error }) => error ? theme.colors.input_error : theme.colors.black};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-bottom: ${getRelative(9.5, 'mobile')};
  padding-right: ${getRelative(20, 'mobile')};

  ${mediaQuery.greaterThan('tablet')`
    padding-bottom: ${getRelative(10, 'desktop')};
    padding-right: 0;
  `}

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-internal-autofill-selected {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: none;
    -webkit-text-fill-color: ${({ theme, error }) => error ? theme.colors.input_error : 'none'};
    transition: background-color 5000s ease-in-out 0s;
  }

  ${({ theme, placeholderVisible }) => parsePlaceHolder(`opacity: ${placeholderVisible ? .3 : 0};`, theme.colors.input_text_black)}
`

const getOptionsList = (data, onClickOption, currentValue) => data.map((option, idx) => {
  return (
    <li key={`${option.value}-${idx}`} onClick={() => { onClickOption(option)}} className={`${currentValue === option.value ? 'current' : ''}${option.store ? ` online-store${data[idx+1]?.store ? '' : ' last-store'}` : ''}`}>
        {option.name}
    </li>
  )
})

let TIMEOUT_ID = null
export const Select = memo(({ options, name = 'country', defaultValue, label, autoComplete = 'off', placeholder, placeholderVisible = false, required = false, disabled = false, readOnly = null, onChange = null, register = noRegister, icon = null, className, error, setFormValue, }:iSelect):JSX.Element => {
  const requireProps = typeof required === 'boolean' ? { required } : required
  const { onChange:registerOnChange, onBlur:registerOnBlur, name:registerName, ref:registerRef } = register(name, { ...requireProps })

  const [fancyValue, setFancyValue] = useState(options.filter(option => option.value === defaultValue)[0]?.name ?? '')
  const [currentOptions, setCurrentOptions] = useState(options)
  const [currentValue, setCurrentValue] = useState(defaultValue ?? '')

  const [triggerRef, dropdownRef, expanded, onTriggerFocus, toggleExpanded] = useDropdownSelect()

  const handleChange = e => {
    registerOnChange && registerOnChange(e)
    onChange && onChange(e)
  }

  const handleBlur = e => {
    registerOnBlur && registerOnBlur(e)
    // setInputValue(e?.target?.value)
    // setFormValue(e?.target?.value)
  }

  const onClickOption = (option) => {
    setFormValue && setFormValue(name, option.value)
    setCurrentValue(option.value)
    setFancyValue(option.name)
    toggleExpanded()
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Options = useMemo(() => getOptionsList(currentOptions, onClickOption, currentValue), [currentOptions, currentValue])

  const filterBySearch = useCallback(async ({ value, valid }) => {
    if (valid) {
      const data = options.filter(option => option.name.toLowerCase().includes(value.toLowerCase()))
      setCurrentOptions(data)
    } else {
      setCurrentOptions(options)
    }
  }, [options])

  const handleSearch = e => {
    const value = e?.target?.value
    setFancyValue(value)

    if(typeof value === 'string') {
      const valid = value.length > 1
      TIMEOUT_ID && clearTimeout(TIMEOUT_ID)
      TIMEOUT_ID = setTimeout(() => filterBySearch({value, valid}), 300)
    }
  }

  const onBlurSearch = () => {
    const value = fancyValue
    let matchOption = null

    if(typeof value === 'string' && value.length > 2) {
      matchOption = options.filter(option => option.name.toLowerCase().includes(value.toLowerCase()))?.[0]
    }

    setFormValue(name, matchOption ? matchOption.value : '')
    setFancyValue(matchOption ? matchOption.name : '')
    setCurrentOptions(options)
  }

  useEffect(() => {
    setCurrentOptions(options)
  }, [options])

  return (
    <Div ref={triggerRef}>
      <input type='hidden' name={name} required={required} aria-invalid={!!error}
        {...registerRef && { ref: registerRef }}
        {...defaultValue && { defaultValue }}
        {...autoComplete && { autoComplete }}
      />
      <Label $hasContent={!!fancyValue}   {...className && { className }}>
        {icon ? icon : <ArrowSelect/>}
        <InputStyled type='text' name={`${name}_searcher`} disabled={disabled} readOnly={readOnly} placeholder={placeholder ?? label} placeholderVisible={placeholderVisible} onChange={handleSearch} onFocus={onTriggerFocus} onBlur={onBlurSearch} value={fancyValue} error={error}/>
      </Label>
      {!readOnly && <OptionsList ref={dropdownRef} initial='collapsed' variants={optionsListVariants} animate={expanded ? 'expanded' : 'collapsed'} >
        {Options}
      </OptionsList>}
    </Div>
  )
})
