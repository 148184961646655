import { memo, ReactNode } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mediaQuery, getRelative, getTransition, getRegularButton, hex2Rgba, getP50, getP18 } from '@/styles'

/**
 * Modal base structure
 *
 * <Body>
 *  <Header>
 *    {children}
 *  </Header>
 *  <Main>
 *    {children}
 *  </Main>
 * </Body>
 *
 */

 export const Overlay = styled.div<any>`
 display: block;
 background: transparent;
 position: fixed;
 right: 0;
 top: 0;
 bottom: 0;
 left: 0;
 z-index: 10000000;
 user-select: none;
 pointer-events: none;
 opacity: 0;
 transition: opacity 300ms ${({ theme }) => theme.ease};
 background-color: ${({ theme }) => hex2Rgba(theme.colors.white, .9)};
 height: 0;

 &.active {
   opacity: 1;
   pointer-events: initial;
   user-select: initial;
   height: 100%;
 }
`

const _Header = styled.header`
  grid-area: 1 / 1 / span 1 / span 6;

  ${mediaQuery.greaterThan('tablet')`
    grid-area: 1 / 1 / span 1 / span 12;
  `}

  .prev {
    grid-area: 1 / 1 / span 1 / span 1;
    justify-self: flex-start;
    padding-right: 50px;
  }

  .close {
    position: absolute;
    top: ${getRelative(40, 'mobile')};
    right: ${getRelative(17, 'mobile')};
    z-index: 100;

    ${mediaQuery.greaterThan('tablet')`
      top: ${getRelative(40, 'desktop')};
      right: ${getRelative(40, 'desktop')};
    `}

    figure {
      width: ${getRelative(23, 'mobile')};
      height: ${getRelative(23, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        width: ${getRelative(23, 'desktop')};
        height: ${getRelative(23, 'desktop')};
      `}
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
`

interface IHeader {
  className?: string,
  onClick: any
}

export const Header = memo<IHeader>(({ onClick, className = ''}) => {
  return (
    <_Header className={className}>
      <button className='close' onClick={onClick}>
        <figure>
          <img src={'/images/svg/close_modal.svg'} alt='cross icon'/>
        </figure>
      </button>
    </_Header>)
})

const _Main = styled.main`
  position: relative;

  button, input[type='submit'] {
    ${getRegularButton()}
    transition: background-color 300ms ${({ theme }) => theme.ease};
    margin: 0 auto;
    display: block;
  }

  h2 {
    ${getP50()}
    margin-bottom: ${getRelative(40, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      margin-bottom: ${getRelative(90, 'desktop')};
    `}
  }

  input, legend {
    ${getP18()}
  }
`

interface IMain {
  className?: string,
  children: ReactNode
}

export const Main = memo<IMain>(({ children, className = '' }) => {
  return (
    <_Main className={className}>
      {children}
    </_Main>)
})

const _Body = styled(motion.div)`
  pointer-events: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  grid-area: 1 / 1 / span 1 / span 6;

  ${mediaQuery.greaterThan('tablet')`
    grid-area: 1 / 4 / span 1 / span 6;
    margin: 0 ${getRelative(27, 'desktop')};
  `}

  &.lateral {
    justify-content: flex-start;
    margin-left: auto;
    margin-top: 0;
    max-height: 100vh;
  }
`

const variants_div = {
  enter: {
    opacity: 0,
    y: '10px',
    transition: { ...getTransition(), delay: .2 }
  },
  animate: {
    opacity: 1,
    y: '0px',
    transition: { ...getTransition(),
    delay: .2 }
  },
  exit: {
    opacity: 0,
    y: '10px',
    transition: { ...getTransition() }
  }
}

const variants_lateral = {
  enter: {
    opacity: 0,
    x: '100%',
    transition: { ...getTransition() }
  },
  animate: {
    opacity: 1,
    x: '0%',
    transition: { ...getTransition() }
  },
  exit: {
    opacity: 0,
    x: '100%',
    transition: { ...getTransition() }
  },
}

const variants_video = {
  enter: {
    opacity: 0,
    transition: { ...getTransition() }
  },
  animate: {
    opacity: 1,
    transition: { ...getTransition() }
  },
  exit: {
    opacity: 0,
    transition: { ...getTransition() }
  },
}

interface IBody {
  className?: string,
  children: ReactNode,
  initial?: string,
  exit?: string,
  framerKey?: string,
  animate?: string,
  readonly isActive?: boolean,
  readonly lateral?: boolean,
  readonly video?: boolean
}

export const Body = memo<IBody>(({ children, className = '', framerKey = '', lateral = false, video = false, isActive }) => {
  return (
    <_Body key={framerKey} initial='enter' exit='exit' animate={isActive ? 'animate' : 'exit'} variants={lateral ? variants_lateral : video ? variants_video : variants_div} className={className}>
      {children}
    </_Body>)
})
