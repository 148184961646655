import { memo } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import styled from 'styled-components'
import Image from 'next/image'
import { motion, useAnimation } from 'framer-motion'
import { mediaQuery, getRelative, getInvertedButtonUnderline, getTransition, getP50 } from '@/styles'
import { imgVariants } from '../commons'

interface ISectionTwoCols {
  cols: any
}

const titleVariants = {
  initial: {
    y: '20px',
    x: '-50%',
    opacity: 0,
    transition: {
      ...getTransition()
    }
  },
  animateText: {
    y: '-50%',
    x: '-50%',
    opacity: 1,
    transition: {
      ...getTransition(),
      ease: [.32,0,.29,.99],
      delay: .3,
      duration: 1
    }
  },
  exit: {
    y: '20px',
    x: '-50%',
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .5
    }
  },
}

const Section = styled.section`
  display: flex;
  flex-wrap: wrap;
  position: relative;

  ${mediaQuery.greaterThan('tablet')`
    flex-wrap: nowrap;
  `}

  a {
    display: inline-block;
    position: relative;
    text-decoration: none;
    width: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.img_bg};

    ${mediaQuery.greaterThan('tablet')`
      width: 50%;
    `}

    > div {
      ${getInvertedButtonUnderline({color: 'white'})}
      ${getP50()}
      color: ${({ theme }) => theme.colors.white};
      left: 50%;
      position: absolute;
      top: 50%;
    }

    figure {
      height: ${getRelative(298, 'mobile')};
      overflow: hidden;
      position: relative;
      width: 100vw;

      ${mediaQuery.greaterThan('tablet')`
        height: ${getRelative(510, 'desktop')};
        width: 100%;
      `}

      img {
        height: 100%;
        transition: 1000ms transform ${({ theme: { ease } }) => ease};
      }
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
`

export const SectionTwoCols = memo<ISectionTwoCols>(({ cols }) => {
  const controls = useAnimation()
  const { locale } = useRouter()

  const handleLoad = () => {
    controls.start('animate')
    controls.start('animateText')
  }

  return (
    <Section>
      <Link scroll={false} href={typeof cols[0].pathname  === 'string' ? cols[0].pathname : cols[0].pathname[locale]}>
        <a>
          <motion.figure variants={imgVariants} animate={controls} initial={'initial'}>
            <Image src={cols[0].src} alt='bg-image' layout='fill' onLoadingComplete={handleLoad} lazyBoundary='-200px' sizes='(min-width: 768px) 50vw, 100vw' quality={98}/>
          </motion.figure>
          <motion.div variants={titleVariants} animate={controls} initial={'initial'}>{typeof cols[0].name === 'string' ? cols[0].name : cols[0].name[locale]}<span></span></motion.div>
        </a>
      </Link>
      <Link scroll={false} href={typeof cols[1].pathname  === 'string' ? cols[1].pathname : cols[1].pathname[locale]}>
        <a>
          <motion.figure variants={imgVariants} animate={controls} initial={'initial'}>
            <Image src={cols[1].src} alt='bg-image' layout='fill' onLoadingComplete={handleLoad} lazyBoundary='-200px' sizes='(min-width: 768px) 50vw, 100vw' quality={98}/>
          </motion.figure>
          <motion.div variants={titleVariants} animate={controls} initial={'initial'}>{typeof cols[1].name === 'string' ? cols[1].name : cols[1].name[locale]}<span></span></motion.div>
        </a>
      </Link>
    </Section>
  )
})
