import { useState } from 'react'
import styled from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import { useRouter } from 'next/router'
import { Header, Body, Main } from './Layout'
import { InputSelect } from '../components/commons'
import { useModal } from './'
import { useMount } from '@/hooks'
import { useI18n } from '@/i18n'
import { mediaQuery, getRelative, getRegularButton, hideScrollbar, getP24 } from '@/styles'
import { cookie } from '@/utils'

const _Body = styled(Body)`
  z-index: 10000;
  background: ${({ theme, isActive }) => isActive ? theme.colors.creme : ''};
  width: ${getRelative(500, 'mobile')};
  margin: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  ${mediaQuery.greaterThan('tablet')`
    left: unset;
    width: ${getRelative(500, 'desktop')};
  `}

  p {
    ${getP24()}
    margin-bottom: ${getRelative(60, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      margin-bottom: ${getRelative(60, 'desktop')};
    `}
  }

  main {
    display: flex;
    flex-flow: column;
    align-items: center;

    ul {
      ${hideScrollbar()}

      span {
        right: 0;
      }
    }

    button {
      ${getRegularButton()}
    }
  }
`

export const modalLanguageId = 'modal-language'
export const ModalLanguage = (asPath) => {
  const { isActive, close } = useModal(modalLanguageId)
  const isMount = useMount()
  const { locales: { modales: { language } } } = useI18n()
  const { push, locale } = useRouter()
  const [currentLanguage, setLanguage] = useState(locale)

  const languageOptions = language.options.map(option => option.name)

  const onLanguageChange = (value) => {
    const selectedLang = language.options.find(option => option.name === value)
    setLanguage(selectedLang.value)
  }

  let path = asPath.asPath
  const onClick = () => {
    cookie.set('NEXT_LOCALE', currentLanguage)
    close()
    push(path, undefined, { locale: currentLanguage })
  }

  return (isMount &&
    <>
      <AnimatePresence exitBeforeEnter>
        <_Body isActive={isActive} initial='enter' exit='exit' animate={isActive ? 'animate' : 'exit'} lateral >
          <Header onClick={onClick} />
          <Main>
            <p>{language?.title}</p>
            <InputSelect key={currentLanguage} options={languageOptions} name='language' initialValue={language.options.find(option => option.value === currentLanguage).name} onChange={onLanguageChange} />
            <button onClick={onClick}>{language?.button}</button>
          </Main>
        </_Body>
      </AnimatePresence>
    </>
  )
}
