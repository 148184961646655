import Link from "next/link"
import { memo } from "react"
import { useI18n } from '@/i18n'

const WhereTobuy = memo(() => {
  const { locales: { navbar: { where_to_buy } } } = useI18n()

  return <Link scroll={false} href='/wheretobuy'><a className='wheretobuy'>{where_to_buy}<span /></a></Link>
})

export default WhereTobuy
