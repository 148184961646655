import { memo, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'

import { mediaQuery, createGrid, getRelative, getP18 } from '@/styles'
import { Figure } from '@dy/commons/components'
import { parserMedia } from '@dy/commons/utils'

import { useI18n } from '@/i18n'
import { Anchor } from './AnchorPoints'

interface ISectionSpecifications {
  data: any,
  onInView: any
}

const Section = styled.section`
  ${createGrid()}
  position: relative;

  h2, dt, p {
    ${getP18()}
    text-align: left;
  }

  h2 {
    grid-area: 1 / 1 / span 1 / span 6;
    margin-bottom: ${getRelative(20, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 1 / 1 / span 1 / span 12;
      margin-bottom: ${getRelative(30, 'desktop')};
      margin-left: ${getRelative(40, 'desktop')};
    `}
  }

  figure {
    > span {
      position: relative !important;
    }

    img {
      height: 100% !important;
      object-fit: contain;
      position: relative !important;
      width: 100% !important;
    }
  }

  dt { opacity: 0.4; }

  dl {
    grid-area: 2 / 1 / span 1 / span 6;

    > div {
      width: ${getRelative(172, 'mobile')};
      display: inline-block;
      vertical-align: top;
      margin-bottom: ${getRelative(20, 'mobile')};

      &:nth-child(odd) {
        margin-right: ${getRelative(23, 'mobile')};
      }
    }

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 2 / 1 / span 1 / span 12;
      margin-left: ${getRelative(40, 'desktop')};

      > div {
        width: ${getRelative(205, 'desktop')};
        margin-right: ${getRelative(26, 'desktop')};
        margin-bottom: ${getRelative(30, 'desktop')};

        &:nth-child(odd) {
          margin-right: ${getRelative(26, 'desktop')};
        }

        &:last-of-type {
          width: ${getRelative(287, 'desktop')};

          dt {
            width: ${getRelative(205, 'desktop')};
          }
        }
      }
    `}
  }

  dl + dl {
    grid-area: 3 / 1 / span 1 / span 6;

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 3 / 1 / span 1 / span 12;
    `}

    > div {
      margin-bottom: ${getRelative(20, 'mobile')};
      width: ${getRelative(235, 'mobile')};

      dt {
        margin-bottom: ${getRelative(30, 'desktop')};
        width: ${getRelative(172, 'mobile')};
      }
    }
  }
`

const getDetails = (values, type) => values.map((value, idx) => {
  if (value.type === 'media' && type === 'media') {
    return (
      <Figure key={`${type} ${idx}`} lazy='false' media={parserMedia(value.media)} layout='fill' quality={65} />
    )
  } else if (value.type === 'text' && type === 'text') {
    return (
      <p key={`${type} ${idx}`}>{value.value}</p>
    )
  } else {
    return null
  }
})

const getSpecs = (specs, specType) => specs.map((spec, idx) => {
  const { type, values } = spec
  const Details = getDetails(values, specType)

  return (
    Details[0] !== null &&
      <div key={idx + specType}>
        <dt>{type}</dt>
        <dd>
          {Details}
        </dd>
      </div>
  )
})

const parseData = (data) => {
  const dataWithoutZeros =  data.map((element) => {
    return {
      ...element,
      values: element.values.filter((el) => {
        if (typeof el.value === 'string') {
          return !el.value.startsWith('0 ')
        } else return el.value
      })
    }
  })
  const dataWithoutEmptyColumns = dataWithoutZeros.filter((element) => {
    return element.values.length > 0
  })

  return dataWithoutEmptyColumns
}

export const SectionSpecifications = memo<ISectionSpecifications>(({ data, onInView }) => {
  const { locales: { product: { specs_title } } } = useI18n()
  const parsedData = parseData(data)

  const TextSpecs = useMemo(() => getSpecs(parsedData, 'text'), [parsedData])
  const MediaSpecs = useMemo(() => getSpecs(data, 'media'), [data])

  const [ref, inView] = useInView({
    rootMargin: '40% 0% 0% 0%',
    threshold: 0.3,
    triggerOnce: false
  })

  useEffect(() => {
    if (inView) {
      onInView('#specificactions')
    }
  }, [inView])

  return (
    <Section ref={ref}>
      <Anchor top={-90} id={'specificactions'}/>
      <h2>{specs_title}</h2>
      <dl key='text'>
        {TextSpecs}
      </dl>
      <dl key='media'>
        {MediaSpecs}
      </dl>
    </Section>
  )
})
