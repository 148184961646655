import { memo, useCallback, useState, useContext, createContext, Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { createGrid, mediaQuery, getP18, getRelative, createUnderline } from '@/styles'
import { useScrollViewer } from '@/hooks'
import { useRecoilState } from 'recoil'
import { navbarColorState } from '@/recoil'
import Menu from './Menu'
import WhereToBuy from './WhereToBuy'
import Link from 'next/link'
import { useI18n } from '@/i18n'
import { Search } from './Search'
import Logo from './Logo'

type NavbarBackgroundType = {
  hasNavbarBackground?: boolean,
  setHasNavbarBackground:Dispatch<SetStateAction<boolean>>
}
export const NavbarBackgroundContext = createContext<NavbarBackgroundType | null>(null)

const _Navbar = styled.nav`
  display: grid;
  grid-template: auto / 1fr 1fr 1fr;
  width: 99.9%;
  position: fixed;
  top: 0;
  background-color: transparent;
  transition: background-color 300ms ${({ theme }) => theme.ease};
  z-index: 1000;
  height: ${({ theme }) => getRelative(theme.height.navbar.mobile, 'mobile')};
  padding: 0 ${getRelative(14, 'mobile')};

  .wheretobuy {
    ${createUnderline({ reverse: true })}
  }

  .button-menu {
    .burger-line {
      stroke: ${({ theme }) => theme.colors.black};
    }
  }

  &.light {
    .logo {
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }

    .search {
      #lupa {
        stroke: ${({ theme }) => theme.colors.white};
      }
    }

    .links {
      a, button {
        color: ${({ theme }) => theme.colors.white};

        &.wheretobuy {
          ${createUnderline({ reverse: true, color: 'white'})}
          white-space: nowrap !important;
          margin: 0 10px;
        }
      }
    }
    .button-menu {
      .burger-line {
        stroke: ${({ theme }) => theme.colors.white};
      }
    }
  }

  &.dark,
  &.light.active {
    .logo {
      path {
        fill: ${({ theme }) => theme.colors.black};
      }
    }

    .search {
      #lupa {
        stroke: ${({ theme }) => theme.colors.black};
      }
    }

    .links {
      a, button {
        color: ${({ theme }) => theme.colors.black};

        &.wheretobuy {
          color: ${({ theme }) => theme.colors.black};
          ${createUnderline({ reverse: true })}
        }
      }

    }
    .button-menu {
      .burger-line {
        stroke: ${({ theme }) => theme.colors.black};
      }
    }
  }

  &.no-top {
    background-color: ${({ theme }) => theme.colors.background};

    .logo {
      path {
        fill: ${({ theme }) => theme.colors.black};
      }
    }

    .links {
      a, button {
        color: ${({ theme }) => theme.colors.black};

        &.wheretobuy {
          color: ${({ theme }) => theme.colors.black};
          ${createUnderline({ reverse: true })}
        }
      }
    }

    .search {
      #lupa {
        stroke: ${({ theme }) => theme.colors.black};
      }
    }

    .button-menu {
      .burger-line {
        stroke: ${({ theme }) => theme.colors.black};
      }
    }
  }

  ${mediaQuery.greaterThan('tablet')`
    ${createGrid()}
    padding: 0;
    align-items: unset;
    justify-content: unset;
  `}

  &.active ~ div > .anchorpoints {
    display: none;
  }

  .logo,
  .links,
  .seacrh {
    align-self: center;
    justify-self: center;
    z-index: 2;
  }

  .button-menu {
    justify-self: flex-start;
  }

  .logo {
    z-index: 200;

    svg {
      width: ${getRelative(67, 'mobile')};
    }

    ${mediaQuery.greaterThan('tablet')`
      grid-column: 6 / span 2;

      svg {
        width: ${getRelative(93, 'desktop')};
        height: ${getRelative(28, 'desktop')};
      }
    `}
  }

  .links {
    display: none;
    grid-column: 10 / span 2;
    padding: 0 ${getRelative(10, 'desktop')} ${getRelative(10, 'desktop')} ${getRelative(40, 'desktop')};

    a, button {
      ${getP18()}
      color: ${({ theme }) => theme.colors.black};
      margin: 0 10px;
      white-space: nowrap !important;
    }

    ${mediaQuery.greaterThan('tablet')`
      display: flex;
      justify-content: flex-end;
      width: 100%;
    `}
  }

  .search {
    grid-column: 3 / span 1;
    z-index: 200;
    justify-self: flex-end;
    width: ${getRelative(50, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      grid-column: 12 / span 1;
      width: ${getRelative(50, 'desktop')};
      margin-right: ${getRelative(40, 'desktop')};
    `}
  }

  ${mediaQuery.greaterThan('tablet')`
    height: ${({ theme }) => getRelative(theme.height.navbar.desktop, 'desktop')};
  `}
`

const NavbarSearchButton = styled.div`
  display: flex;
  place-content: center;

  svg {
    width: ${getRelative(21, 'mobile')};
    height: ${getRelative(22, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      width: ${getRelative(21, 'desktop')};
      height: ${getRelative(22, 'desktop')};
    `}
  }
`

const SVGLupa = () => (
  <svg width="21px" height="22px" viewBox="0 0 21 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Group 5</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="i--search" transform="translate(-1365.000000, -34.000000)">
            <g transform="translate(1350.000000, 20.000000)">
                <g id="lupa" transform="translate(16.000000, 15.000000)" stroke="#4D4D4D" strokeWidth="0.5">
                    <path d="M13.684,6.842 C13.684,10.621 10.621,13.684 6.842,13.684 C3.063,13.684 0,10.621 0,6.842 C0,3.063 3.063,0 6.842,0 C10.621,0 13.684,3.063 13.684,6.842 Z" id="Stroke-1"></path>
                    <line x1="11.3158" y1="12.3685" x2="18.9468" y2="20.0005" id="Stroke-3"></line>
                </g>
            </g>
        </g>
    </g>
  </svg>
)


export const Navbar = memo<any>(({ menu_data }) => {
  const [isMenuActive, setActive] = useState(false)
  const scrollState = useScrollViewer(2)
  const [navbarColor] = useRecoilState(navbarColorState)
  const { hasNavbarBackground } = useContext(NavbarBackgroundContext)
  const { locales: { navbar, contact_section } } = useI18n()
  const [isActiveSearch, setActiveSearch] = useState(false)

  const handleChange = useCallback(() => setActive((prev) => !prev), [setActive])

  return <>
    <_Navbar id='navbar-klk' className={`${navbarColor} ${isMenuActive ? 'active' : ''} ${scrollState !== 'TOP' || hasNavbarBackground ? 'no-top' : ''}`}>
      <Menu data={menu_data} onClick={handleChange} setActive={setActive} isActive={isMenuActive} />
        <Logo/>
      <div className='links'>
        <WhereToBuy/>
        <Link scroll={false} href='/usa/products/all'><a className='wheretobuy'>{navbar.usa_products}<span /></a></Link>
        <Link scroll={false} href='/contact'><a className='wheretobuy'>{contact_section.contact}<span /></a></Link>
      </div>
      <NavbarSearchButton className='search'>
        <button onClick={() => setActiveSearch(true)}>
          <SVGLupa/>
        </button>
      </NavbarSearchButton>
    </_Navbar>
    <Search setMenuActive={setActive} isActiveSearch={isActiveSearch} setActiveSearch={setActiveSearch}/>
  </>
})
