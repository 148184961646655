import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { Input, InputSelect, FileUpload, INPUT_PATTERNS, Select } from '../components/commons'
import { useModal, modalContactId } from '@/modals'
import { callAPI } from '@/api'
import { useI18n } from '@/i18n'
import { useToast } from '@/hooks'
import { mediaQuery, getRelative, getP18, getP12, getRegularButton } from '@/styles'

const TRANSLATIONS = {
  'en': {
    form_sent: 'Form sent successfully',
  },
  'es': {
    form_sent: 'Formulario enviado correctamente',
  },
  'fr': {
    form_sent: 'Formulaire soumis avec succès',
  },
  'it': {
    form_sent: 'Modulo inviato con successo',
  }
}


const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  max-width: 600px;
  padding: 0 12px;

  ${mediaQuery.greaterThan('tablet')`
    grid-column-gap: ${getRelative(25, 'desktop')};
  `}

  ${mediaQuery.greaterThan('desktop-xs')`
    max-width: ${getRelative(600, 'desktop')};
  `}

  .title,
  .legal {
    text-align: center;
    margin: 0 auto;

    ${mediaQuery.greaterThan('tablet')`
      max-width: ${getRelative(435, 'desktop')};
    `}
  }

  > div {
    grid-column: 1 / span 2;

    ${mediaQuery.greaterThan('tablet')`
      grid-column: auto;
    `}
  }

  input[type='submit'],
  .legal {
    grid-column: 1 / span 2;
  }

  input[type='submit'] {
    ${getP18()}
    ${getRegularButton()}
    transition: background-color 300ms ${({ theme }) => theme.ease};
    margin: 0 auto;
    display: block;
    font-family: ${({ theme }) => theme.fonts.family};
    margin-bottom: ${getRelative(22, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      margin-bottom: ${getRelative(22, 'desktop')};
    `}
  }

  .legal {
    ${getP12()}
    a { text-decoration: underline; }
  }

  input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    opacity: 1;
  }
  input::-moz-placeholder { /* Firefox 19+ */
    opacity: 1;
  }
  input:-ms-input-placeholder { /* IE 10+ */
    opacity: 1;
  }
  input:-moz-placeholder { /* Firefox 18- */
    opacity: 1;
  }
`

export const FormContact = ({ inModal = false }) => {
  const { Container, addToast, toasts } = useToast()
  const { locale } = useRouter()
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [countries, setCountries] = useState([])
  const [defaultLocale, setDefaultLocale] = useState('')
  const { close } = useModal(modalContactId)
  const localesArray = ['es', 'fr', 'it']
  const { locales: { modales: { contact } } } = useI18n()
  const { register, handleSubmit, formState: { errors }, setValue, watch, reset } = useForm({
    defaultValues: {
      inquiry: contact.inquiry,
      name: contact.name,
      email: contact.email,
      country: contact.country,
      message: contact.message,
      trade: contact.trade,
      company: contact.company,
      phone: contact.phone,
      postal: contact.postal,
      file: null
    }
  })
  const watchInquiry = watch('inquiry')
  const watchName = watch('name')
  const watchEmail = watch('email')
  const watchCountry = watch('country')
  const watchMessage = watch('message')
  const watchTrade = watch('trade')
  const watchPostal = watch('postal')
  const inquiryOptions = contact.inquiries.map(inquiry => inquiry.name)
  const tradeOptions = contact.trades.map(trade => trade.name)

  const closeAndReset = () => {
    close()
    setButtonDisabled(true)
    reset()
  }

  const onSubmit = async (data) => {
    const formData = new FormData()
    for (const [key, value] of Object.entries(data)) {
      if (key !== 'file') {
        formData.append(key, value as string)
      } else if (value !== null) {
        formData.append(key, value?.[0], value?.[0]?.name)
      }
    }
    formData.append('locale', defaultLocale)
    // const [res, error] = await callAPI({ type: 'contact-us-2022', params: { locale }, body: formData, contentType: null, accept: null })
    const DY_CMS_URL = process.env.DY_CMS_URL
    const DY_API_URL = `${DY_CMS_URL}${process.env.DY_API_VERSION}`
    const res = await fetch(`${DY_API_URL}/${locale}/subscribers/contact`, {
      method: 'POST',
      body: formData
    })

    if (res.ok || res.status === 200) {
      if(inModal) closeAndReset()

      if (window['dataLayer']) window['dataLayer'].push({ 'event': 'generate_lead', 'lead_type': 'contacto'})
      addToast({ children: TRANSLATIONS[locale]?.form_sent ?? 'Form sent', type: 'success' })
    }
  }

  useEffect(() => {
    const allRequiredFields = watchInquiry !== contact.inquiry && watchName !== contact.name && watchEmail !== contact.email && watchMessage !== contact.message && watchTrade !== contact.trade && watchPostal !== contact.postal

    if (allRequiredFields) {
      setButtonDisabled(false)
    }
  }, [watchInquiry, watchCountry, watchName, watchEmail, watchMessage, watchTrade, watchPostal])


  useEffect(() => {
    const found = localesArray.find((el => el === watchCountry.toLowerCase()))
    setDefaultLocale(found ? found : 'en')
  }, [watchCountry])


  useEffect(() => {
    async function getLocalCountriesData() {
      const [res, error] = await callAPI({ type: 'countries' })

      if (!error && res) {
        const countries = res.data.map((item) => {
          item.name = item.name[locale]
          item.value = item.code
          return item
        })
        const countriesOrdered = countries.sort((a, b) => a.name.localeCompare(b.name))
        setCountries(countriesOrdered)
      }
    }
    getLocalCountriesData()
  }, [])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className='first'>
        <InputSelect name='inquiry' hook_setValue={setValue} error={errors.inquiry ? true : false} errorMessage={contact?.errorMessages?.select} registerProps={register('inquiry', { required: true })} options={inquiryOptions} placeHolderAlwaysActive placeholder={contact.inquiry} />
        <Input name='name' error={errors.name ? true : false} hook_setValue={setValue} errorMessage={contact?.errorMessages?.text} registerProps={register('name', { required: true })} placeHolderAlwaysActive placeholder={contact.name} />
        <Input name='email' error={errors.email ? true : false} hook_setValue={setValue} errorMessage={contact?.errorMessages?.email} registerProps={register('email', { pattern: INPUT_PATTERNS.email, required: true })} placeHolderAlwaysActive placeholder={contact.email} />
        <Select name='country' register={register} placeholder={contact.country} options={countries} setFormValue={setValue} placeholderVisible />
        <Input name='message' error={errors.message ? true : false} hook_setValue={setValue} errorMessage={contact?.errorMessages?.text} registerProps={register('message', { required: true })} placeHolderAlwaysActive placeholder={contact.message} />
      </div>
      <div className='second'>
        <InputSelect name='trade' hook_setValue={setValue} error={errors.trade ? true : false} errorMessage={contact?.errorMessages?.select} registerProps={register('trade', { required: true })} options={tradeOptions} placeHolderAlwaysActive placeholder={contact.trade} />
        <Input name='company' error={errors.company ? true : false} hook_setValue={setValue} errorMessage={contact?.errorMessages?.text} registerProps={register('company')} placeHolderAlwaysActive placeholder={contact.company} />
        <Input name='phone' error={errors.phone ? true : false} hook_setValue={setValue} errorMessage={contact?.errorMessages?.text} registerProps={register('phone')} placeHolderAlwaysActive placeholder={contact.phone} />
        <Input name='postal' error={errors.postal ? true : false} hook_setValue={setValue} errorMessage={contact?.errorMessages?.text} registerProps={register('postal', { required: true })} placeHolderAlwaysActive placeholder={contact.postal} />
        <FileUpload name='file' registerProps={register('file')} labelId='upload' label={contact.file} />
      </div>
      <input type='submit' value={contact.button} disabled={buttonDisabled} />
      <p dangerouslySetInnerHTML={{ __html: contact.legal }} className='legal' />
      <Container toasts={toasts} />
    </Form>
  )
}
