import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mediaQuery, createGrid, getRelative, getTransition, getP18 } from '@/styles'

interface ISectionCompanyInfo {
  data: any
}

const titleVariants = {
  initial: {
    y: '20px',
    opacity: 0,
    transition: {
      ...getTransition()
    }
  },
  animateText: {
    y: '0px',
    opacity: 1,
    transition: {
      ...getTransition(),
      ease: [.32,0,.29,.99],
      duration: .5,
    }
  },
  exit: {
    y: '20px',
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .5
    }
  },
}

const Section = styled.section`
  ${createGrid()}

  dl {
    column-count: 2;
    column-gap: ${getRelative(23, 'mobile')};
    grid-area: 1 / 1 / span 1 / span 6;

    /* Increased col to 4 due to less company links (designed to be 2 more links in a 4º column) */
    ${mediaQuery.greaterThan('tablet')`
      column-count: 3;
      column-gap: ${getRelative(25, 'desktop')};
      grid-area: 1 / 3 / span 1 / span 8;
      margin: 0 ${getRelative(30, 'desktop')};
    `}

    > div {
      position: relative;
      break-inside: avoid-column;
    }
  }

  dt, dd {
    ${getP18()}
  }

  dt { opacity: .4;}

  .spacer {
    line-height: ${getRelative(30, 'mobile')};
    &.first { line-height: ${getRelative(90, 'mobile')}; }

    ${mediaQuery.greaterThan('tablet')`
      line-height: ${getRelative(40, 'desktop')};
      &.first { line-height: ${getRelative(40, 'desktop')}; }
    `}
  }
`

const getInfos = (infos) => infos.map((info, idx) => {
  const { title, text } = info

  return (
    <div key={idx}>
      <dt>{title}</dt>
      <dd dangerouslySetInnerHTML={{ __html: text }} />
      <br className={`spacer${idx === 0 ? ' first' : ''}`} />
    </div>
  )
})

export const SectionCompanyInfo = memo<ISectionCompanyInfo>(({ data }) => {
  const Infos = useMemo(() => getInfos(data), [data])

  return (
    <Section>
      <motion.dl variants={titleVariants} initial='initial' exit='exit' animate='animateText'>
        {Infos}
      </motion.dl>
    </Section>
  )
})
