import { createContext, useContext, useEffect } from 'react'
import { useSmartState } from '@dy/commons/hooks'

const init: any = {
  locales: {},
  setLocales: () => {}
}

const i18nContext = createContext(init)

export const Provideri18n = ({ value, children }) => {
  const [locales, _setLocales] = useSmartState(value)

  useEffect(() => {
    if (value) {
      _setLocales(value)
    }
  }, [value])

  const setLocales = (newLocales) => {
    const newState = { ...locales, ...newLocales }
    _setLocales(newState)
  }

  return <i18nContext.Provider value={{ locales, setLocales }}>
    {children}
  </i18nContext.Provider>
}

export const useI18n = () => {
  const { locales, setLocales } = useContext(i18nContext)

  return { locales, setLocales }
}
