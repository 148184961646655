import { memo, useMemo, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { mediaQuery, getRelative, getMargin130, getMargin90 } from '@dy/commons/styles'
import { useInView } from 'react-intersection-observer'
import { IContents } from '@dy/commons/src/schema'
import { Content } from './Content'
import { Anchor } from '../product/AnchorPoints'

const Section = styled.section<any>`
  align-items: ${({ onlyTextContent }) => onlyTextContent ? 'flex-start' : 'center'};
  column-gap: ${getRelative(23, 'mobile')};
  display: grid;
  flex-wrap: wrap;
  grid: auto-flow / repeat(6, 1fr);
  padding: ${({ fullScreen }) => fullScreen ? 0 : `0 ${getRelative(23, 'mobile')}`};

  ${mediaQuery.greaterThan('tablet')`
    ${({ onlyTextContent }) => onlyTextContent ? getMargin90() : getMargin130()}
    column-gap: ${getRelative(25, 'desktop')};
    grid: auto-flow / repeat(12, 1fr);
    padding: ${({ fullScreen }) => fullScreen ? 0 : `0 ${getRelative(40, 'desktop')}`};
    position: relative;
  `}

  > article {
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: ${({ columns }) => columns === 2 ? getRelative(20, 'mobile') : getRelative(30, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        margin-bottom: 0;
      `}
    }

    &:last-of-type, 
    &.media {
      margin-bottom: ${getRelative(60, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        margin-bottom: 0;
      `}
    }

    &.media {
      ${({ textImageCols }) => textImageCols && css`
        ${mediaQuery.lessThan('tablet')`
          grid-column: 1 / span 6;

          figure {
            width: ${getRelative(172, 'mobile')};
          }
        `}
      `}
    }

    &.text {
      ${({ columns, onlyTextContent }) => columns === 3 && onlyTextContent && css`
        ${mediaQuery.lessThan('tablet')`
          grid-column: 1 / span 4;
        `}
      `}
    }
  }

  > .spacer {
    height: ${getRelative(60, 'mobile')};
    margin-bottom: 0;
    padding-bottom: 0;

    ${mediaQuery.greaterThan('tablet')`
      height: ${getRelative(120, 'desktop')};
    `}
  }
`

const getContentsByColumns = (columns) => columns.map((content, idx) => {
  return (
    <Content key={idx+'column'} content={content} columns={columns.length}/>
  )
})

const checkIfOnlyTextContents = (columns) => {
  let onlyTextContent = true
  for(const col of columns) {
    if(col.type !== 'text') onlyTextContent = false
  }

  return onlyTextContent
}

const checkIfTextWithImageContents = (columns) => {
  if(columns.length < 2) return false

  let textContent = false
  let imageContent = false
  for(const col of columns) {
    if(col.type === 'text') textContent = true
    if(col.type === 'media') imageContent = true
  }

  return (textContent && imageContent)
}

interface ISectionContents {
  data: IContents,
  onInView?: any
}

export const SectionContents = memo(({ data, onInView = null }:ISectionContents) => {
  const { columns } = data
  const ContentsByColumns = useMemo(() => getContentsByColumns(columns), [columns])
  const fullScreenOrSlider = columns[0]?.type === 'fullscreen' || columns[0]?.type === 'slideshow' || columns[0]?.type === 'slider' || (columns[0]?.type === 'media' && columns[0]?.fullscreen)
  const onlyTextContent = useMemo(() => checkIfOnlyTextContents(columns), [columns])
  const textImageCols= useMemo(() => checkIfTextWithImageContents(columns), [columns])

  const [ref, inView] = useInView({
    rootMargin: '40% 0% 0% 0%',
    threshold: 0.3,
    triggerOnce: false
  })

  useEffect(() => {
    if (onInView && inView) {
      onInView('#contents')
    }
  }, [inView])

  return (
    <Section columns={columns.length} fullScreen={fullScreenOrSlider} onlyTextContent={onlyTextContent} textImageCols={textImageCols} ref={ref}>
      <Anchor top={-90} id={'contents'}/>
      {ContentsByColumns}
    </Section>
  )
})
