import { memo } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { mediaQuery, getRelative, createGrid, createUnderline, getP18 } from '@/styles'
import { useRouter } from 'next/router'

const Section = styled.section`
  grid-area: 3 / 1 / span 1 / span 6;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${getRelative(23, 'mobile')};
  grid-row-gap: ${getRelative(40, 'mobile')};
  padding-bottom: ${getRelative(40, 'mobile')};
  margin-top: -${getRelative(21, 'mobile')};

  ${mediaQuery.greaterThan<any>('tablet')`
    ${createGrid()}
    grid-area: 2 / 1 / span 1 / span 12;
    grid-column-gap: 0;
    grid-row-gap: 0;
    padding-bottom: ${getRelative(40, 'desktop')};
    margin-top: 0;
  `}

  p, button {
    ${getP18()}
  }

  button {
    ${createUnderline()}
  }

  ul { display: flex; }

  .copyright {
    opacity: .4;
    grid-area: 2 / 1 / span 1 / span 3;

    ${mediaQuery.greaterThan<any>('tablet')`
      grid-area: 1 / 1 / span 1 / span 2;
      margin-left: ${getRelative(40, 'desktop')};
    `}
  }

  .list--countries {
    grid-area: 2 / 4 / span 1 / span 3;

    ${mediaQuery.greaterThan<any>('tablet')`
      grid-area: 1 / 7 / span 1 / span 2;
      margin-left: ${getRelative(13, 'desktop')};
    `}

    li {
      margin-right: ${getRelative(30, 'mobile')};
      transition: opacity 300ms ${({ theme }) => theme.ease};
      pointer-events: none;

      &:not(.active) {
        opacity: .4;
        pointer-events: auto;
      }

      &:hover {
        opacity: 1;
      }

      ${mediaQuery.greaterThan<any>('tablet')`
        margin-right: ${getRelative(20, 'desktop')};
      `}
    }
  }

  .list--rrss {
    grid-area: 1 / 4 / span 1 / span 3;

    ${mediaQuery.greaterThan<any>('tablet')`
      grid-area: 1 / 10 / span 1 / span 3;
      margin-left: ${getRelative(117, 'desktop')};
    `}

    li {
      margin-right: ${getRelative(18, 'mobile')};

      ${mediaQuery.greaterThan<any>('tablet')`
        margin-right: ${getRelative(12, 'desktop')};
      `}
    }
  }

  [title*='instagram'] img,
  [title*='twitter'] img {
    width: ${getRelative(18, 'mobile')};
    height: ${getRelative(18, 'mobile')};
  }
  [title*='facebook'] img {
    width: ${getRelative(11, 'mobile')};
    height: ${getRelative(21, 'mobile')};
  }
  [title*='linkedin'] img {
    width: ${getRelative(19, 'mobile')};
    height: ${getRelative(18, 'mobile')};
  }
  [title*='whatsapp'] img,
  [title*='pinterest'] img {
    width: ${getRelative(20, 'mobile')};
    height: ${getRelative(20, 'mobile')};
  }

  ${mediaQuery.greaterThan<any>('tablet')`
    [title*='instagram'] img,
    [title*='twitter'] img {
      width: ${getRelative(12, 'desktop')};
      height: ${getRelative(12, 'desktop')};
    }
    [title*='facebook'] img {
      width: ${getRelative(7, 'desktop')};
      height: ${getRelative(14, 'desktop')};
    }
    [title*='linkedin'] img {
      width: ${getRelative(13, 'desktop')};
      height: ${getRelative(12, 'desktop')};
    }
    [title*='whatsapp'] img {
      width: ${getRelative(13, 'desktop')};
      height: ${getRelative(13, 'desktop')};
    }
    [title*='pinterest'] img {
      width: ${getRelative(14, 'desktop')};
      height: ${getRelative(14, 'desktop')};
    }
  `}
`

export const SectionRrss = memo(() => {
  const { locale } = useRouter()

  return (
    <Section>
      <p className='copyright'>Nexia S.L © {new Date().getFullYear()}</p>
      <ul className='list--countries'>
        <li className={`${locale === 'en' ? 'active' : ''}`}>
          <Link href='/' locale='en'>en</Link>
        </li>
        <li className={`${locale === 'es' ? 'active' : ''}`}>
          <Link href='/' locale='es'>es</Link>
        </li>
        <li className={`${locale === 'it' ? 'active' : ''}`}>
          <Link href='/' locale='it'>it</Link>
        </li>
        <li className={`${locale === 'fr' ? 'active' : ''}`}>
          <Link href='/' locale='fr'>fr</Link>
        </li>
      </ul>
      <ul className='list--rrss'>
        <li>
          <a href='https://www.instagram.com/nexialighting/' rel='noreferrer' target='_blank' title='instagram'>
            <figure>
              <img src='/images/svg/instagram.svg' alt='Instagram logo'/>
            </figure>
          </a>
        </li>
        <li>
          <a href='https://es.linkedin.com/company/nexialighting' rel='noreferrer' target='_blank' title='linkedin'>
            <figure>
              <img src='/images/svg/linkedin.svg' alt='LinkedIn logo'/>
            </figure>
          </a>
        </li>
        <li>
          <a href='https://www.pinterest.es/nexialighting/' rel='noreferrer' target='_blank' title='pinterest'>
            <figure>
              <img src='/images/svg/pinterest.svg' alt='Pinterest logo'/>
            </figure>
          </a>
        </li>
        <li>
          <a href='https://wa.me/0034676081574' rel='noreferrer' target='_blank' title='whatsapp'>
            <figure>
              <img src='/images/svg/whatsapp.svg' alt='WhatsApp logo'/>
            </figure>
          </a>
        </li>
        <li>
          <a href='https://es-es.facebook.com/NexiaLighting/' rel='noreferrer' target='_blank' title='facebook'>
            <figure>
              <img src='/images/svg/facebook.svg' alt='Facebook logo'/>
            </figure>
          </a>
        </li>
      </ul>
    </Section>
  )
})
