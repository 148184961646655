import { useEffect, ReactNode, memo, useMemo, useState, useCallback, useContext } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useDropdown } from './useDropdown'
import { useScrollViewer } from '@/hooks'
import { getTransition, mediaQuery, getRelative, hideScrollbar, getP18 } from '@/styles'
import { NavbarBackgroundContext } from '@/components'

const DropdownWrapper = styled.div`
  max-width: ${getRelative(248, 'desktop')};
  position: relative;

  .triggerButton {
    &.light:not(.no-top) span {
      color: ${({ theme }) => theme.colors.white};

      &::before {
        background: url('/images/svg/arrow-down-light.svg');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    span {
      color: ${({ theme }) => theme.colors.text};

      &::before {
        content: ' ';
        background: url('/images/svg/arrow-down.svg');
        background-size: contain;
        background-repeat: no-repeat;
        vertical-align: middle;
        display: inline-block;
        transition: transform 300ms ${({ theme }) => theme.ease};
        width: ${getRelative(12, 'desktop')};
        height: ${getRelative(7, 'desktop')};
        margin-right: ${getRelative(15, 'desktop')}; 
      }

      &[data-expanded='true']::before {
        transform: rotate(-180deg);
      }
    }
  }

  span, a {
    ${getP18()}
  }
`

export const OptionsList = styled(motion.ul)`
  background-color: ${({ theme }) => theme.colors.background};
  right: 0;
  top: 0%;
  height: 0;
  opacity: 0;
  overflow-y: auto;
  padding: ${getRelative(20, 'mobile')} ${getRelative(30, 'mobile')} ${getRelative(30, 'mobile')};
  position: absolute;
  width: 100vw;
  z-index: 10;
  left: 0;
  ${hideScrollbar()}

  ${mediaQuery.greaterThan('tablet')`
    padding: ${getRelative(10, 'desktop')} 0 ${getRelative(10, 'desktop')} ${getRelative(155, 'desktop')};
    left: -${getRelative(130, 'desktop')};
  `}

  li {
    cursor: pointer;
    opacity: .4;
    width: 100%;
    transition: opacity 300ms ${({ theme }) => theme.ease}, background-color 300ms ${({ theme }) => theme.ease};

    &:active,
    &:hover,
    &.active {
      opacity: 1;
    }
  }

  a {
    display: block;
    margin-bottom: ${getRelative(5, 'desktop')};

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const optionsListVariants = {
  collapsed: {
    height: '0',
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
    transition: { ...getTransition() }
  },
  expanded: {
    height: 'auto',
    opacity: 1,
    display: 'block',
    transition: { ...getTransition() }
  }
}

interface IDropdown {
  className?: string,
  trigger: ReactNode | string,
  options: any,
  currentValue?: any,
  onChange?: any,
  textColor?: any
}

const getOptions = (options, onClickCreator, currentValue) => {
  const Anchors = []

  for (let name in options) {
    const link = options[name]

    Anchors.push(
      <a key={name} onClick={onClickCreator(link)} href={link} className={`${currentValue === link ? 'active' : ''}`}>
        <li>
          {name}
        </li>
      </a>
    )
  }

  return Anchors
}

const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
}

export const Dropdown = memo<IDropdown>(({ className = '', options, onChange = null, currentValue = '', textColor = ''}) => {
  const [triggerRef, dropdownRef, expanded, toggleExpanded] = useDropdown();
  const [currentSelection, setSelection] = useState(Object.keys(options)[0])
  const scrollState = useScrollViewer(5)
  const { setHasNavbarBackground } = useContext(NavbarBackgroundContext)

  const onClickCreator = useCallback((anchor) => () => {
    onChange && onChange(anchor)
    setSelection(getKeyByValue(options,anchor))
    toggleExpanded()
  }, [])

  useEffect(() => {
    if (currentValue) {
      if (getKeyByValue(options,currentValue)) {
        setSelection(getKeyByValue(options,currentValue))
      } else {
        console.warn(`At Dropdown.tsx -> No option with value ${currentValue}`)
      }
    }
  }, [currentValue, options])

  useEffect(() => {
    setHasNavbarBackground(expanded)
  }, [expanded])

  const Options = useMemo(() => getOptions(options, onClickCreator, currentSelection), [options])

  return (
    <DropdownWrapper className={className}>
      <button className={`triggerButton ${textColor} ${scrollState !== 'TOP' ? 'no-top' : ''}`} ref={triggerRef} type='button' onClick={toggleExpanded}>
        <span data-expanded={expanded}>{currentSelection}</span>
      </button>
      <OptionsList ref={dropdownRef} initial='collapsed' variants={optionsListVariants} animate={expanded ? 'expanded' : 'collapsed'}>
        {Options}
      </OptionsList>
    </DropdownWrapper>
  )
})
