import { memo, useEffect } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { useI18n } from '@/i18n'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import { mediaQuery, getRelative, getInvertedButtonUnderline, getP50 } from '@/styles'
import { useModal, modalNewsletterId, modalContactId } from '@/modals'
import { staggerChildVariants, staggerWrapperVariants, basicVariantsProps } from '../../commons'

const Section = styled(motion.section)`
  text-align: center;
  margin: ${getRelative(130, 'mobile')} 0;

  ${mediaQuery.greaterThan('tablet')`
    margin: ${getRelative(150, 'desktop')} 0;
  `}

  p {
    ${getP50()}
    text-align: center;
    margin-bottom: ${getRelative(20, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      margin-bottom: ${getRelative(40, 'desktop')};
    `}
  }

  button {
    ${getInvertedButtonUnderline()}
  }
`

export const PreFooter = memo(() => {
  const { open } = useModal(modalNewsletterId)
  const { open:openModalContact } = useModal(modalContactId)
  const { locales: { prefooter } } = useI18n()
  const { query } = useRouter()
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: '0px 0px -100px 0px'
  })

  useEffect(() => {
    if(inView) {
      controls.start('animate')
    }
  }, [inView])

  return (
    <Section ref={ref} variants={staggerWrapperVariants} {...basicVariantsProps} animate={controls}>
      {query?.slug?.[1] !== 'colour-tool' ?
        <>
          <motion.p variants={staggerChildVariants} dangerouslySetInnerHTML={{ __html: prefooter.title }}></motion.p>
          <motion.button variants={staggerChildVariants} onClick={open}>{prefooter.subscribe}<span></span></motion.button>
        </>
      :
        <>
          <motion.p variants={staggerChildVariants} dangerouslySetInnerHTML={{ __html: prefooter.colour_tool }}></motion.p>
          <motion.button variants={staggerChildVariants} onClick={openModalContact}>{prefooter.colour_tool_button}<span></span></motion.button>
        </>
      }
    </Section>
  )
})
