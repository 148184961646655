import { memo } from 'react'
import styled from 'styled-components'
import { mediaQuery, getRelative, getHeaderStyles } from '@dy/commons/styles'
import { Figure } from '@dy/commons/components'
import { useMediaType } from '@dy/commons/hooks'
import { parserMedia } from '@dy/commons/utils'

interface IHeaderContents {
  data: any
}

const Header = styled.header<any>`
  align-items: center;
  margin-bottom: ${getRelative(60, 'mobile')};

  ${mediaQuery.greaterThan('tablet')`
    margin-bottom: ${getRelative(60, 'desktop')};
  `}

  ${({ fullWidth, headerTheme, fontLarge, fontPosition }) => getHeaderStyles({fullWidth, headerTheme, fontLarge, fontPosition})}
`

export const HeaderContents = memo<IHeaderContents>(({ data }) => {
  const { image, title, subtitle, text_color, text_position } = data
  const mediaType = useMediaType()
  const fullWidth = true

  const onClick = () => {
    mediaType === 'mobile' ? window.scrollTo(0, 650) : window.scrollTo(0, window.innerHeight);
  }

  return (
    <Header id='header' fullWidth={fullWidth} headerTheme={text_color} fontLarge={true} fontPosition={text_position}>
      <div>
        <h1 dangerouslySetInnerHTML={{ __html: title }} />
        {subtitle && <p dangerouslySetInnerHTML={{ __html: subtitle }} />}
      </div>
      <div>
        <Figure media={parserMedia(image)} lazy={false} />
      </div>
      <button onClick={() => onClick()}>
        <figure><img src={`/images/svg/arrow_${!fullWidth || text_color === 'light' ? 'black' : 'white'}.svg`} /></figure>
      </button>
    </Header>
  )
})
