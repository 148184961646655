import { memo, useMemo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { callAPI } from '@/api'
import { getRelative, mediaQuery, getTransition, getMargin40 } from '@/styles'

import { ProductCard } from '../commons'
import { useRouter } from 'next/router'

type SectionListType = {
  projects: any,
  hasFiltersApplied?: boolean
}

const Section = styled.section`
  width: 100%;
`

const Ol = styled(motion.ol)<any>`
  column-gap: ${getRelative(23, 'mobile')};
  display: grid;
  flex-wrap: wrap;
  grid: auto-flow / repeat(6, 1fr);
  padding: 0 ${getRelative(23, 'mobile')};

  ${mediaQuery.greaterThan<any>('tablet')`
    column-gap: ${getRelative(25, 'desktop')};
    grid: auto-flow / repeat(12, 1fr);
    padding: 0 ${getRelative(40, 'desktop')};
  `}

  > li {
    grid-column: auto / span 6;
    ${getMargin40()}

    .product-card-image {
      background: #0000001c;
      height: ${getRelative(214, 'mobile')};

      ${mediaQuery.greaterThan<any>('tablet')`
        height: ${getRelative(396, 'desktop')};
      `}
    }
  }
`

const variants = {
  active: {
    opactiy: 1,
    transition: {
      ...getTransition()
    }
  },
  inactive: {
    opactiy: 0,
    transition: {
      ...getTransition()
    }
  }
}

const getItems = (items, productCategories) => items.map((item, idx) => {
  const categories = []

  item.categories.map(category => {
    productCategories && productCategories.map(productCategory => {
      if(productCategory.id === category && productCategory.slug['en'] !== 'all') {
        categories.push(productCategory.name)
      }
    })
  })

  return (
    <li key={idx+item.name}>
      <ProductCard data={item} isSmall={false} isLarge isProject categories={categories}/>
    </li>
  )
})

export const SectionProjectsList = memo(({ projects }:SectionListType) => {
  const { locale, asPath } = useRouter()
  const [productCategories, setProductCategories] = useState()
  const Items = useMemo(() => getItems(projects, productCategories), [projects, productCategories])

  useEffect(() => {
    async function getProducts() {
      const [data, error] = await callAPI({ type: 'projects-categories', params: { locale } })
      if(error) return []
      setProductCategories(data)
    }

    getProducts()
  }, [])

  if(Items.length < 1) return <></>

  return (
    <Section>
      <Ol initial={'inactive'} animate={'active'} exit={'inactive'} variants={variants} key={asPath}>
        {Items}
      </Ol>
    </Section>
  )
})
