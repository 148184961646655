import { memo, useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import Link from 'next/link'

import { createGrid, getTransition, mediaQuery, hideScrollbar, getP24, getP18, getRelative } from '@/styles'
import { AnimatePresence, motion } from 'framer-motion'
import { useMemo } from 'react'
import { useMediaType } from '@/hooks'
import { ModalMenuMobile } from './ModalMenuMobile'
import { Burger } from './Burger'
import { parseData } from './utils'
import { Picture as Figure } from '../../../../../commons/components/media/Picture'

const variants = {
  'initial': {
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .1,
    }
  },
  'animate': {
    opacity: 1,
    transition: {
      ...getTransition(),
      duration: .1,
    }
  },
  'exit': {
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .1,
    }
  },
}

const variants_props = {
  'initial': 'initial',
  'animate': 'animate',
  'exit': 'exit',
}

const SVG = ({ isActive }) => (
  < AnimatePresence exitBeforeEnter>
  {isActive ? <motion.svg key='acitve' variants={variants} {...variants_props} width="50px" height="50px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Menu" transform="translate(-32.000000, -21.000000)">
          <g id="i--menu" transform="translate(32.000000, 21.000000)">
              <rect id="Rectangle" x="0" y="0" width="50" height="50"></rect>
              <g id="Group" transform="translate(14.000000, 14.000000)" stroke="#4D4D4D" strokeWidth="0.5">
                  <line x1="0" y1="22.9991127" x2="23" y2="9.85855133e-05" id="Stroke-19"></line>
                  <line x1="0" y1="0" x2="23" y2="22.9990141" id="Stroke-21"></line>
              </g>
          </g>
      </g>
  </g>
</motion.svg> : <motion.svg key='inacitve' variants={variants} {...variants_props} width="50px" height="50px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="i--menu" transform="translate(-32.000000, -21.000000)">
              <g transform="translate(32.000000, 21.000000)">
                  <rect id="Rectangle" x="0" y="0" width="50" height="50"></rect>
                  <line className='burger-line' x1="8.5" y1="19.5" x2="40.5" y2="19.5" id="Line" stroke="#4D4D4D" strokeWidth="0.5" strokeLinecap="square"></line>
                  <line className='burger-line' x1="8.5" y1="30.5" x2="40.5" y2="30.5" id="Line-Copy" stroke="#4D4D4D" strokeWidth="0.5" strokeLinecap="square"></line>
              </g>
          </g>
      </g>
  </motion.svg>}
  </AnimatePresence>
)

const Button = styled.button`
  padding-left: ${getRelative(32, 'desktop')};
  z-index: 10;

  svg {
    ${mediaQuery.greaterThan('tablet')`
      width: ${getRelative(50, 'desktop')};
      height: ${getRelative(50, 'desktop')};
    `}
  }
`

const Aside = styled(motion.aside)`
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.background};
  padding-top: ${({ theme }) => getRelative(theme.height.navbar.mobile, 'mobile')};
  z-index: 1;
  position: absolute;

  user-select: none;
  pointer-events: none;

  &.is-active {
    user-select: auto;
    pointer-events: auto;
  }

  figure {
    ${mediaQuery.greaterThan('tablet')`
      width: ${getRelative(321, 'desktop')};
    `}

    img {
      ${mediaQuery.greaterThan('tablet')`
        margin-bottom: ${getRelative(20, 'desktop')};
      `}
    }

    figcaption {
      ${getP18()}
      font-style: normal;
    }
  }

  section {
    li {
      ${getP24()}
      color: ${({ theme }) => theme.colors.text};


      a, button {
        cursor: pointer;
        font-size: inherit;
        color: inherit;
        font-weight: inherit;
        font-family: inherit;
        opacity: inherit;
        transition: opacity 300ms ${({ theme }) => theme.ease};

        &:hover {
          opacity: 1;
        }
      }

      &[data-active='true'] {
        opacity: 1;
      }
    }
  }

  .title {
    pointer-events: none;
  }

  .heading {
    ${mediaQuery.greaterThan('tablet')`
      margin-bottom: ${getRelative(30, 'desktop')};
    `}
  }

  ${mediaQuery.greaterThan('tablet')`
    padding-top: ${({ theme }) => getRelative(theme.height.navbar.desktop, 'desktop')};
  `}

  // --------- GRID ---------
  ${createGrid()}

  ${mediaQuery.greaterThan('tablet')`
    section {
      &.selected {
        li {
          a, button {
            opacity: .4;

            &:hover {
              opacity: 1;
            }
          }

          &[data-active='true'] {
            a, button {
              opacity: 1;
            }
          }
        }
      }

      &.section-1 {
        grid-column: 1 / span 3;
        padding-left: ${getRelative(40, 'desktop')};

        .middle{
          margin-top: ${getRelative(60, 'desktop')};
        }

        .bottom {
          margin-top: ${getRelative(60, 'desktop')};
        }
      }

      &.section-3 {
        grid-column: 10 / span 3;
        padding-rigt: ${getRelative(40, 'desktop')};
      }
    }

    .section-2 {
      grid-column: 4 / span 6;
      padding-left: ${getRelative(26, 'desktop')};

      section {
        display: grid;
        grid-template-columns: 1fr 1fr;

        ul:first-of-type:hover li.heading:first-of-type button { opacity: 1!important; }
        ul:first-of-type:hover li.heading:first-of-type a { opacity: 1!important; }

        ul:last-of-type:hover li.heading:first-of-type button { opacity: 1!important; }
        ul:last-of-type:hover li.heading:first-of-type a { opacity: 1!important; }
      }
    }

    .ul-navbar {
      ${hideScrollbar()}
      max-height: ${({ theme }) => `calc(100vh - ${getRelative(theme.height.navbar.desktop, 'desktop')})`};
      overflow: scroll;
      padding-bottom: ${getRelative(150, 'desktop')};
    }

    .section-2 .ul-navbar {
      &:last-of-type:not(:hover) li {
        &.heading button,
        a {
          opacity: 1;
        }
      }
    }
  `}
`

const variants_aside = {
  'initial': {
    opacity: 0,
    transition: {
      ...getTransition(),
    }
  },
  'animate': {
    opacity: 1,
    transition: {
      ...getTransition(),
    }
  },
  'exit': {
    opacity: 0,
    transition: {
      ...getTransition(),
    }
  },
}

const variants_items = {
  'initial': {
    opacity: 0,
    x: '-20px',
    transition: {
      ...getTransition(),
      duration: .2,
    }
  },
  'animate': {
    opacity: 1,
    x: '0px',
    transition: {
      ...getTransition(),
      duration: .2,
    }
  },
  'exit': {
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .1,
    }
  },
}

const variants_ul = {
  'animate': {
    transition: {
      staggerChildren: 0.05,
    }
  },
}

const variants_section = {
  'initial': {
    transition: {
      ...getTransition(),
    }
  },
  'animate': {
    transition: {
      ...getTransition(),
    }
  },
  'exit': {
    transition: {
      ...getTransition(),
      duration: .1
    }
  },
}

const variants_image = {
  'initial': {
    opacity: 0,
    transition: {
      ...getTransition(),
    }
  },
  'animate': {
    opacity: 1,
    transition: {
      ...getTransition(),
      delay: .1,
    }
  },
  'exit': {
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .1
    }
  },
}

const variants_section_1 = {
  'initial': {
    x: '-50px',
    transition: {
      ...getTransition(),
    }
  },
  'animate': {
    x: '0px',
    transition: {
      ...getTransition(),
    }
  },
  'exit': {
    x: '-50px',
    transition: {
      ...getTransition(),
    }
  },
}

const containsTitle = (data) => {
  for(var i = 0; i < data?.length; i++) {
    if (data[i].type === 'title') {
      return true
    }
  }
  return false
}

const parseSection = ({ data, callback, section, firstLevelActiveIndex = null, onClick, secondSectionData = null, secondDataIdx = null }) => {
  const Items = []
  const Items_2 = []
  const Items_Section2 = []
  const Key_name = data.length ? data[firstLevelActiveIndex]?.title ? data[firstLevelActiveIndex]?.title : data[0].title  : 'key' + Date.now()

  if (secondSectionData) {
    for (let jdx = 0; jdx < secondSectionData.length; jdx++) {
      const item = secondSectionData[jdx]

      let _url = item.url ? `/${item.url.type}/${item.url.slug}${item.url.params ? `?${Object.keys(item.url.params)[0]}=${item.url.params[Object.keys(item.url.params)[0]].value}` : ''}` : ''
      Items_Section2.push(<motion.li key={jdx + item.title} variants={variants_items} className={item.type === 'title' ? `${item.type} ${item.class} heading` : item.class} >
        {_url ? <Link href={_url}>
          <a onClick={onClick}>
            {item.title}
          </a>
        </Link> : <button>{item.title}</button>}
      </motion.li>)
    }
  }

  for (let idx = 0; idx < data?.length; idx++) {
    const item = data[idx]
    const url = typeof item.url === 'string' ? item.url : item.url ? `/${item.url.page}${item.url.slug ? '/' + item.url.slug: ''}` : ''

    if(item.type === 'title' && idx > 0 || Items_2.length > 0) {
      if (secondSectionData) {
        break
      }

      let _url = item.url ? `/${item.url.type}/${item.url.slug}${item.url.params ? `?${Object.keys(item.url.params)[0]}=${item.url.params[Object.keys(item.url.params)[0]].value}` : ''}` : ''

      Items_2.push(<motion.li key={idx} variants={variants_items} className={item.type === 'title' ? `${item.type} ${item.class} heading` : item.class} >
        {_url ? <Link href={_url}>
          <a onClick={onClick} onMouseEnter={callback(section, true, idx)}>
            {item.title}
          </a>
        </Link> : <button>{item.title}</button>}
      </motion.li>)
    } else {
      Items.push(<motion.li key={idx} variants={variants_items} className={item.type === 'title' ? `${item.type} ${item.class} heading` : item.class} data-active={(section === 'section_2' && idx === firstLevelActiveIndex) || item.childPosition === secondDataIdx ? true : false} >
        {url ? <Link scroll={false} href={url}>
          <a onClick={onClick} onMouseEnter={callback(section, true, idx, item.childPosition)}>
          {item.title}
          </a>
        </Link> :
          <button onMouseEnter={callback(section, true, idx, item.childPosition)}>
            {item.title}
          </button>
        }
      </motion.li>)
    }
  }
  return [(
    <>
      <motion.ul className='ul-navbar' key={data + section} variants={variants_ul} >{Items}</motion.ul>
      {Items_2.length > 0 && <motion.ul className='ul-navbar' key={data + section + '2'} variants={variants_ul} >{Items_2}</motion.ul>}
      {Items_Section2.length > 0 && <motion.ul className='ul-navbar' key={data + section + '2'} variants={variants_ul} >{Items_Section2}</motion.ul>}
    </>
  ), Items.length + Key_name]
}

const parseImage = (currentImage, currentCaption) => {
  return [
    currentImage && <Figure key={currentImage?.default?.['2x']?.regular} className='product-card-image' data={{...currentImage, ...(currentCaption && { media_caption: currentCaption })}} lazyLoading={false}/>
  , currentCaption ?? 'key_image']
}

const _Menu = memo<any>(({ data, bottomLinks, middleLinks, isActive, mediaType, onClick }) => {
  const [currentMouseState, setMouseState] = useState({ section_1: false, section_2: false, section_image: false })
  const [secondLevel, setSecondLevel] = useState([])
  const [firstLevelActiveIndex, setFirstLevelActiveIndex] = useState(null)
  const [secondLevelActiveIndex, setSecondLevelActiveIndex] = useState(null)

  const [secondDataIdx, setSecondDataIdx] = useState(null)

  const [currentImage, setCurrentImage] = useState('')
  const [currentCaption, setCurrentCaption] = useState('')
  if (typeof bottomLinks[0] === 'object') bottomLinks[0].class = 'bottom'
  if (typeof middleLinks[0] === 'object') middleLinks[0].class = 'middle'

  const handleChange = useCallback((section, value, idx, secondDataPositionIdx = null) => () => {

    if (section === 'section_image') {
      setSecondDataIdx(secondDataPositionIdx)
    } else {
      setSecondDataIdx(null)
    }

    // set second level items
    if(section === 'section_2' && data[0][idx]?.childPosition) {
      setFirstLevelActiveIndex(idx)
      setSecondLevelActiveIndex(null)
      let secondLevelData = data[data[0][idx].childPosition]
      if (containsTitle(secondLevelData)) {
        let titleWithItems = []
        secondLevelData.forEach(item => {
          titleWithItems.push(item)
          titleWithItems.push(...data[item.childPosition])
        })
        setSecondLevel(titleWithItems)
      } else {
        setSecondLevel(secondLevelData)
      }
    } else if (section === 'section_2') {
      setFirstLevelActiveIndex(idx)
      setSecondLevel([])
      setSecondLevelActiveIndex(null)
    }

    // set first level media
    if (section === 'section_2' && data[0][idx]?.media) {
      setCurrentImage(data[0][idx]?.media)
      setCurrentCaption(data[0][idx]?.caption)
    } else if (section === 'section_2') {
      setCurrentImage('')
      setCurrentCaption('')
    }

    // set second level media
    if (section === 'section_image' && secondLevel[idx]?.media) {
      setCurrentImage(secondLevel[idx]?.media)
      setCurrentCaption(secondLevel[idx]?.caption)
      setSecondLevelActiveIndex(idx)
    } else if (section === 'section_image') {
      setCurrentImage('')
      setCurrentCaption('')
      setSecondLevelActiveIndex(idx)
    }

    // reset active indexes
    if(value === false) {
      if(section === 'section_2' && secondLevel.length === 0) {
        setFirstLevelActiveIndex(null)
      } else if(section === 'section_image') {
        setSecondLevelActiveIndex(null)
      }
    }

    setMouseState((oldState) => {
      return { ...oldState, [section]: value }
    })
  }, [setMouseState, currentMouseState])

  const [Section_1] = useMemo(() => parseSection({ data: [...data[0], ...middleLinks, ...bottomLinks], callback: handleChange, section: 'section_2', firstLevelActiveIndex, onClick }), [data, middleLinks, bottomLinks, firstLevelActiveIndex])

  const [Section_2, key_2] = useMemo(() => parseSection({ data: secondLevel, callback: handleChange, section: 'section_image', onClick, secondSectionData: data[secondDataIdx], secondDataIdx }), [secondLevel, firstLevelActiveIndex, secondDataIdx])

  const [Image, key_image] = useMemo(() => parseImage(currentImage, currentCaption), [currentImage, currentCaption])

  useEffect(() => {
    handleChange('section_1', isActive, 0)()
  }, [isActive])

  return <AnimatePresence>
    {mediaType !== 'mobile' && isActive && <Aside variants={variants_aside} {...variants_props} className={'menu' + isActive ? ' is-active' : ''}>
      <AnimatePresence exitBeforeEnter>
        <motion.section key='section_1' variants={variants_section_1} {...variants_props} className={firstLevelActiveIndex !== null ? 'section-1 selected' : 'section-1'}>{Section_1}</motion.section>
      </AnimatePresence>

      <section key={key_2} className={secondLevelActiveIndex !== null ?  'section-2 selected' : 'section-2'}>
        <AnimatePresence exitBeforeEnter>
          <motion.section key={'section_2' + key_2} variants={variants_section} {...variants_props}>{Section_2}</motion.section>
        </AnimatePresence>
      </section>
      <AnimatePresence exitBeforeEnter>
        <motion.section key={key_image} variants={variants_image} {...variants_props} className="section-3">
          {Image}
        </motion.section>
      </AnimatePresence>
    </Aside>}
  </AnimatePresence>
})

const Menu = memo<any>(({ data, isActive, onClick, setActive }) => {
  const { top_links, bottom_links, middle_links } = data
  const mediaType = useMediaType()
  const [currentLevel, setState] = useState(0)
  const [arrowLevel, setArrowLevel] = useState(0)

  const dataParsed = useMemo(() => parseData(top_links ?? [], mediaType), [top_links])

  const handleBurger = () => {
    if (currentLevel > 0) {
      setState(arrowLevel)
    } else {
      setActive(!isActive)
    }
  }

  return <>
    {mediaType !== 'mobile' &&
      <Button onClick={onClick} className='button-menu' aria-label='menu button'>
        <SVG isActive={isActive} />
      </Button>
    }
    <_Menu onClick={onClick} data={dataParsed} bottomLinks={bottom_links ?? []} middleLinks={middle_links ?? []} isActive={isActive} mediaType={mediaType} currentLevel={currentLevel} setState={setState} />
    {mediaType === 'mobile' &&
      <>
        <Burger onClick={() => handleBurger()} menuActive={isActive} currentLevel={currentLevel}/>
        <ModalMenuMobile onClickCross={() => setActive(false)} isActive={isActive} data={data} setArrowLevel={setArrowLevel} currentLevel={currentLevel} setState={setState}/>
      </>
    }
  </>
})

export default Menu
