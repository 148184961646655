import styled from 'styled-components'
import { mediaQuery, getRelative } from '@/styles'

const Figure = styled.figure<any>`
  pointer-events: none;
  position: absolute;
  height: ${getRelative(9, 'mobile')};
  width: ${getRelative(19, 'mobile')};
  right: 0;

  ${mediaQuery.greaterThan('tablet')`
    height: ${getRelative(9, 'desktop')};
    width: ${getRelative(19, 'desktop')};
  `}

  svg {
    transition: 300ms transform ${({ ease }) => ease};
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const Icon = () => (
  <img src='/images/svg/arrow_black.svg' alt='arrow down'/>
)

export const ArrowSelect = () => {
  return (<Figure><Icon/></Figure>)
}
