import { useEffect } from 'react'
import { useCallback } from 'react'
import { createContext, useContext, useState } from 'react'

const init: any = {
  modals: {},
  setActive: () => {}
}

const ModalContext = createContext(init)

export const ProviderModal = ({ children }) => {
  const [modals, changeState] = useState({})

  const setActive = useCallback((id, isActive, payload) => () => {
    changeState((prevModals) => ({ ...prevModals, [id]: { isActive, payload } }))
  }, [])

  return <ModalContext.Provider value={{ modals, setActive }}>
    {children}
  </ModalContext.Provider>
}

export const useModal = (id: string) => {
  const { modals, setActive } = useContext(ModalContext)
  const [{ isActive, payload }, setModal] = useState({ isActive: false, payload: null })

  const triggerModal = useCallback((isActive, payload = null) => {
    return () => {
      setActive(id, isActive, payload)()
    }
  }, [id])

  useEffect(() => {
    if (!modals[id]) {
      setActive(id, false, null)()
      return
    } else {
      setModal({ ...modals[id] })
    }
  }, [modals, id])

  return { isActive, payload, open: triggerModal(true), close: triggerModal(false), triggerModal }
}
