import { memo } from 'react'
import { AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import { Main, Body, Overlay } from './Layout'
import { useModal } from './'
import { ContentVideo } from '../components/contents/content-types/ContentVideo'
import { mediaQuery, getRelative } from '@/styles'

const ButtonClose = styled.button`
  position: fixed;
  z-index: 100000000000000;
  background-color: transparent;
  width: ${getRelative(40, 'mobile')};
  height: ${getRelative(40, 'mobile')};
  top: ${getRelative(70, 'mobile')};
  right: ${getRelative(20, 'mobile')};

  ${mediaQuery.greaterThan('tablet')`
    width: ${getRelative(40, 'desktop')};
    height: ${getRelative(40, 'desktop')};
    top: ${getRelative(40, 'desktop')};
    right: ${getRelative(40, 'desktop')};
  `}

  &:hover {
    background-color: transparent;
  }

  &.close {
    ${mediaQuery.greaterThan('tablet')`
      padding: 0;
    `}
  }
`

const _Body = styled(Body)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000001;
  height: ${({ isActive }) => isActive ? 'unset' : '0'};
  max-width: 100vw;
  max-height: 100vh;

  ${mediaQuery.greaterThan('tablet')`
    margin: 0;
    width: 100%;
  `}
`

const _Main = styled(Main)`
  max-height: inherit;
  height: inherit;
  max-width: inherit;
  aspect-ratio: 16 / 9;
  max-height: 90vh;
  display: flex;
  align-items: center;

  ${mediaQuery.greaterThan('tablet')`
    max-width: 85vw;
  `}

  figure.figure--video {
    height: auto!important;
  }

  .react-player {
    width: 100%!important;
  }

  figure.figure--video {
    height: auto;
  }

  .react-player {
    width: 100%!important;
  }
`


interface IModalVideo {
  id: string | number,
  platform: string,
  data: any,
  col: number
}

export const modalVideoId = 'modal-video'
export const ModalVideo = memo<IModalVideo>(({ id, platform, data, col }) => {
  const { close, isActive } = useModal(`${modalVideoId}_${platform}_${id}_${col}`)

  const onClick = () => {
    close()
  }

  return isActive && <>
    <Overlay onClick={() => close()} className={isActive ? 'active' : ''} $isActive={isActive}>
    </Overlay>
    <AnimatePresence exitBeforeEnter>
      <_Body isActive={isActive} video>
        <ButtonClose key={id} className='close' onClick={onClick}>
          <img src='/images/svg/i--close--modal.svg' alt='cross icon' width='16' height='16'/>
        </ButtonClose>
        <_Main>
          <ContentVideo data={data} longModal={true} modalActive={isActive}/>
        </_Main>
      </_Body>
    </AnimatePresence>
  </>
})
