
import { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player/lazy'
import { useInView } from 'react-intersection-observer'
import { useModal, ModalVideo, modalVideoId } from '@/modals'
import { mediaQuery, getRelative, getP50 } from '@dy/commons/styles'
import { ContentTypeVideo } from '@dy/commons/src/schema'

const Figure = styled.figure<any>`
  height: auto;
  overflow: hidden;
  position: relative;
  width: 100%;

  .react-player {
    height: 0;
    left: 50%;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    transform: translate(-50%);
    width: ${getRelative(823*16/9, 'desktop')} !important;
  }

  iframe {
    top:0;
    height: 100%;
    left: 50%;
    min-width: 100%;
    position: absolute;
    transform: translateX(-50%);
    width: auto;
  }


  img {
    width: 100%;
  }

  #player {
    height: 100%;
  }

  .react-player {
    video {
      width: auto !important;
    }
  }
`

const PlayIcon = styled.button`
  align-items: center;
  /* background-color: rgba(0, 0, 0, .1);
  border: 1px solid #ffffff;
  border-radius: 100%; */
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: height 300ms ${({ theme }) => theme.ease}, width 300ms ${({ theme }) => theme.ease};
  z-index: 2;
  width: 100%;
  height: 100%;
  display: block;

  span {
    ${getP50()}
    color: #ffffff;
  }
`

interface IContentVideo {
  data: ContentTypeVideo,
  longModal?: boolean,
  playsinline?: boolean,
  modalActive?: boolean
}

export const ContentVideo = memo(({ data, longModal = false, modalActive = false, playsinline = false }:IContentVideo) => {
  const { media: { platform, long_video, short_video } } = data

  const [playing, setPlaying] = useState(!longModal)
  const [ref, inView] = useInView({
    rootMargin: '0px', // TOP RIGHT
    threshold: 0,
  })

  const url = `https://www.${platform}.com${platform === 'youtube' ? 'watch?v=' : ''}${longModal ? long_video : short_video}`

  const { open } = useModal(`${modalVideoId}_${platform}_${long_video}_${data.col.from}`)
  const onClick = () => {
    open()
  }

  useEffect(() => {
    if(longModal) return
    setPlaying(inView)
  }, [inView])

  useEffect(() => {
    if(longModal) setPlaying(modalActive)
  }, [modalActive])

  const config = { youtube: { playerVars: { origin: 'https://www.youtube.com' }}}

  return (
    <>
      <Figure ref={ref} longModal={longModal} className='figure--video'>
        <ReactPlayer className='react-player' width='100%' height='100%' url={url} playsinline={playsinline} controls={longModal} volume={longModal ? .5 : 0} muted={!longModal} loop={!longModal} title={''} playing={playing} {...(platform === 'youtube' && config)}/>
        {long_video && !longModal &&
          <PlayIcon onClick={onClick}>
            <span>Play</span>
          </PlayIcon>
        }
      </Figure>
      {long_video && !longModal && <ModalVideo data={data} id={long_video} platform={platform} col={data.col.from}/>}
    </>
  )
})
