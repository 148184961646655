import { memo } from 'react'
import styled from 'styled-components'
import { useI18n } from '@/i18n'
import { mediaQuery, getRelative, getButtonUnderline, getP18 } from '@/styles'
import { useModal, modalContactId } from '@/modals'

const Section = styled.section`
  grid-area: 1 / 1 / span 1 / span 6;
  padding-top: ${getRelative(20, 'mobile')};
  margin-bottom: ${getRelative(40, 'mobile')};

  ${mediaQuery.greaterThan('tablet')`
    padding: ${getRelative(40, 'desktop')} ${getRelative(40, 'desktop')} 0;
    margin-bottom: 0;
  `}

  p {
    ${getP18()}
  }

  button {
    ${getButtonUnderline()}
  }

  .contact {
    display: grid;
    grid: auto / 1fr 1fr;
    grid-column-gap: ${getRelative(23, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      grid: auto / ${getRelative(205, 'desktop')} ${getRelative(205, 'desktop')};
      grid-column-gap: ${getRelative(25, 'desktop')};
    `}

    > div:last-of-type {
      grid-column-start: 2;
      grid-row-start: 1;
    }

    .mail {
      margin-bottom: ${getRelative(22, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        margin-bottom: ${getRelative(25, 'desktop')};
      `}
    }
  }

  .title {
    opacity: .4;
    margin-bottom: ${getRelative(20, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      margin-bottom: ${getRelative(40, 'desktop')};
    `}
  }
`
export const CallUs = memo(() => {
  const { locales: { footer: { contact } } } = useI18n()
  const { open } = useModal(modalContactId)

  return (
    <Section>
      <button onClick={open}>
        <p dangerouslySetInnerHTML={{ __html: contact.title }} className='title' />
      </button>
      <div className='contact'>
        <div>
          <div className='mail'>
            <p>{contact.mail}</p>
            <button><a href='mailto:info@nexia.es'>info@nexia.es<span></span></a></button>
          </div>
          <div>
            <p>{contact.phone}</p>
            <button><a href='tel:+34 93 715 47 12'>+34 93 715 47 12<span></span></a></button>
          </div>
        </div>
        <div>
          <p>{contact.address}</p>
          <p>Carrer del Priorat, 14-20,<br/>08211 Castellar del<br/>Vallès, Barcelona</p>
        </div>
      </div>
    </Section>
  )
})
