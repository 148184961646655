import { memo, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { ProductCard } from '../commons'
import { Anchor } from './AnchorPoints'

import { useI18n } from '@/i18n'
import { mediaQuery, createGrid, getRelative, getP18 } from '@/styles'
import { useMediaType } from '@dy/commons/hooks'

interface ISectionRelated {
  data: any,
  onInView: any
}

const Section = styled.section`
  ${createGrid()}
  position: relative;

  h2 {
    ${getP18()}
    text-align: left;
    grid-area: 1 / 1 / span 1 / span 6;
    margin-bottom: ${getRelative(30, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 1 / 1 / span 1 / span 3;
      margin-bottom: ${getRelative(40, 'desktop')};
      margin-left: ${getRelative(40, 'desktop')};
    `}
  }

  > div {
    grid-area: 2 / 1 / span 1 / span 6;
    grid-template-columns: 1fr 1fr;
    display: grid;
    grid-column-gap: ${getRelative(23, 'mobile')};
    grid-row-gap: ${getRelative(20, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 2 / 1 / span 1 / span 12;
      margin: 0 ${getRelative(40, 'desktop')};
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: ${getRelative(23, 'desktop')};
      grid-row-gap: 0;
    `}
  }

  .product-card-image {
    img {
      mix-blend-mode: multiply;
    }
  }
`

const getRelated = (products) => products.map((product, idx) => {
  return (<ProductCard key={idx+product.name} data={product} isSmall />)
})

export const SectionRelated = memo<ISectionRelated>(({ data, onInView }) => {
  const mediaType = useMediaType()
  const { locales: { product : { relateds_products } } } = useI18n()
  const Related = useMemo(() => getRelated(mediaType === 'mobile' ? data.slice(0, 2) : data.slice(0, 4)), [data, mediaType])
  const [ref, inView] = useInView({
    rootMargin: '40% 0% 0% 0%',
    threshold: 0.3,
    triggerOnce: false
  })

  useEffect(() => {
    if (inView) {
      onInView('#related')
    }
  }, [inView])

  return (
    <Section ref={ref}>
      <Anchor top={-90} id={'related'}/>
      <h2>{relateds_products}</h2>
      <div>
        {Related}
      </div>
    </Section>
  )
})
