import { memo, useMemo, useState, useRef, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { Zoom } from './Zoom'

import { RadioCheckBoxes2 } from '../commons'
import { mediaQuery, createUnderline, getRelative, getTransition, hideScrollbar, getP18, getRegularButton, hex2Rgba } from '@/styles'
import { useMediaType } from '@dy/commons/hooks'
import { useI18n } from '@/i18n'
import { Anchor } from './AnchorPoints'
import { useRouter } from 'next/router'
import { callAPI } from 'api/urls'
import { Figure } from '@dy/commons/components'
import { parserMedia } from '@dy/commons/utils'

const configuratorSizes = '(min-width: 768px) 15vw, 30vw'
interface ISectionConfigurator {
  data: any,
  productName?: any,
  onInView: any
}

const Section = styled.section`
  display: grid;
  margin: 0 ${getRelative(23, 'mobile')};
  position: relative;

  ${mediaQuery.greaterThan('tablet')`
    grid-template: auto / ${getRelative(205, 'desktop')} 1fr ${getRelative(205, 'desktop')};
    margin: 0 ${getRelative(40, 'desktop')};
  `}

  h2, li, label, button, p {
    ${getP18()}
    text-align: left;
  }

  h2 {
    margin-bottom: ${getRelative(20, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 1 / 1 / span 1 / span 3;
      margin-bottom: ${getRelative(30, 'desktop')};
    `}
  }

  .variants {
    margin-bottom: ${getRelative(45, 'mobile')};
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
    margin-left: -${getRelative(23, 'mobile')};
    margin-right: -${getRelative(23, 'mobile')};
    padding-bottom: ${getRelative(20, 'mobile')};
    position: sticky;
    background-color: ${({ theme }) => theme.colors.background};
    top: ${({ theme }) => getRelative(theme.height.navbar.mobile, 'mobile')};
    z-index: 1;
    display: flex;
    overflow-x: scroll;
    ${hideScrollbar()}

    ${mediaQuery.greaterThan('tablet')`
      border-bottom: unset;
      grid-area: 2 / 1 / span 1 / span 1;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
      padding-bottom: 0;
      display: block;
    `}

    ul{
      margin-left: ${getRelative(23, 'mobile')};
      margin-right: ${getRelative(23, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        margin-right: 0;
        margin-left: 0;
      `}
    }

    li {
      display: inline-block;
      margin-right: ${getRelative(20, 'mobile')};
      opacity: 0.4;
      transition: 300ms opacity ${({ theme: { ease } }) => ease};

      ${mediaQuery.greaterThan('tablet')`
        margin-right: 0;
        display: block;
      `}
    }

    li.active,
    li:hover {
      opacity: 1;
    }
  }

  .variant-info {
    margin: 0 -${getRelative(23, 'mobile')} ${getRelative(40, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 2 / 2 / span 1 / span 1;
      margin: 0 ${getRelative(25, 'desktop')} ${getRelative(50, 'desktop')};
    `}

    #numform {
      display: grid;
      grid: auto-flow / repeat(2, 1fr);
      padding: 0 ${getRelative(25, 'mobile')};
      grid-row-gap: ${getRelative(30, 'mobile')};
      grid-column-gap: ${getRelative(20, 'mobile')};
      word-break: break-all;

      ${mediaQuery.greaterThan('tablet')`
        grid: auto-flow / repeat(4, 1fr);
        padding: 0;
        grid-row-gap: ${getRelative(30, 'desktop')};
        grid-column-gap: ${getRelative(25, 'desktop')};
      `}

      fieldset {
        ${mediaQuery.greaterThan('tablet')`
          margin-left: ${getRelative(25, 'desktop')},
          margin-bottom: ${getRelative(30, 'desktop')};
          width: ${getRelative(206, 'desktop')};
        `}

        input + span {
          display: flex;
        }

        > div {
          margin-bottom: ${getRelative(10, 'mobile')};

          &:last-of-type {
            margin-bottom: 0;
          }

          ${mediaQuery.greaterThan('tablet')`
            margin-bottom: 0;
          `}
        }
      }

      legend {
        opacity: 0.4;
        margin-left: ${getRelative(24, 'mobile')};
        margin-bottom: ${getRelative(20, 'mobile')};

        ${mediaQuery.greaterThan('tablet')`
          margin-left: ${getRelative(24, 'desktop')};
          margin-bottom: ${getRelative(20, 'desktop')};
        `}
      }
    }
  }

  .variant-image-wrapper {
    margin-bottom: ${getRelative(20, 'mobile')};
    display: flex;
    overflow-x: scroll;
    margin-left: -${getRelative(23, 'mobile')};
    margin-right: -${getRelative(23, 'mobile')};
    ${hideScrollbar()}


    .mobile-figure {
      height: ${getRelative(250, 'mobile')};
      margin-right: ${getRelative(10, 'mobile')};
      width: fit-content;
      flex-shrink: 0;


      &:first-of-type {
        margin-left: ${getRelative(23, 'mobile')};
      }


      > span {
        position: relative !important;
      }

      img {
        height: 100% !important;
        object-fit: contain;
        position: relative !important;
        width: 100% !important;
      }
    }

    ${mediaQuery.greaterThan('tablet')`
      display: block;
      margin-bottom: 0;
      display: block;
      margin-left: 0;
      margin-right: 0;
      overflow: visible;
    `}

    .variant-image {

      &:disabled {
        cursor: default;
      }

      ${mediaQuery.greaterThan('tablet')`
        position: relative;
        grid-area: 2 / 3 / span 1 / span 1;
      `}
    }

    .klk-image {
      height: ${getRelative(250, 'mobile')};
      margin-right: ${getRelative(10, 'mobile')};
      flex-shrink: 0;

      &:first-of-type {
        margin-left: ${getRelative(23, 'mobile')};
      }

      ${mediaQuery.greaterThan('tablet')`
        height: ${getRelative(320, 'desktop')};
        width: ${getRelative(206, 'desktop')};
        margin-right: 0;

        &:first-of-type {
          margin-left: 0;
        }
      `}

      img {
        width: 100% !important;
        height: auto !important;
        object-fit: contain;
        margin: 0px auto !important;
        min-width: auto !important;
        max-width: auto !important;
        min-height: auto !important;
        max-height: auto !important;


        ${mediaQuery.greaterThan('tablet')`
          width: 100%;
        `}
      }
    }

    .zoom-btn {
      display: none;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      ${mediaQuery.greaterThan('tablet')`
        position: absolute;
        display: flex;
        bottom: ${getRelative(10, 'desktop')};
        right: ${getRelative(10, 'desktop')};
        height: ${getRelative(24, 'desktop')};
        width: ${getRelative(24, 'desktop')};
      `}
    }
  }

  .output {
    ${getP18()}
    display: grid;
    grid: auto / 2fr 1fr;
    grid-row-gap: ${getRelative(10, 'mobile')};
    grid-column-gap: ${getRelative(24, 'mobile')};
    border-top: 1px solid ${({ theme }) => theme.colors.black};
    margin-left: -${getRelative(23, 'mobile')};
    margin-right: -${getRelative(23, 'mobile')};
    padding: ${getRelative(8, 'mobile')} ${getRelative(23, 'mobile')} ${getRelative(18, 'mobile')};
    margin-bottom: -${getRelative(18, 'mobile')};
    margin-top: ${getRelative(20, 'mobile')};
    background-color: ${({ theme }) => theme.colors.background};
    position: sticky;
    bottom: 0;
    z-index: 2;

    button:disabled {
      cursor: default;
    }

    .o-output {
      color: ${({ theme }) => theme.colors.orange};

      &.none {
        color: ${({ theme }) => theme.colors.grey_dark};
      }
    }

    .code {
      grid-area: 1 / 1 / span 1 / span 1;
    }

    .download {
      grid-area: 2 / 1 / span 1 / span 2;
      display: flex;
      align-items: self-start;

      a {
        ${createUnderline({ color: 'orange', height: 0.35 })}
        color: ${({ theme }) => theme.colors.orange};
        margin-right: ${getRelative(15, 'mobile')};
      }

      figure {
        width: ${getRelative(16, 'mobile')};
        height: ${getRelative(18, 'mobile')};
      }
    }

    .clear {
      grid-area: 1 / 2 / span 1 / span 1;
      display: flex;
      align-items: self-start;
      margin-left: ${getRelative(24, 'desktop')};

      img {
        display: none;

        ${mediaQuery.greaterThan('tablet')`
          display: inline;
        `}
      }

      button {
        ${createUnderline({ color: 'black', height: 0.35 })}
        opacity: .4;
      }
    }

    .price {
      grid-area: 1 / 2 / span 1 / span 1;
    }

    .desktop-helper {
      display: contents;

      ${mediaQuery.greaterThan('tablet')`
        grid-area: 1 / 2 / span 1 / span 1;
        margin: ${getRelative(12, 'desktop')} ${getRelative(12, 'desktop')} ${getRelative(30, 'desktop')} ${getRelative(25, 'desktop')};
        display: grid;
        grid: auto-flow / repeat(4,1fr);
        grid-column-gap: ${getRelative(25, 'desktop')};
      `}
    }

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 3 / 1 / span 1 / span 3;
      grid-template: auto / ${getRelative(205, 'desktop')} 1fr ${getRelative(205, 'desktop')};
      grid-row-gap: ${getRelative(40, 'desktop')};
      grid-column-gap: 0;
      border-top: 1px solid ${({ theme }) => theme.colors.black};
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      padding: 0;
      position: sticky;

      .code {
        grid-area: 1 / 1 / span 1 / span 2;
        margin-left: ${getRelative(24, 'desktop')};
      }

      .download {
        grid-area: 1 / 2 / span 1 / span 2;
        justify-self: center;

        a {
          margin-right: ${getRelative(10, 'desktop')};
        }

        figure {
          width: ${getRelative(17, 'desktop')};
          height: ${getRelative(19, 'desktop')};
        }
      }

      .clear {
        grid-area: 1 / 4 / span 1 / span 1;
        display: flex;
        align-items: self-start;
        margin-left: ${getRelative(24, 'desktop')};

        button {
          margin-right: ${getRelative(10, 'desktop')};
        }

        figure {
          width: ${getRelative(20, 'desktop')};
          height: ${getRelative(20, 'desktop')};
        }
      }

      .price {
        grid-area: 2 / 2 / span 1 / span 1;
        margin-left: ${getRelative(24, 'desktop')};
      }
    `}
  }

  &.small .dy-transitional-slide {

    figure {
      width: ${getRelative(326, 'mobile')};
      height: ${getRelative(250, 'mobile')};
      margin: ${getRelative(132, 'mobile')} auto 0;

      ${mediaQuery.greaterThan<any>('tablet')`
        width: ${getRelative(667, 'desktop')};
        height: ${getRelative(510, 'desktop')};
        margin: ${getRelative(150, 'desktop')} auto 0;
      `}
    }

    img { object-fit: contain; }
    h2 { color: unset; }
    p { color: unset; }
  }
`

const ButtonExpand = styled.div<any>`
  position: absolute;
  display: flex;
  align-items: end;
  justify-content: center;
  width: 100%;
  bottom: 0;
  background: linear-gradient(transparent 20%, ${({ theme }) => theme.colors.background} 80%);
  height: ${getRelative(200, 'mobile')};

  button {
    ${getRegularButton()};

    &:hover {
      background-color: ${({ theme }) => hex2Rgba(theme.colors.black, 1)};
    }
  }
`

const MobileFormWrapper = styled.div<any>`
  height: ${({ isExpandedMobile }) => isExpandedMobile ? '100%' : getRelative(200, 'mobile')};
  overflow: hidden;
  position: relative;
  margin-bottom: ${getRelative(20, 'mobile')};
  transition: 500ms height ${({ theme: { ease } }) => ease};

  ${mediaQuery.greaterThan('tablet')`
    display: contents;
  `}

  > *:nth-last-child(2) {
    margin-bottom: ${getRelative(100, 'mobile')};
  }
`

const imageVariants = {
  initial: {
    opacity: 0,
    transition: {
      ...getTransition(),
    }
  },
  animate: {
    opacity: 1,
    transition: {
      ...getTransition(),
    }
  },
  exit: {
    opacity: 0,
    transition: {
      ...getTransition(),
    }
  },
}

const getConfigOption = (options, title, setOptions, setImage, slug, parent_id, type) => options.map(({ name, id, media, visible, active }, idx) => {
  const handleChange = (value) => {
    setOptions(slug, id, { value, parent_id, type })()

    if (setImage) {
      if (media?.length && media[0].configurator) {
        // setImage({ image: media[0].configurator, zoom: true, type: 'color', key: id + idx + '', zoom_images: [...media, DEFAULT_MODEL] })
      } else {
        if (DEFAULT_MODEL) {
          setImage({ image: DEFAULT_MODEL.configurator, zoom: true, type: 'model', key: 'default', zoom_images: [DEFAULT_MODEL] })
        } else {
          setImage({ image: null, zoom: false, type: '', key: '', zoom_images: [] })
        }
      }
    }
  }

  return (
    <div key={idx}>
      <RadioCheckBoxes2 key={active} disabled={!visible} active={active} onChange={handleChange} option={name} title={title} />
    </div>
  )
})

const getConfigOptions = (configOptions) => {
  const { title, options, setOptions, setImage, slug, parent_id, type } = configOptions
  const configOption = getConfigOption(options, title, setOptions, setImage, slug, parent_id, type)

  return (
    <fieldset key={title}>
      <legend>{title}</legend>
      {configOption}
    </fieldset>
  )
}

const getVariantInfos = (infos, setOptions, setImage) => {
  const _options = []
  for (let idx = 1; idx < infos.length; idx++) {
    const { option_values, name, type, slug, id: parent_id } = infos[idx]
    _options.push(getConfigOptions({ parent_id, options: option_values, title: name, slug, setOptions, setImage: type === 'color' ? setImage : null, type }))
  }

  return _options
}

const getVariants = (variants, setVariant, selectedVariant, setImage) => variants.option_values.map(({ name, id, media, downloads, slug }, idx) => {
  const keyValue = idx + id + slug + ''

  if (idx === selectedVariant.idx && id !== selectedVariant.id) {
    const download_item = downloads.find(item => item.spec_sheet)
    const download = download_item ? download_item.url : null
    setVariant({ idx, id, download, parent_id: variants.id })

    setImage((old) => {
      if (old.type !== 'color') {
        if (media.length) {
          DEFAULT_MODEL = media[0]
          const image = media[0].configurator

          return { image, zoom: true, type: 'model', key: keyValue, zoom_images: media }
        }

        return { image: null, zoom: false, type: 'model', key: '', zoom_images: [] }
      }
      return old
    })
  }

  const handleClick = () => {
    const download_item = downloads.find(item => item.spec_sheet)
    const download = download_item ? download_item.url : null
    setVariant({ idx, id, download, parent_id: variants.id })
    setImage((_old) => {
      if (media.length) {
        DEFAULT_MODEL = media[0]
        return { image: media[0].configurator, zoom: true, type: 'model', key: keyValue, zoom_images: media }
      }

      return { image: null, zoom: false, type: 'model', key: '', zoom_images: [] }
    })
  }

  return (
    <li key={idx} className={idx === selectedVariant.idx ? 'active' : ''}>
      <button onClick={handleClick}>{name}</button>
    </li>
  )
})

const getMobileImages = images => images.map(({ configurator }, idx) => {
  return (
    <Figure className='mobile-figure' key={idx} media={parserMedia(configurator)} layout='fill' quality={65} sizes={configuratorSizes} />
  )
})

const NO_APLICA = ['dimming', 'emergency']
const NO_APLICA_LOCALE_KEY = { 'dimming': 'no_dimming', 'emergency': 'no_emegency' }
const id_no_aplica = 'no-aplica'
const useConfiguratorState = (initOptions, slug, locale, pathname) => {
  const [{ options, selectedOptions, selectedVariant, lastOptionSelected }, setState] = useState({ lastOptionSelected: { slug: '', active: false }, options: [], allOptions: initOptions, selectedOptions: {}, selectedVariant: { idx: 0, id: null, download: null, parent_id: null } })
  const { locales: { product: { _configurator } } } = useI18n()

  const changeSelectedOptions = useCallback((input_key, newValue, obj) => () => {
    setState((oldState) => {
      const selectedOptions = { ...oldState.selectedOptions }

      if (obj.value) {
        selectedOptions[input_key] = { id: newValue, parent_id: obj.parent_id, type: obj.type }
      } else {
        delete selectedOptions[input_key]
      }

      const _old = { ...oldState, selectedOptions, lastOptionSelected: { slug: input_key, active: obj.value } }
      checkNewState(_old, pathname)
      return _old
    })

  }, [pathname])

  const getBaseOptions = useCallback(async (id, all) => {
    const ids = [id].toString()
    const [options, error] = await callAPI({
      type: pathname.includes('/usa') ? 'usa-configurator' : 'configurator',
      params: { locale, slug },
      query: { ids }
    })

    if (error) {
      console.warn('Error getting initial data configurator')
      return []
    }

    return options.map((option, idx) => {
      const option_values = []


      for (let j = 0; j < option.option_values.length; j++) {
        if (option.type === 'model' || option.slug === 'model' || option.slug === 'modelo') {
          option_values.push(...all[idx].option_values.map((_item) => ({ ..._item, visible: _item.id === id, active: false })))
        } else {
          const currentId = option.option_values[j].id
          const allObject = all.filter(allOption => allOption.id === option.id)?.[0]
          const optionValue = allObject?.option_values.find((newitem) => newitem.id === currentId)

          if (optionValue) {
            option_values.push({ ...optionValue, visible: true, active: id === optionValue.id })
          } else {
            console.warn('Error configurator getting base options -> ', all, options)
          }
        }
      }

      if (NO_APLICA.includes(option.type)) {
        const obj = {
          active: false,
          id: id_no_aplica + '-' + option.type,
          name: _configurator[NO_APLICA_LOCALE_KEY[option.type]],
          slug: "no-aplica",
          visible: true,
        }
        option_values.unshift(obj)
      }

      return { ...option, option_values }
    })
  }, [])

  const checkNewState = async (oldState, pathname) => {
    const { selectedOptions, selectedVariant, options, lastOptionSelected } = oldState

    if (!selectedVariant.parent_id || !selectedVariant.id) return

    const ids = [`${selectedVariant.parent_id}-${selectedVariant.id}`, ...Object.values(selectedOptions).map(({ id, parent_id }) => id.toString().includes(id_no_aplica) ? '' : `${parent_id}-${id}`).filter(item => item)].toString()

    const [data, error] = await callAPI({
      type: pathname.includes('/usa') ? 'usa-configurator' : 'configurator',
      params: { locale, slug }, query: { ids }
    })

    if (!error) {
      if (typeof data.length === 'number') {
        const newOptions = options.map((item, idx) => {
          return {
            ...item, option_values: item.option_values.map((_item, _idx) => {

              let match
              if (item.slug === lastOptionSelected.slug) {
                // compare with last and let them open
                match = lastOptionSelected.active ? _item.visible : true
              } else {
                if (_item.id.toString().includes(id_no_aplica)) {
                  match = true
                } else {
                  match = data[idx]?.option_values.find((toFind) => toFind.id === _item.id) ? true : false
                }
              }
              const active = Object.values(selectedOptions).find(({ id }) => id === _item.id)
              return { ..._item, visible: match, active }
            })
          }
        })
        setState(old => ({ ...old, options: [...newOptions] }))
      } else if (typeof data.length !== 'number') {
        // final combination
        const newOptions = options.map((item, idx) => {
          return {
            ...item, option_values: item.option_values.map((_item, _idx) => {

              let match
              if (item.slug === lastOptionSelected.slug) {
                // compare with last and let them open
                match = lastOptionSelected.active ? _item.visible : true
              } else {
                if (_item.id.toString().includes(id_no_aplica)) {
                  match = true
                } else {
                  match = data.options[idx]?.option_values.find((toFind) => toFind.id === _item.id) ? true : false
                }
              }
              const active = Object.values(selectedOptions).find(({ id }) => id === _item.id)
              return { ..._item, visible: match, active }
            })
          }
        })
        setState(old => ({ ...old, options: [...newOptions] }))
      }

    } else {
      console.warn('error calling configurator API')
    }
  }

  const changeVariant = useCallback(async (obj) => {

    const options = await getBaseOptions(`${obj.parent_id}-${obj.id}`, initOptions)

    setState(() => {
      return { options, allOptions: [...initOptions], selectedVariant: { ...obj }, selectedOptions: {}, lastOptionSelected: { slug: '', active: false } }
    })
  }, [])

  const resetState = useCallback(async (newState = null) => {
    const all = newState ? [...newState] : [...initOptions]

    const { id, downloads } = all[0].option_values[0]

    const download_item = downloads.find(item => item.spec_sheet)
    const download = download_item ? download_item.url : null

    const selectedVariant = { idx: 0, id: null, download, parent_id: null }
    const options = await getBaseOptions(`${all[0].id}-${id}`, all)

    setState({ lastOptionSelected: { slug: '', active: false }, options, allOptions: all, selectedOptions: {}, selectedVariant })
  }, [])

  //@ts-ignore
  useEffect(() => {
    return () => resetState()
  }, [])


  return { options, selectedOptions, selectedVariant, changeSelectedOptions, changeVariant, resetState, lastOptionSelected }
}

const formVariants = {
  animate: {
    opacity: 1,
    transition: {
      ...getTransition()
    }
  },
  initial: {
    opacity: 0,
    ...getTransition()
  },
  exit: {
    opacity: 0,
    ...getTransition()
  },
}

let DEFAULT_MODEL = null
export const SectionConfigurator = memo<ISectionConfigurator>(({ data, onInView }) => {
  const { slug } = data
  const { locales: { product: { configurator, _configurator } } } = useI18n()
  const refForm = useRef()
  const refInput = useRef()
  const refColorImage = useRef('')
  const { locale, pathname } = useRouter()
  const [loading, setLoading] = useState(false)
  const [isActive, setActive] = useState(false)
  const [isExpandedMobile, setExpandMobile] = useState(false)
  const { options, selectedOptions, changeSelectedOptions, changeVariant, resetState, selectedVariant } = useConfiguratorState(data.options, slug, locale, pathname)
  const [output, setOutput] = useState({ price: null, code: _configurator.fill_all })
  const [currentImage, setImage] = useState({ image: null, zoom: false, type: '', key: '', zoom_images: [] })

  const Variants = useMemo(() => options.length ? getVariants(options[0], changeVariant, selectedVariant, setImage) : [], [selectedVariant, options])
  const VariantInfo = useMemo(() => options.length ? getVariantInfos([...options], changeSelectedOptions, setImage) : [], [selectedVariant, options, selectedOptions])
  const MobileImages = useMemo(() => getMobileImages(currentImage.zoom_images), [currentImage])

  const mediaType = useMediaType()
  const [sectionRef, inView] = useInView({
    rootMargin: '40% 0% 0% 0%',
    threshold: 0.3,
    triggerOnce: false
  })

  const setColorImage = useCallback(async (ids) => {
    const [data, error] = await callAPI({
      type: pathname.includes('/usa') ? 'usa-configurator-color' : 'configurator-color',
      params: { locale, slug },
      query: { ids }
    })

    if (!error && data.length) {
      const zoom_images = [...data]
      if (DEFAULT_MODEL) {
        zoom_images.push(DEFAULT_MODEL)
      }

      setImage({ image: data[0].configurator, zoom: true, type: 'color', key: ids, zoom_images })
    } else {
      console.info('error no image configurator -> ', ids, data)
    }
  }, [])

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    if (Object.values(selectedOptions).length < options.length - 1) {
      setOutput({ code: _configurator.fill_all, price: '' })
      return
    }

    const ids = [`${selectedVariant.parent_id}-${selectedVariant.id}`, ...Object.values(selectedOptions).map(({ id, parent_id }) => id.toString().includes(id_no_aplica) ? '' : `${parent_id}-${id}`).filter(item => item)].toString()


    const [data, error] = await callAPI({
      type: pathname.includes('/usa') ? 'usa-configurator' : 'configurator',
      params: { locale, slug },
      query: { ids }
    })

    if (!error) {
      if (typeof data.length === 'number') {
        setOutput({ code: _configurator.not_available, price: '' })
        setLoading(false)
      } else {
        setOutput({ code: data.variant.code, price: Number(data.variant.price).toFixed(2).replace('.', ',') + ' EUR' })
        setLoading(false)
      }
    } else {
      setOutput({ code: _configurator.not_available, price: '' })
      setLoading(false)
    }
  }

  const clickReset = useCallback(() => {
    if (refForm.current) {
      //@ts-ignore
      refForm.current.reset()
    }

    resetState()
    setOutput({ price: null, code: _configurator.fill_all })
    setImage({ image: null, zoom: false, type: 'model', key: '', zoom_images: [] })
  }, [refForm])

  const toggleExpand = () => {
    setExpandMobile(true)
  }

  useEffect(() => {
    if (Object.values(selectedOptions).length === options.length - 1) {
      //@ts-ignore
      refInput.current.click()
    } else {
      setOutput({ price: null, code: _configurator.fill_all })
    }
  }, [selectedOptions, options, selectedVariant])

  //@ts-ignore
  useEffect(() => {
    if (inView) {
      onInView('#configurator')
    }
  }, [inView])

  useEffect(() => {
    resetState(data.options)
  }, [data.options])

  useEffect(() => {
    let newRef = ''
    const colors = []
    Object.keys(selectedOptions).map((key) => {
      if (selectedOptions[key].type === 'color') {
        const id = selectedOptions[key].id
        newRef += id
        colors.push(id)
      }
    })

    if (newRef !== refColorImage.current) {
      refColorImage.current = newRef
      if (colors.length) {
        const ids = `${selectedVariant.id},${colors.toString()}`
        setColorImage(ids)
      }
    }
  }, [selectedOptions, selectedVariant])

  useEffect(() => {
    return () => DEFAULT_MODEL = null
  }, [])

  return (
    <Section ref={sectionRef} className='small'>
      <Anchor top={-90} id={'configurator'} />
      <h2>{configurator}</h2>
      <div className='variants'>
        <ul>
          {Variants}
        </ul>
      </div>
      <MobileFormWrapper isExpandedMobile={isExpandedMobile}>
        <AnimatePresence exitBeforeEnter>
          {VariantInfo.length ? <motion.div key={selectedVariant.idx} variants={formVariants} initial='initial' exit='exit' animate='animate' className='variant-info'>
            <form onSubmit={handleSubmit} id="numform" ref={refForm}>
              {VariantInfo}
              <input ref={refInput} type='submit' hidden />
            </form>
          </motion.div> : <motion.div key={'Loading'} variants={formVariants} initial='initial' exit='exit' animate='animate' className='variant-info'>
            <form onSubmit={handleSubmit} id="numform" ref={refForm}>
              <h2>{_configurator.loading}</h2>
              <input ref={refInput} type='submit' hidden />
            </form>
          </motion.div>}
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter>
          {currentImage.image && <motion.div key={currentImage.key} className='variant-image-wrapper' variants={imageVariants} initial='initial' exit='exit' animate='animate'>
            {mediaType === 'mobile' ?
              MobileImages : <button className='variant-image' disabled={!currentImage.zoom} onClick={() => setActive(true)}>
                <Figure className='klk-image' media={parserMedia(currentImage.image)} layout='fill' quality={65} sizes={configuratorSizes} />
                {currentImage.zoom && <figure className='zoom-btn'>
                  <img src='/images/svg/i--zoom.svg' alt='image zoom' />
                </figure>}
              </button>}
          </motion.div>}
        </AnimatePresence>
        {(mediaType === 'mobile' && !isExpandedMobile) && <ButtonExpand>
          <button onClick={toggleExpand}>
            {_configurator.open_mobile}
          </button>
        </ButtonExpand>}
      </MobileFormWrapper>
      {(mediaType !== 'mobile' || isExpandedMobile) &&
        <div className='output'>
          <div className='desktop-helper'>
            {VariantInfo.length ? <div className='code'>
              {
                //@ts-ignore
                <button disabled>{_configurator.code}<span></span></button>
              }
              <div className={`o-output ${typeof output.price !== 'string' && Object.keys(selectedOptions).length === 0 ? 'none' : ''}`}>{loading ? _configurator.loading : typeof output.code === 'string' ? output.code : ''}</div>
            </div> : <></>}
            {selectedVariant.download ? <div className='download'>
              <a target={'_blank'} href={selectedVariant.download} rel='noreferrer'>{_configurator.download}<span></span></a>
              <figure>
                <img src='/images/svg/i--download.svg' alt='Icon download' />
              </figure>
            </div> : <></>}
            <AnimatePresence>
              {Object.keys(selectedOptions).length && <motion.div variants={imageVariants} initial='initial' exit='exit' animate='animate' className='clear'>
                <button onClick={clickReset}>{_configurator.clear}<span></span></button>
                <figure>
                  <img src='/images/svg/i--clear.svg' alt='Icon clear selection' />
                </figure>
              </motion.div>}
            </AnimatePresence>
            <div className='price'>
              <div></div>
              <div></div>
              {/*  // TEMP PRICE DISABLED */}
              {/* <div>{_configurator.price}</div>
              <div>{typeof output.price === 'string' ? output.price : ''}</div> */}
            </div>
          </div>
        </div>
      }
      {currentImage.zoom && <Zoom onClick={() => setActive(false)} isActive={isActive} data={currentImage.zoom_images} />}
    </Section>
  )
})
