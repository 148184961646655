
import { useMemo, useEffect, useCallback } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { getRelative, getTransition, getP24_2 } from '@/styles'
import { parseData } from './utils'

const Ul = styled(motion.ul)`
  height: 100%;
  grid-area: 1 / 1 / span 1 / span 6;
  margin-top: ${getRelative(30, 'mobile')};
`

const Li = styled(motion.li)`
  opacity: 0;
  margin-bottom: ${getRelative(10, 'mobile')};

  &.heading {
    span {
      opacity: .4;
      pointer-events: none;
      text-transform: capitalize;
    }

    ~ .heading {
      margin-top: ${getRelative(46, 'mobile')};
    }
  }

  &:not(.middle) + .middle {
    margin-top: ${getRelative(60, 'mobile')};
  }

  &:not(.bottom) + .bottom {
    margin-top: ${getRelative(60, 'mobile')};
  }

  button, a, span {
    ${getP24_2()}
    font-weight: 300;
  }
`

const variants_props = {
  'initial': 'initial',
  'animate': 'animate',
  'exit': 'exit',
}

const listVariants = {
  'animate': {
    transition: {
      staggerChildren: 0.05
    }
  },
}

const variants_items = {
  'initial': {
    opacity: 0,
    x: '-20px',
    transition: {
      ...getTransition(),
      duration: .2,
    }
  },
  'animate': {
    opacity: 1,
    x: '0px',
    transition: {
      ...getTransition(),
      duration: .2,
    }
  },
  'exit': {
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .1,
    }
  },
}

const getLevel = (data, fn, closeNavbar) => {
  const Items = []
  let arrow = 0

  for (let idx = 0; idx < data.length; idx++) {
    const { title, childPosition, type, url, className } = data[idx]

    if (title !== 'arrow') {
      const handleClick = (type !== 'simple') && childPosition ? () => {
        fn(childPosition)
      } : null

      if (type === 'simple') {
        let _onClick = closeNavbar
        const _url = typeof url === 'string' ? url : url ? `/${url.page}${url.slug ? '/' + url.slug: ''}` : ''

        // if (params) {
        //   const key = Object.keys(params)[0]
        //   const value = params[key]
        //   _onClick = () => {
        //     closeNavbar()
        //   }
        // }

        Items.push(
          <Li variants={variants_items} {...variants_props} key={title} className={className ? className : ''}>
            <Link scroll={false} href={_url} >
              <a onClick={_onClick}>{title}<span></span></a>
            </Link>
          </Li>
        )
      } else if (type === 'title') {
        Items.push(
          <Li variants={variants_items} {...variants_props} key={title} className='heading'>
            <span>{title}</span><span></span>
          </Li>
        )
      } else {
        Items.push(
          <Li variants={variants_items} {...variants_props} key={title}>
            <button onClick={handleClick}>{title}<span></span></button>
          </Li>
        )
      }
    } else {
      arrow = childPosition
    }
  }

  return [arrow, Items]
}

const containsTitle = (data) => {
  for(var i = 0; i < data.length; i++) {
    if (data[i].type === 'title') {
      return true
    }
  }
  return false
}

const getCurrentLevelData = (dataParsed, currentLevel, middle_links, bottom_links) => {
  let currentLevelData = []
  if (containsTitle(dataParsed[currentLevel])) {
    dataParsed[currentLevel].map(item => {
      if (item.type === 'title') {
        currentLevelData.push(item)
        let childArray = dataParsed[item.childPosition]
        if(childArray[0].title === 'arrow') {
          childArray.shift()
        }
        currentLevelData.push(...childArray)
      } else {
        currentLevelData.push(item)
      }
    })
  } else if (currentLevel === 0) {
    middle_links.map(item => {
      item.className = 'middle'
    })

    bottom_links.map(item => {
      item.className = 'bottom'
    })
    currentLevelData = [...dataParsed[currentLevel], ...middle_links, ...bottom_links]
  } else {
    currentLevelData = dataParsed[currentLevel]
  }

  return currentLevelData
}

export const Multilevel = ({ items, close, currentLevel, setState, setArrowLevel }) => {
  const { top_links, middle_links, bottom_links } = items

  const closeNavbar = useCallback(() => {
    setState(0)
    close()
  }, [])

  const dataParsed = useMemo(() => parseData(top_links), [ top_links ])

  const levelData = useMemo(() => getCurrentLevelData(dataParsed, currentLevel, middle_links ?? [], bottom_links ?? []), [dataParsed, currentLevel, middle_links, bottom_links])

  const [arrow, Level] = useMemo(() => getLevel(levelData, setState, closeNavbar) , [levelData, closeNavbar, setState])

  useEffect(() => {
    setArrowLevel(arrow)
  }, [arrow])

  return (
    <AnimatePresence exitBeforeEnter>
      <Ul key={currentLevel} variants={listVariants} {...variants_props}>
        {Level}
      </Ul>
    </AnimatePresence>
  )
}
