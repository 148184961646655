"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.cookie = void 0;
var PROD = process.env.SITE_ENVIRONMENT !== 'development' && process.env.SITE_ENVIRONMENT !== 'local';
exports.cookie = {
    set: function (name, value, days, seconds, path) {
        if (days === void 0) { days = 365; }
        if (seconds === void 0) { seconds = null; }
        if (path === void 0) { path = '/'; }
        if (typeof window === 'undefined')
            return;
        var date = new Date();
        var expires = '';
        if (seconds) {
            date.setTime(date.getTime() + (seconds * 1000));
        }
        else if (days) {
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        }
        expires = "; expires=".concat(date.toUTCString());
        document.cookie = "".concat(name, "=").concat((value || '')).concat(expires, "; path=").concat(path, "; ").concat(PROD ? 'secure;' : '');
    },
    setObj: function (_a) {
        var name = _a.name, value = _a.value, _b = _a.days, days = _b === void 0 ? 365 : _b, _c = _a.seconds, seconds = _c === void 0 ? null : _c, _d = _a.path, path = _d === void 0 ? '/' : _d;
        if (typeof window === 'undefined')
            return;
        var date = new Date();
        var expires = '';
        if (seconds) {
            date.setTime(date.getTime() + (seconds * 1000));
        }
        else if (days) {
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        }
        expires = "; expires=".concat(date.toUTCString());
        document.cookie = "".concat(name, "=").concat((value || '')).concat(expires, "; path=").concat(path, "; domain=.nexia.es; ").concat(PROD ? 'secure;' : '');
    },
    get: function (name) {
        var e_1, _a;
        var nameEQ = "".concat(name, "=");
        var arrayOfCookies = document.cookie.split(';');
        try {
            for (var arrayOfCookies_1 = __values(arrayOfCookies), arrayOfCookies_1_1 = arrayOfCookies_1.next(); !arrayOfCookies_1_1.done; arrayOfCookies_1_1 = arrayOfCookies_1.next()) {
                var rawCookie = arrayOfCookies_1_1.value;
                var cookie_1 = rawCookie;
                while (cookie_1.charAt(0) == ' ')
                    cookie_1 = cookie_1.substring(1, cookie_1.length);
                if (cookie_1.indexOf(nameEQ) == 0)
                    return cookie_1.substring(nameEQ.length, cookie_1.length);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (arrayOfCookies_1_1 && !arrayOfCookies_1_1.done && (_a = arrayOfCookies_1.return)) _a.call(arrayOfCookies_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return null;
    },
    removeCookie: function (name) { if (typeof window !== 'undefined')
        document.cookie = "".concat(name, "=; Max-Age=-99999999;"); }
};
