import { memo, useEffect, useMemo } from 'react'
import Image from 'next/image'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { createGrid, getRelative, mediaQuery, getInvertedButtonUnderline, getP90, getP50, getP24, createUnderline } from '@/styles'
import { useI18n } from '@/i18n'
import { useRecoilState } from 'recoil'
import { navbarColorState } from '@/recoil'
import { staggerChildVariants, imgVariants, basicVariantsProps } from '../commons'
import { useRouter } from 'next/router'

interface IHeader {
  data: any,
  homepage?: any
}

const staggerWrapperVariants = {
  animate: {
    transition: {
      delay: 0.5,
      staggerChildren: 1,
    }
  },
}

const MediaWrapper = styled(motion.div)<any>`
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.img_bg};

  > div {
    height: inherit;
  }
`

const HeaderElem = styled.header<any>`
  ${createGrid()}
  grid-auto-rows: ${({ fullWidth }) => fullWidth ? '1fr' : '50vh 50vh'};
  position: relative;
  height: 100vh;

  ${mediaQuery.greaterThan('tablet')`
    grid-auto-rows: auto-flow;
  `}

  > div:first-of-type {
    grid-area: 1 / 1 / span 1 / span 6;
    background-color: ${({ theme, fullWidth }) => fullWidth ? 'transparent' : theme.colors.creme};
    margin: 0 ${({ fullWidth }) => fullWidth ? '0' : '-' + getRelative(23, 'mobile')};
    padding: 0 ${({ fullWidth }) => fullWidth ? '0' : getRelative(23, 'mobile')};
    align-self: ${({ fullWidth, text_position }) => fullWidth && text_position === 'center' ? text_position : ''};
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    ${({ fullWidth }) => !fullWidth && `
      justify-content: center;
    `}

    ${mediaQuery.greaterThan<any>('tablet')`
      grid-area: ${({ text_position }) => text_position === 'left' ? '1 / 1 / span 1 / span 6' : '1 / 4 / span 1 / span 6'};
      padding: 0 ${({ fullWidth, text_position }) => fullWidth && text_position !== 'left' ? getRelative(26, 'desktop') : '0'};
      margin: 0;

      ${({ fullWidth }) => !fullWidth && `
        justify-content: space-between;
      `}
    `}

    h1, p {
      ${({ fontLarge, homepage }) => fontLarge === 'big' && !homepage ? getP90() : getP50()}
      text-align: ${({ fullWidth, text_position }) => fullWidth && text_position === 'left' ? text_position : 'center'};
      color: ${({ theme, fullWidth, text_color }) => !fullWidth || text_color === 'dark' ? theme.colors.black : theme.colors.white};

      ${mediaQuery.greaterThan<any>('tablet')`
        margin-left: ${({ fullWidth, text_position }) => !fullWidth || text_position === 'left' ? getRelative(40, 'desktop') : '0'};
        margin-right: ${({ fullWidth }) => !fullWidth ? 'calc(100vw / 12)' : '0'};
        text-align: ${({ fullWidth, text_position }) => fullWidth && text_position === 'center' ? text_position : 'left'};
      `}
    }

    h1 {
      margin-top: ${({ fullWidth, text_position }) => !fullWidth || text_position === 'center' ? '0' : getRelative(100, 'mobile')};

      ${mediaQuery.greaterThan<any>('tablet')`
        margin-top: ${({ fullWidth, text_position }) => !fullWidth || text_position === 'left' ? getRelative(100, 'desktop') : 'auto'};
      `}
    }

    h1 + .link {
      margin-top: ${getRelative(30, 'mobile')};

      ${mediaQuery.greaterThan<any>('tablet')`
        margin-top: ${getRelative(40, 'desktop')};
      `}
    }

    p {
      margin-top: ${getRelative(30, 'mobile')};
      margin-bottom: ${({ fullWidth, text_position }) => fullWidth && text_position === 'left' && getRelative(90, 'mobile')};

      ${mediaQuery.greaterThan<any>('tablet')`
        margin-top: ${getRelative(40, 'desktop')};
        margin-bottom: ${({ fullWidth, text_position }) => !fullWidth || text_position === 'left' ? getRelative(130, 'desktop') : '0'};
      `}
    }
  }

  > div:last-of-type {
    margin: 0 ${({ fullWidth }) => fullWidth ? '0' : '-' + getRelative(23, 'mobile')};
    height: inherit;

    figure, img {
      width: 100%;
      height: 100%;
    }

    figure {
      height: ${({ fullWidth }) => fullWidth ? '100%' : '50vh'};
      position: relative;

      ${mediaQuery.greaterThan<any>('tablet')`
        height: 100%;
      `}
    }

    img { object-fit: cover; }

    ${({ fullWidth }) => fullWidth ? `
      position: absolute;
      z-index: -1;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;`: `
      grid-area: 2 / 1 / span 1 / span 6;
    `}

    ${mediaQuery.greaterThan<any>('tablet')`
      margin: 0;
      padding: 0;

      ${({ fullWidth }) => !fullWidth && `
        grid-area: 1 / 7 / span 1 / span 6;
      `}
    `}
  }

  > button {
    grid-area: 1 / 1 / span 1 / span 6;
    align-self: end;
    justify-self: center;
    margin-bottom: ${getRelative(40, 'mobile')};

    ${({ fullWidth }) => !fullWidth && `
        grid-area: 2 / 1 / span 1 / span 6;
      `}

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 1 / 1 / span 1 / span 12;
      margin-bottom: ${getRelative(60, 'desktop')};
    `}

    img {
      width: ${getRelative(20, 'mobile')};
      height: ${getRelative(10, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        width: ${getRelative(28, 'desktop')};
        height: ${getRelative(14, 'desktop')};
      `}
    }
  }

  button.link {
    display: block;
    margin: 0 auto;
    ${getInvertedButtonUnderline({color: 'white'})}
  }

  button.contactus {
    ${getP24()}
    ${createUnderline({ height: 1, reverse: true })}
    margin-top: ${getRelative(100, 'mobile')};
    margin-bottom: ${getRelative(150, 'mobile')};
    width: auto;
    width: fit-content;

    ${mediaQuery.greaterThan<any>('tablet')`
      margin-top: ${getRelative(100, 'desktop')};
      margin-bottom: ${getRelative(150, 'desktop')};
    `}

    ${mediaQuery.greaterThan<any>('tablet')`
      margin-left: ${({ fullWidth, text_position }) => !fullWidth || text_position === 'left' ? getRelative(40, 'desktop') : '0'};
      margin-right: ${({ fullWidth }) => !fullWidth ? 'calc(100vw / 12)' : '0'};
      text-align: ${({ fullWidth, text_position }) => fullWidth && text_position === 'center' ? text_position : 'left'};
    `}
  }
`

export const Header = memo<IHeader>(({ data, homepage = false }) => {
  const { query } = useRouter()
  const { image, title, subtitle, image_size, text_color, font_size, text_position } = data
  const fullWidth = useMemo(() => image_size === 'full', [image_size])
  const { locales: { home } } = useI18n()
  const [,setNavColor] = useRecoilState(navbarColorState);

  const onClick = () => {
    window.scrollTo({top: window.innerHeight, behavior: 'smooth'});
  }

  useEffect(() => {
    setNavColor(text_color)

    return () => {
      setNavColor('')
    }
  }, [text_color])

  return (
    <HeaderElem key={title} id='header' fullWidth={fullWidth} text_color={text_color} fontLarge={font_size} text_position={text_position} homepage={homepage} onClick={onClick}>
      <motion.div variants={staggerWrapperVariants} {...basicVariantsProps} >
        <motion.h1 variants={staggerChildVariants}>{title}</motion.h1>
        {subtitle && <motion.p variants={staggerChildVariants}>{subtitle}</motion.p>}
        {(homepage && image_size === 'full') &&
          <motion.button variants={staggerChildVariants} className='link' onClick={() => onClick()}>
            {home.header_link}
            <span/>
          </motion.button>
        }
        {query?.slug?.[0] === 'service' && <button className='contactus'>Contact us<span></span></button>}
      </motion.div>
      <MediaWrapper>
        <motion.div variants={imgVariants} initial='initial' animate='animate'>
          <figure>
            {image?.default &&
              <Image
                src={image?.default?.['2x']?.regular}
                alt={image?.alt ?? 'Homepage header image'}
                priority={true}
                layout='fill'
                sizes='100vw'
                quality={98}
              />
            }
          </figure>
        </motion.div>
      </MediaWrapper>
      <button onClick={() => onClick()} aria-label='button scroll down'>
          <figure><img src={!fullWidth || text_color === 'light' ? '/images/svg/arrow_white.svg' : '/images/svg/arrow_black.svg'} alt='arrow scroll down'/></figure>
      </button>
    </HeaderElem>
  )
})
