"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterFilters = exports.slugMatch = exports.applyFilters = exports.setActiveFiltersFromQuery = exports.generateQueryParams = exports.getFilters = exports.getFilteredProducts = exports.hasQueryParams = void 0;
var hasQueryParams = function (asPath, param) {
    if (param === void 0) { param = null; }
    return !!asPath.split("?".concat(param ? "".concat(param, "=") : ''))[1];
};
exports.hasQueryParams = hasQueryParams;
var getFilteredProducts = function (products, activeFilters) {
    var e_1, _a;
    if (products === void 0) { products = []; }
    if ((products === null || products === void 0 ? void 0 : products.length) < 1)
        return [];
    if (Object.keys(activeFilters).length < 1)
        return products;
    var filteredProducts = [];
    var _loop_1 = function (product) {
        var filters = product.filters;
        var matchAllFilters = true;
        Object.keys(activeFilters).map(function (param) {
            if (!filters[param]) {
                matchAllFilters = false;
            }
            else {
                var atLeastOne_1 = false;
                Object.keys(activeFilters[param]).map(function (value) {
                    var _a, _b;
                    if ((_b = (_a = filters[param]) === null || _a === void 0 ? void 0 : _a.values) === null || _b === void 0 ? void 0 : _b[value])
                        atLeastOne_1 = true;
                });
                if (!atLeastOne_1)
                    matchAllFilters = false;
            }
        });
        if (matchAllFilters)
            filteredProducts.push(product);
    };
    try {
        for (var products_1 = __values(products), products_1_1 = products_1.next(); !products_1_1.done; products_1_1 = products_1.next()) {
            var product = products_1_1.value;
            _loop_1(product);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (products_1_1 && !products_1_1.done && (_a = products_1.return)) _a.call(products_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return filteredProducts;
};
exports.getFilteredProducts = getFilteredProducts;
var TRANSLATIONS_SORT_BY = {
    'en': {
        name: 'Sort',
        values: {
            'recommended': 'Recommended',
            'low-price': 'Lowest price',
            'high-price': 'Highest price'
        }
    },
    'es': {
        name: 'Ordenar',
        values: {
            'recommended': 'Recomendados',
            'low-price': 'Precio más bajo',
            'high-price': 'Precio más alto'
        }
    },
    'fr': {
        name: 'Trier',
        values: {
            'recommended': 'Conseillé',
            'low-price': 'Prix le plus bas',
            'high-price': 'Prix le plus élevé'
        }
    },
    'it': {
        name: 'Ordina',
        values: {
            'recommended': 'Consigliato',
            'low-price': 'Prezzo più basso',
            'high-price': 'Prezzo più alto'
        }
    }
};
var getCategories = function (categories, locale) {
    if (categories === void 0) { categories = []; }
    return categories.map(function (cat, idx) {
        var id = cat.id, name = cat.name, slug = cat.slug;
        return { id: id, name: name, slug: slug[locale] };
    });
};
var getFilters = function (filters, categories) {
    if (filters === void 0) { filters = []; }
    if (categories === void 0) { categories = []; }
    if ((filters === null || filters === void 0 ? void 0 : filters.length) === 0)
        return {};
    var finalFilters = {
        categories: { name: 'Categories', values: categories },
        specification: { name: 'Specification', values: {} }
    };
    filters.map(function (filter) {
        //@ts-ignore
        var type = filter.type, slug = filter.slug, name = filter.name, values = filter.values;
        if (type === 'regular') {
            finalFilters[slug] = { name: name, values: values };
        }
        else if (type === 'specification') {
            if (finalFilters['specification'].values[slug]) {
                finalFilters['specification'].values[slug] = {
                    name: name,
                    values: __assign(__assign({}, finalFilters['specification'].values[slug]), values)
                };
            }
            else {
                finalFilters['specification'].values[slug] = { name: name, values: values };
            }
        }
    });
    return finalFilters;
};
exports.getFilters = getFilters;
var getUrl = function (_a) {
    var e_2, _b;
    var slugs = _a.slugs, filters_query = _a.filters_query, _c = _a.page, page = _c === void 0 ? '' : _c, origin = _a.origin;
    var url = '/' + origin;
    if (typeof slugs === 'string') {
        url += "/".concat(slugs);
    }
    else if (typeof slugs === 'object') {
        try {
            for (var slugs_1 = __values(slugs), slugs_1_1 = slugs_1.next(); !slugs_1_1.done; slugs_1_1 = slugs_1.next()) {
                var _slug = slugs_1_1.value;
                url += "/".concat(_slug);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (slugs_1_1 && !slugs_1_1.done && (_b = slugs_1.return)) _b.call(slugs_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    }
    if (page)
        filters_query += "&page=".concat(page);
    if (filters_query)
        filters_query = '?' + filters_query;
    return url + filters_query;
};
var generateQueryParams = function (_a) {
    var slugs = _a.slugs, activeByType = _a.activeByType, origin = _a.origin;
    var filters_query = Object.keys(activeByType).reduce(function (acc, name) { return __spreadArray(__spreadArray([], __read(acc), false), ["".concat(name, "=").concat(Object.keys(activeByType[name].values).join(','))], false); }, []).join('&');
    return getUrl({ slugs: slugs, origin: origin, filters_query: filters_query });
};
exports.generateQueryParams = generateQueryParams;
var setActiveFiltersFromQuery = function (query, filters) {
    var tempQuery = __assign({}, query);
    delete tempQuery.slug;
    var tempFilters = JSON.parse(JSON.stringify(filters));
    var last_param = '';
    for (var param in tempQuery) {
        if (filters[param]) {
            var values = tempQuery[param].split(',');
            for (var idx = 0; idx < values.length; idx++) {
                var value = values[idx];
                if (tempFilters[param].values[value]) {
                    tempFilters[param].values[value] = __assign(__assign({}, tempFilters[param].values[value]), { active: true });
                }
            }
        }
        last_param = param;
    }
    return [tempFilters, last_param];
};
exports.setActiveFiltersFromQuery = setActiveFiltersFromQuery;
var applyFilters = function (filters, products) {
    // Getting activeFilters (maybe this could go to recoil index)
    var activeFilters = Object.keys(filters).reduce(function (fAcc, fKey) {
        var activeFilterValues = Object.keys(filters[fKey].values).reduce(function (vAcc, vKey) {
            if (filters[fKey].values[vKey].active) {
                vAcc[vKey] = filters[fKey].values[vKey];
            }
            return vAcc;
        }, {});
        if (Object.keys(activeFilterValues).length > 0) {
            fAcc[fKey] = __assign(__assign({}, filters[fKey]), { values: activeFilterValues });
        }
        return fAcc;
    }, {});
    // Return all products if no active filters
    if (Object.keys(activeFilters).length == 0) {
        return products;
    }
    // return products.filter((product) => {
    //   const key = Object.keys(product.filters).find(fKey => Object.keys(activeFilters).includes(fKey))
    //   if (key) {
    //     const value = Object.keys(product.filters[key].values).find(value => Object.keys(activeFilters[key].values).includes(value))
    //     if (value) {
    //       return true
    //     }
    //   }
    // })
    // filter products using activeFilters
    return products.filter(function (product) {
        var hasAllFilterKeys = Object.keys(activeFilters).every(function (el) { return Object.keys(product.filters).includes(el); });
        return hasAllFilterKeys && Object.keys(product.filters).map(function (fKey) {
            if (Object.keys(activeFilters).includes(fKey)) {
                return Object.keys(product.filters[fKey].values).map(function (value) {
                    return Object.keys(activeFilters[fKey].values).includes(value);
                }).some(function (el) { return el === true; }); // some ocurrence in specific filter values will match
            }
            return true; // Return true if product filter is not in selected filters
        }).every(function (el) { return el === true; }); // all ocurrence from different filters should match
    });
};
exports.applyFilters = applyFilters;
var slugMatch = function (arr1, arr2) {
    var arr = arr1;
    if (typeof arr1 === 'string')
        arr = [arr1];
    if (arr.length !== arr2.length)
        return false;
    for (var i = 0; i < arr.length; i++) {
        if (arr[i] !== arr2[i])
            return false;
    }
    return true;
};
exports.slugMatch = slugMatch;
var resetFiltersShow = function (filters) {
    var _filters = JSON.parse(JSON.stringify(filters));
    var _loop_2 = function (key) {
        Object.keys(_filters[key].values).map(function (value) {
            _filters[key].values[value] = __assign(__assign({}, _filters[key].values[value]), { show: false });
        });
    };
    for (var key in _filters) {
        _loop_2(key);
    }
    return _filters;
};
var no_filter = ['driver', 'lifetime', 'voltage'];
var filterFilters = function (_a) {
    var products = _a.products, filtersState = _a.filtersState;
    var restFilters = {};
    var filters = filtersState.filters, lastFilterApplied = filtersState.lastFilterApplied;
    for (var idx = 0; idx < products.length; idx++) {
        var _b = products[idx], filters_1 = _b.filters, name_1 = _b.name;
        for (var key in filters_1) {
            if (no_filter.includes(key))
                continue;
            if (!restFilters[key]) {
                restFilters[key] = __assign({}, filters_1[key].values);
            }
            else {
                restFilters[key] = __assign(__assign({}, restFilters[key]), filters_1[key].values);
            }
        }
    }
    var tempFilters = resetFiltersShow(filters);
    var _loop_3 = function (key) {
        Object.keys(restFilters[key]).map(function (value) {
            if (lastFilterApplied === key) {
                tempFilters[key].values = Object.keys(tempFilters[key].values).reduce(function (acc, value) {
                    var _a;
                    return (__assign(__assign({}, acc), (_a = {}, _a[value] = __assign(__assign({}, tempFilters[key].values[value]), { show: true }), _a)));
                }, {});
            }
            else {
                if (!tempFilters[key]) {
                    console.info('error filter no values -> ', key);
                }
                else {
                    tempFilters[key].values[value] = __assign(__assign({}, tempFilters[key].values[value]), { show: true });
                }
            }
        });
    };
    for (var key in restFilters) {
        _loop_3(key);
    }
    return tempFilters;
};
exports.filterFilters = filterFilters;
