"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.search = void 0;
var search = function (region, lang) {
    if (region === void 0) { region = 'es'; }
    if (lang === void 0) { lang = 'es'; }
    return JSON.stringify({
        "@context": "https://schema.org",
        "@type": "WebSite",
        "url": "https://www.nexia.es",
        "potentialAction": {
            "@type": "SearchAction",
            "target": "https://nexia.es",
            "query-input": "required name=search_term_string"
        }
    });
};
exports.search = search;
