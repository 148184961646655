import { memo, useEffect } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import Image from 'next/image'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import { mediaQuery, getRelative, getP24, getP18 } from '@/styles'
import { useRouter } from 'next/router'
import { imgVariants, titleVariants, basicVariantsProps } from '../commons'

type IProductCard = {
  data: any,
  isSmall?: boolean,
  isLarge?: boolean,
  isProject?: boolean,
  categories?: any
}

const TextWrapper = styled(motion.div)<any>`
  h3 {
    ${({isLarge}) => isLarge ? getP18() : getP24()}
  }

  p {
    ${getP18()}
    opacity: .4;
    text-align: center;
  }
`

const MediaWrapper = styled.div<any>`
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.img_bg};
  margin-bottom: ${({ isSmall }) => `${getRelative(isSmall ? 5 : 4, 'mobile')}`};

  ${mediaQuery.greaterThan<any>('tablet')`
    margin-bottom: ${({ isSmall }) => `${getRelative(isSmall ? 5 : 8, 'desktop')}`};
  `}

  figure {
    height: ${({ isLarge, hasCategories }) => `${getRelative(isLarge && hasCategories ? 214 : 195, 'mobile')}`};
    overflow: hidden;
    position: relative;
    width: ${({ isLarge, hasCategories }) => `${getRelative(isLarge && hasCategories ? 367 : 172, 'mobile')}`};

    ${mediaQuery.greaterThan<any>('tablet')`
      height: ${({ isSmall, isLarge }) => `${getRelative(isSmall ? 396 : isLarge ? 396 : 540, 'desktop')}`};
      width: ${({ isSmall, isLarge }) => `${getRelative(isSmall ? 320 : isLarge ? 667 : 436, 'desktop')}`};
    `}

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 1000ms transform ${({ theme: { ease } }) => ease};
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
`

const Article = styled.article`
  .product-card-image.no-img {
    background-color: ${({ theme }) => theme.colors.img_bg};

    width: ${getRelative(172, 'mobile')};
    height: ${getRelative(195, 'mobile')};
    overflow: hidden;

    ${mediaQuery.greaterThan<any>('tablet')`
      width: ${getRelative(320, 'desktop')};
      height: ${getRelative(396, 'desktop')};
    `}

    .no-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 1000ms transform ${({ theme: { ease } }) => ease};
    }
  }
`

export const ProductCard = memo<IProductCard>(({ data, isSmall = false, isLarge = false, isProject = false, categories = null }) => {
  const sizes = `(min-width: 768px) ${getRelative(isSmall ? 320 : isLarge ? 667 :  436, 'desktop')}, ${getRelative(isLarge && !!categories ? 367 : 172, 'mobile')}`
  // const sizes = `(min-width: 768px) 40vw, 80vw`
  const { slug, name, subtitle, media, model_reference, product_type, usa } = data

  const { locale } = useRouter()
  const controls = useAnimation()

  const [ref, inView] = useInView({
    rootMargin: '0px 0px 0px 0px'
  })

  useEffect(() => {
    if(inView) {
      controls.start('animate')
      controls.start('animateText')
    }
  }, [inView])

  return (
    <Article ref={ref}>
      {slug ? <Link scroll={false} href={isProject ? `/project/${slug[locale]}` : `${usa ? '/usa' : ''}/product/${slug[locale]}`}>
        <a>
          {media && Object.keys(media).length ?
            <MediaWrapper isSmall={isSmall} isLarge={isLarge} hasCategories={categories}>
              <motion.div variants={imgVariants} animate={controls} initial={'initial'}>
                <figure className='product-card-image'>
                  <Image
                    src={media?.default?.['2x']?.regular}
                    alt={media.alt}
                    layout='fill'
                    // {...layout === 'responsive' && { height: media[(mediaType === 'mobile' && media?.mobile) ? 'mobile' : 'default'].size.height ?? 500 }}
                    // {...layout === 'responsive' && { width: media[(mediaType === 'mobile' && media?.mobile) ? 'mobile' : 'default'].size.width }}
                    sizes={sizes}
                    quality={98}
                  />
                </figure>
              </motion.div>
            </MediaWrapper>
          :
            <div className='product-card-image no-img'>
              <div className='no-img'></div>
            </div>
          }
          <TextWrapper variants={titleVariants} {...basicVariantsProps} animate={controls} initial={'initial'} isLarge={product_type === 'accessory' || isLarge}>
            <h3>{name}</h3>
            <p>{subtitle}</p>
            {model_reference && <p className='ref'>{model_reference}</p>}
            {categories && <p>{categories.join(', ')}</p>}
          </TextWrapper>
        </a>
      </Link> : <div>
      {media && Object.keys(media).length ?
            <MediaWrapper isSmall={isSmall} isLarge={isLarge} hasCategories={categories}>
              <motion.div variants={imgVariants} animate={controls} initial={'initial'}>
                <figure className='product-card-image'>
                  <Image
                    src={media?.default?.['2x']?.regular}
                    alt={media.alt}
                    layout='fill'
                    // {...layout === 'responsive' && { height: media[(mediaType === 'mobile' && media?.mobile) ? 'mobile' : 'default'].size.height ?? 500 }}
                    // {...layout === 'responsive' && { width: media[(mediaType === 'mobile' && media?.mobile) ? 'mobile' : 'default'].size.width }}
                    sizes={sizes}
                  />
                </figure>
              </motion.div>
            </MediaWrapper>
          :
            <div className='product-card-image no-img'>
              <div className='no-img'></div>
            </div>
          }
          <TextWrapper variants={titleVariants} {...basicVariantsProps} animate={controls} initial={'initial'} isLarge={product_type === 'accessory' || isLarge}>
            <h3>{name}</h3>
            <p>{subtitle}</p>
            {model_reference && <p className='ref'>{model_reference}</p>}
            {categories && <p>{categories.join(', ')}</p>}
          </TextWrapper>
        </div>}
    </Article>
  )
})
