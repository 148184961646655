import { useMemo } from "react"

const OneColorchart = ({ colors: [first] }) => (
<svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>1 color</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="PDP" transform="translate(-39.000000, -1261.000000)">
            <g id="Overview" transform="translate(40.000000, 840.000000)">
                <g id="1-color" transform="translate(0.000000, 422.000000)">
                    <path d="M11.9985337,0 C5.37265285,0 0,5.37265285 0,12.0014663 C0,18.6273472 5.37265285,24 11.9985337,24 C18.6234369,24 23.9960898,18.6273472 23.9960898,12.0014663 C23.9960898,5.37265285 18.6234369,0 11.9985337,0" id="Fill-24" fill={first}></path>
                    <path d="M11.9975561,0 C5.37265285,0 0,5.37265285 0,12.0014663 C0,18.6273472 5.37265285,24 11.9975561,24 C18.6224594,24 23.9951122,18.6273472 23.9951122,12.0014663 C23.9951122,5.37265285 18.6224594,0 11.9975561,0" id="Border" strokeOpacity="0.1" stroke="#000000" strokeWidth="0.5"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
)

const TwoColorChart = ({ colors: [first, second] }) => (
<svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>2 colores</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="PDP" transform="translate(-69.000000, -1261.000000)">
            <g id="Overview" transform="translate(40.000000, 840.000000)">
                <g id="2-colores" transform="translate(30.000000, 422.000000)">
                    <path d="M11.9977516,0 C5.37274038,0 0,5.37274038 0,12.0016619 C0,15.3146563 1.34220752,18.3138498 3.51241925,20.4850391 L20.4821063,3.51632954 C18.3099395,1.34416267 15.310746,0 11.9977516,0" id="Color-1" fill={first}></path>
                    <path d="M3.51281028,20.484648 C5.68497715,22.6568149 8.68417065,24 11.9981426,24 C18.6231538,24 23.9958942,18.6272596 23.9958942,12.0022484 C23.9958942,8.68827646 22.6527091,5.68810538 20.4815198,3.51593851 L3.51281028,20.484648 Z" id="Color-2" fill={second}></path>
                    <path d="M11.9975561,0 C5.37265285,0 0,5.37265285 0,12.0014663 C0,18.6273472 5.37265285,24 11.9975561,24 C18.6224594,24 23.9951122,18.6273472 23.9951122,12.0014663 C23.9951122,5.37265285 18.6224594,0 11.9975561,0" id="Border" strokeOpacity="0.1" stroke="#000000" strokeWidth="0.5"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
)

const ThreeColorChart = ({ colors: [first, second, third] }) => (
<svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>3 colores</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="PDP" transform="translate(-99.000000, -1261.000000)">
            <g id="Overview" transform="translate(40.000000, 840.000000)">
                <g id="3-colores" transform="translate(60.000000, 422.000000)">
                    <path d="M12.0017082,24 C16.4437801,24 20.3137226,21.5794522 22.3890366,17.9921516 L12.0017082,11.9950611 L1.61340185,17.9921516 C3.68969393,21.5794522 7.55963635,24 12.0017082,24" id="Color-3" fill={third}></path>
                    <path d="M22.3977408,5.99220046 C20.1767049,2.14475202 16.1453926,0.00391199638 12.0016104,0 L12.0016104,11.9951589 L22.3889388,17.9922494 C24.4583849,14.4010367 24.6187768,9.8396489 22.3977408,5.99220046" id="Color-2" fill={second}></path>
                    <path d="M1.60479546,5.99229826 C-0.616240486,9.8397467 -0.454870635,14.4011345 1.61359745,17.9923472 L12.0019038,11.9952567 L12.0019038,9.77999096e-05 C7.85714368,0.00400979629 3.82583141,2.14582782 1.60479546,5.99229826" id="Color-1" fill={first}></path>
                    <path d="M11.9975561,0 C5.37265285,0 0,5.37265285 0,12.0014663 C0,18.6273472 5.37265285,24 11.9975561,24 C18.6224594,24 23.9951122,18.6273472 23.9951122,12.0014663 C23.9951122,5.37265285 18.6224594,0 11.9975561,0" id="Border" strokeOpacity="0.1" stroke="#000000" strokeWidth="0.5"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
)

const FourColorchart = ({ colors: [first, second, third, fourth] }) => (
<svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>4 colores</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="PDP" transform="translate(-129.000000, -1261.000000)">
            <g id="Overview" transform="translate(40.000000, 840.000000)">
                <g id="4-colores" transform="translate(90.000000, 422.000000)">
                    <path d="M12.0004889,11.99956 L3.5148898,3.5139264 C1.34338208,5.68642069 0,8.68704399 0,12.0015155 C0,12.3153637 0.0234651892,12.6233455 0.0469303785,12.9313274 C0.0547521082,13.0310548 0.0527966758,13.1337155 0.0625738379,13.2324652 C0.0821281623,13.4231206 0.120259095,13.6059543 0.148612865,13.7926989 C0.1808775,14.0126859 0.206298122,14.2346284 0.251273068,14.4487491 C0.278649122,14.5817191 0.320690919,14.707845 0.351977838,14.8378818 C0.415529393,15.1038217 0.479080947,15.3697616 0.561209109,15.6278797 C0.588585163,15.7148968 0.624760663,15.7970253 0.655069866,15.8820869 C0.755774636,16.1793139 0.862345704,16.4755631 0.986515664,16.7620351 C1.00802542,16.8099434 1.03246833,16.8549185 1.0549558,16.9038046 C1.19770237,17.2225413 1.35120381,17.5363895 1.52132644,17.8404605 C1.53208131,17.8590372 1.54479162,17.8776139 1.55456879,17.8961906 C1.74131258,18.2256823 1.94174441,18.5444191 2.15781969,18.8533786 C2.15781969,18.8553341 2.15977513,18.8563118 2.16075284,18.8572895 C2.56552736,19.4380553 3.01527682,19.9855786 3.51391209,20.4851936 L12.0004889,11.99956 Z" id="Color-4" fill={fourth}></path>
                    <path d="M12.0004889,11.99956 L3.51391209,20.4851936 C3.57159734,20.5428791 3.6351489,20.5927429 3.69478959,20.6484729 C5.85065385,22.720262 8.77304762,24.0000978 11.9985334,24.0000978 C12.0503524,24.0000978 12.1011936,23.9932537 12.1520349,23.992276 C12.5538762,23.9873874 12.9518067,23.9639221 13.3428932,23.9199247 C13.5012833,23.9023258 13.6547847,23.8729941 13.812197,23.8485511 C14.101601,23.8045537 14.3890496,23.75469 14.6716096,23.6911382 C14.8299996,23.6549625 14.983501,23.6129206 15.1379802,23.5708786 C15.4254288,23.492661 15.707011,23.4056439 15.9846824,23.3078719 C16.1147187,23.261919 16.2437772,23.2159662 16.3718581,23.1661024 C16.7003707,23.0380211 17.0200839,22.8942962 17.3329531,22.737861 C17.3994378,22.7046185 17.4688557,22.6752869 17.5343627,22.6410667 C18.625494,22.0720335 19.6227645,21.3475429 20.4860879,20.4851936 L12.0004889,11.99956 Z" id="Color-3" fill={third}></path>
                    <path d="M23.9594248,11.1922565 C23.9496476,11.050487 23.951603,10.9057844 23.9369373,10.7649927 C23.9164053,10.5655378 23.8772966,10.3729269 23.8469874,10.1764052 C23.8147228,9.96423988 23.7893022,9.75011916 23.7462826,9.54186476 C23.7149957,9.39227357 23.6690431,9.24757098 23.6328676,9.09993523 C23.5702937,8.84963885 23.510653,8.59836476 23.4324357,8.35393471 C23.3982157,8.24736321 23.3551962,8.14665803 23.3180429,8.04204197 C23.2192936,7.76045855 23.1185888,7.48180829 23.0002852,7.21000208 C22.969976,7.14253938 22.9347782,7.07898757 22.904469,7.0125026 C22.7656333,6.70843161 22.6179981,6.40924923 22.4547195,6.12082177 C22.4351652,6.08464613 22.4126777,6.05140364 22.391168,6.01620571 C22.2093127,5.70040209 22.0147472,5.39242022 21.8055159,5.09519328 C21.7957388,5.08248292 21.7859616,5.07075028 21.7771622,5.05803992 C21.5522874,4.74223629 21.3137247,4.43914303 21.0604962,4.14778241 C21.0595185,4.14484925 21.057563,4.14387153 21.0556076,4.14191609 C20.8708192,3.92779536 20.6850532,3.71465236 20.4855991,3.51421972 L20.4699556,3.53084096 L12.0009777,11.9998533 L20.4855991,20.485487 C20.9069947,20.0640896 21.2873264,19.6026056 21.6422373,19.122545 C23.1176111,17.1289735 24.0004889,14.6719627 24.0004889,12.0018088 C24.0004889,11.7280471 23.9780014,11.4611295 23.9594248,11.1922565" id="Color-2" fill={second}></path>
                    <path d="M12.0004889,11.99956 L20.4694667,3.53054765 C20.3883163,3.45037459 20.3159653,3.36237977 20.2328594,3.28416215 C18.0828614,1.2534373 15.1907769,0 11.9985334,0 C9.09960484,0 6.44119444,1.02758393 4.36648063,2.73859428 C4.07121033,2.98400205 3.78669491,3.24212019 3.5148898,3.5139264 L12.0004889,11.99956 Z" id="Color-1" fill={first}></path>
                    <path d="M11.9975561,0 C5.37265285,0 0,5.37265285 0,12.0014663 C0,18.6273472 5.37265285,24 11.9975561,24 C18.6224594,24 23.9951122,18.6273472 23.9951122,12.0014663 C23.9951122,5.37265285 18.6224594,0 11.9975561,0" id="Border" strokeOpacity="0.1" stroke="#000000" strokeWidth="0.5"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
)

const Charts = {
  '0': OneColorchart,
  '1': OneColorchart,
  '2': TwoColorChart,
  '3': ThreeColorChart,
  '4': FourColorchart,
  '5': FourColorchart,
  '6': FourColorchart
}

export const PieColors = ({ colors }) => {
  const Chart = useMemo(() => {
    if (!Charts[colors.length]) {
      console.info('too many colors -> ', colors)
      return <></>
    }
    return Charts[colors.length]
  }, [colors])

  return <Chart colors={colors}  />
}
