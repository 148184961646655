import { atom, selector } from 'recoil'

export const navbarColorState = atom({
  key: 'navbar-color',
  default: '',
});

export const gridFiltersState = atom({
  key: 'grid-filters',
  default: {
    filters: {},
    lastFilterApplied: ''
  },
});

export const activeFiltersSelector = selector({
  key: 'activeFilters', // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const { filters } = get(gridFiltersState);
    const activeFilters = []

    for (let key in filters) {
      Object.keys(filters[key].values).map((value) => {
        const { active, name } = filters[key].values[value]
        if (active) {
          activeFilters.push({ name, key: value, parent_key: key })
        }
      })
    }

    return activeFilters;
  },
});

export const activeFiltersIdSelector = selector({
  key: 'activeFiltersIds', // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const { filters } = get(gridFiltersState);
    const activeIds = []

    for (let key in filters) {
      if (Object.keys(filters[key].values).some((value) => {
        const { active } = filters[key].values[value]
        return active
      })) {
        activeIds.push(filters[key].id)
      }
    }

    return activeIds;
  },
});

export const byTypeSelector = selector({
  key: 'byTypeSelector', // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const { filters } = get(gridFiltersState);
    const filtersByType = {}

    for (let key in filters) {
      const { type, name } = filters[key]

      if (type === 'specification') {
        if (!filtersByType[type]) {
          filtersByType[type] = { [key]: { ...filters[key] } }
        } else {
          filtersByType[type] = { ...filtersByType[type], [key]: { ...filters[key] } }
        }
      } else {
        if (!filtersByType[name]) {
          filtersByType[name] = { [key]: { ...filters[key] } }
        } else {
          filtersByType[name] = { ...filtersByType[name], [key]: { ...filters[key] } }
        }
      }
    }

    return filtersByType;
  },
});

export const byTypeActive = selector({
  key: 'byTypeActive', // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const { filters } = get(gridFiltersState);
    const filtersByType = {}

    for (let key in filters) {
      Object.keys(filters[key].values).map((value) => {
        const { active } = filters[key].values[value]
        if (active) {
          if (!filtersByType[key]) {
            filtersByType[key] = { ...filters[key], values: { [value] : { ...filters[key].values[value] } } }
          } else {
            filtersByType[key] = { ...filtersByType[key], values: { ...filtersByType[key].values, [value]: { ...filters[key].values[value] } } }
          }
        }
      })
    }

    return filtersByType;
  },
});

export const resetFilters = (filters) => {
  let _filters = JSON.parse(JSON.stringify(filters))

  for (let key in _filters) {
    Object.keys(_filters[key].values).map((value) => {
      _filters[key].values[value] = { name: _filters[key].values[value].name, show: false, active: false }
    })
  }

  return _filters
}