import styled from 'styled-components'
// import { useModal, modalContactId } from '@/modals'
import Link from 'next/link'
import { getRelative, mediaQuery, getP50, getP24_3, getInvertedButtonUnderline } from '@/styles'
import { useRouter } from 'next/router'

const TRANSLATIONS = {
  'en': {
    title: 'Do you think <br/> it fits your project?',
    contact_us: 'Contact us'
  },
  'es': {
    title: '¿Crees que <br /> encaja en tu proyecto?',
    contact_us: 'Contáctanos'
  },
  'fr': {
    title: 'Pensez-vous que <br />cela corresponde à votre projet ?',
    contact_us: 'Contactez-nous'
  },
  'it': {
    title: 'Pensi che sia <br /> adatto al tuo progetto?',
    contact_us: 'Contattaci'
  }
}

const Section = styled.section`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 12px;
  margin: ${getRelative(130, 'mobile')} 0;

  ${mediaQuery.greaterThan('tablet')`
    margin: ${getRelative(150, 'desktop')} 0;
  `}

  h2 {
    ${getP50()}
    width: 100%;
    margin-bottom: ${getRelative(20, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      margin-bottom: ${getRelative(40, 'desktop')};
    `}
  }

  h2,
  button {
    text-align: center;
  }

  a,
  button {
    ${getInvertedButtonUnderline()}
  }
`

export const SectionFitsIntoYourProject = () => {
  const { locale } = useRouter()
  // const { open: openModalContact } = useModal(modalContactId)

  return (<Section>
    <h2 dangerouslySetInnerHTML={{ __html: TRANSLATIONS[locale].title }} />
    <Link href='/contact' passHref>
      <a>
        {TRANSLATIONS[locale].contact_us}<span />
      </a>
    </Link>
    {/* <button onClick={openModalContact}>{TRANSLATIONS[locale].contact_us}<span /></button> */}
  </Section>)
}
