"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScrollTop = void 0;
var react_1 = require("react");
var useScrollTop = function (pathname) {
    if (pathname === void 0) { pathname = ''; }
    (0, react_1.useEffect)(function () {
        window.scroll({ top: 0 });
    }, [pathname]);
};
exports.useScrollTop = useScrollTop;
