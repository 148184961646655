import LOCALES from './locales_urls'

interface ICallAPI {
  locale: string
}

const urlFor = ({ locale }): [string] => {
  if (!LOCALES[locale]) throw new Error(`utils/locales -> No URL of locale ${locale}`)

  const url= LOCALES[locale]

  return [url]
}

export const callLocales = async ({ locale = 'es' }: ICallAPI): Promise<[any, any, any?]> => {
  try {
    const [locales] = urlFor({ locale })

    return [locales, false]

  } catch (error) {
    return [{status: error.status, message: error.message, error: 'server_error'}, true]
  }
}
