"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMargin130 = exports.getMargin90 = exports.getMargin60 = exports.getPadding50 = exports.getMargin50 = exports.getMargin40_2 = exports.getMargin40 = exports.getMargin30 = exports.getMargin20 = void 0;
var styled_components_1 = require("styled-components");
var _1 = require(".");
var getMargin20 = function (top) {
    if (top === void 0) { top = false; }
    if (top) {
        return (0, styled_components_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      margin-top: ", ";\n\n      ", "\n    "], ["\n      margin-top: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(15, 'mobile'), _1.mediaQuery.greaterThan('tablet')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        margin-top: ", ";\n      "], ["\n        margin-top: ", ";\n      "])), (0, _1.getRelative)(20, 'desktop')));
    }
    else {
        return (0, styled_components_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      margin-bottom: ", ";\n\n      ", "\n    "], ["\n      margin-bottom: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(15, 'mobile'), _1.mediaQuery.greaterThan('tablet')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        margin-bottom: ", ";\n      "], ["\n        margin-bottom: ", ";\n      "])), (0, _1.getRelative)(20, 'desktop')));
    }
};
exports.getMargin20 = getMargin20;
var getMargin30 = function (top) {
    if (top === void 0) { top = false; }
    if (top) {
        return (0, styled_components_1.css)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      margin-top: ", ";\n\n      ", "\n    "], ["\n      margin-top: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(20, 'mobile'), _1.mediaQuery.greaterThan('tablet')(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n        margin-top: ", ";\n      "], ["\n        margin-top: ", ";\n      "])), (0, _1.getRelative)(30, 'desktop')));
    }
    else {
        return (0, styled_components_1.css)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      margin-bottom: ", ";\n\n      ", "\n    "], ["\n      margin-bottom: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(20, 'mobile'), _1.mediaQuery.greaterThan('tablet')(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n        margin-bottom: ", ";\n      "], ["\n        margin-bottom: ", ";\n      "])), (0, _1.getRelative)(30, 'desktop')));
    }
};
exports.getMargin30 = getMargin30;
var getMargin40 = function (top) {
    if (top === void 0) { top = false; }
    if (top) {
        return (0, styled_components_1.css)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n      margin-top: ", ";\n\n      ", "\n    "], ["\n      margin-top: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(30, 'mobile'), _1.mediaQuery.greaterThan('tablet')(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n        margin-top: ", ";\n      "], ["\n        margin-top: ", ";\n      "])), (0, _1.getRelative)(40, 'desktop')));
    }
    else {
        return (0, styled_components_1.css)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n      margin-bottom: ", ";\n\n      ", "\n    "], ["\n      margin-bottom: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(30, 'mobile'), _1.mediaQuery.greaterThan('tablet')(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n        margin-bottom: ", ";\n      "], ["\n        margin-bottom: ", ";\n      "])), (0, _1.getRelative)(40, 'desktop')));
    }
};
exports.getMargin40 = getMargin40;
var getMargin40_2 = function () {
    return (0, styled_components_1.css)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    margin-bottom: ", ";\n\n    ", "\n  "], ["\n    margin-bottom: ", ";\n\n    ", "\n  "])), (0, _1.getRelative)(20, 'mobile'), _1.mediaQuery.greaterThan('tablet')(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n      margin-bottom: ", ";\n    "], ["\n      margin-bottom: ", ";\n    "])), (0, _1.getRelative)(40, 'desktop')));
};
exports.getMargin40_2 = getMargin40_2;
var getMargin50 = function () {
    return (0, styled_components_1.css)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    margin-bottom: ", ";\n\n    ", "\n  "], ["\n    margin-bottom: ", ";\n\n    ", "\n  "])), (0, _1.getRelative)(40, 'mobile'), _1.mediaQuery.greaterThan('tablet')(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n      margin-bottom: ", ";\n    "], ["\n      margin-bottom: ", ";\n    "])), (0, _1.getRelative)(50, 'desktop')));
};
exports.getMargin50 = getMargin50;
var getPadding50 = function () {
    return (0, styled_components_1.css)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n    padding-bottom: ", ";\n\n    ", "\n  "], ["\n    padding-bottom: ", ";\n\n    ", "\n  "])), (0, _1.getRelative)(40, 'mobile'), _1.mediaQuery.greaterThan('tablet')(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n      padding-bottom: ", ";\n    "], ["\n      padding-bottom: ", ";\n    "])), (0, _1.getRelative)(50, 'desktop')));
};
exports.getPadding50 = getPadding50;
var getMargin60 = function (top) {
    if (top === void 0) { top = false; }
    if (top) {
        return (0, styled_components_1.css)(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n      margin-top: ", ";\n\n      ", "\n    "], ["\n      margin-top: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(40, 'mobile'), _1.mediaQuery.greaterThan('tablet')(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n        margin-top: ", ";\n      "], ["\n        margin-top: ", ";\n      "])), (0, _1.getRelative)(60, 'desktop')));
    }
    else {
        return (0, styled_components_1.css)(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n      margin-bottom: ", ";\n\n      ", "\n    "], ["\n      margin-bottom: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(40, 'mobile'), _1.mediaQuery.greaterThan('tablet')(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n        margin-bottom: ", ";\n      "], ["\n        margin-bottom: ", ";\n      "])), (0, _1.getRelative)(60, 'desktop')));
    }
};
exports.getMargin60 = getMargin60;
var getMargin90 = function (top) {
    if (top === void 0) { top = false; }
    if (top) {
        return (0, styled_components_1.css)(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n      margin-top: ", ";\n\n      ", "\n    "], ["\n      margin-top: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(60, 'mobile'), _1.mediaQuery.greaterThan('tablet')(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n        margin-top: ", ";\n      "], ["\n        margin-top: ", ";\n      "])), (0, _1.getRelative)(90, 'desktop')));
    }
    else {
        return (0, styled_components_1.css)(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n      margin-bottom: ", ";\n\n      ", "\n    "], ["\n      margin-bottom: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(60, 'mobile'), _1.mediaQuery.greaterThan('tablet')(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n        margin-bottom: ", ";\n      "], ["\n        margin-bottom: ", ";\n      "])), (0, _1.getRelative)(90, 'desktop')));
    }
};
exports.getMargin90 = getMargin90;
var getMargin130 = function (top) {
    if (top === void 0) { top = false; }
    if (top) {
        return (0, styled_components_1.css)(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n      margin-top: ", ";\n\n      ", "\n    "], ["\n      margin-top: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(100, 'mobile'), _1.mediaQuery.greaterThan('tablet')(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n        margin-top: ", ";\n      "], ["\n        margin-top: ", ";\n      "])), (0, _1.getRelative)(130, 'desktop')));
    }
    else {
        return (0, styled_components_1.css)(templateObject_30 || (templateObject_30 = __makeTemplateObject(["\n      margin-bottom: ", ";\n\n      ", "\n    "], ["\n      margin-bottom: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(100, 'mobile'), _1.mediaQuery.greaterThan('tablet')(templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n        margin-bottom: ", ";\n      "], ["\n        margin-bottom: ", ";\n      "])), (0, _1.getRelative)(130, 'desktop')));
    }
};
exports.getMargin130 = getMargin130;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29, templateObject_30;
