import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import { useMount } from '@/hooks'
import { getTransition } from '@/styles'

interface IBurger {
  onClick: any,
  menuActive: boolean,
  currentLevel?: any
}

const Button = styled.button`
  z-index: 200;
`

const variants_aside = {
  'initial': {
    opacity: 0,
    transition: {
      ...getTransition(),
    }
  },
  'animate': {
    opacity: 1,
    transition: {
      ...getTransition(),
    }
  },
  'exit': {
    opacity: 0,
    transition: {
      ...getTransition(),
    }
  },
}

const BurgerLine = () => (
  <motion.svg key='burger' initial='initial' exit='exit' animate='animate' variants={variants_aside} width="40px" height="40px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>i--menu</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="i--menu" transform="translate(-32.000000, -21.000000)">
            <g transform="translate(32.000000, 21.000000)">
                <rect id="Rectangle" x="0" y="0" width="40" height="40"></rect>
                <line className='burger-line' x1="14.5" y1="19.5" x2="41.5" y2="19.5" id="Line" stroke="#FFFFFF" strokeWidth="0.5" strokeLinecap="square"></line>
                <line className='burger-line' x1="14.5" y1="30.5" x2="41.5" y2="30.5" id="Line-Copy" stroke="#FFFFFF" strokeWidth="0.5" strokeLinecap="square"></line>
            </g>
        </g>
    </g>
</motion.svg>
)

export const Burger = ({ onClick, menuActive, currentLevel }:IBurger) => {
  const isMounted = useMount()

  return isMounted && (
    <Button key='mobile' className='button-menu' onClick={onClick} name='Menu'>
      <AnimatePresence exitBeforeEnter>
        {currentLevel > 0 ?
          <motion.img key='back' src={`/images/svg/back.svg`} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} alt='Burger menu icon' width='40' height='40'/>  : 
          menuActive ? 
          <motion.img key='close' src={`/images/svg/close.svg`} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} alt='Burger menu icon' width='40' height='40'/> : <BurgerLine/>
        }
      </AnimatePresence>
    </Button>
  )
}
