"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScrollViewer = void 0;
var react_1 = require("react");
var STATES = {
    TOP: 'TOP',
    SCROLLING_DOWN: 'SCROLLING_DOWN',
    SCROLLING_UP: 'SCROLLING_UP',
    currentState: 'TOP',
    lastScroll: 0,
    getCurrentState: function (currentScroll, maxHeight) {
        if (currentScroll < maxHeight)
            this.currentState = this.TOP;
        else {
            if (this.lastScroll < currentScroll)
                this.currentState = this.SCROLLING_DOWN;
            else if (this.lastScroll > currentScroll)
                this.currentState = this.SCROLLING_UP;
        }
        this.lastScroll = currentScroll;
        return this.currentState;
    }
};
var useScrollViewer = function (maxHeight) {
    var _a = __read((0, react_1.useState)(STATES.TOP), 2), currentState = _a[0], setState = _a[1];
    var checkScroll = function () {
        var newState = getCurrentState();
        setState(function (currentState) {
            if (currentState !== newState)
                return newState;
            return currentState;
        });
    };
    var getCurrentState = function () {
        var currentScroll = window.pageYOffset;
        return STATES.getCurrentState(currentScroll, maxHeight);
    };
    (0, react_1.useEffect)(function () {
        window.addEventListener('scroll', checkScroll, { passive: true });
        return function () { return window.removeEventListener('scroll', checkScroll); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxHeight]);
    return currentState;
};
exports.useScrollViewer = useScrollViewer;
