"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Reset = exports.theme = void 0;
var _1 = require(".");
var themeProject = {
    colors: {
        'background': '#FBFBFB',
        'text': '#4D4D4D',
        'white': '#FFFFFF',
        'black': '#4D4D4D',
        'creme': '#E6E3DD',
        'orange': '#FF4E15',
        'img_bg': '#EFEFEF',
        'grey_dark': '#B8B8B8',
        'grey_medium': '#EDEDED',
        'grey_light': '#FBFBFB',
        'input_text_light': '#FFFFFF',
        'input_text_dark': '#4D4D4D',
        'input_error': '#FF4E15',
        'input_black': '#4D4D4D',
        'toast_error': '#FF0048',
        'toast_success': '#E8A836',
        'toast_warning': '#FF0048'
    },
    fonts: {
        'family': "Plain, sans-serif",
    },
    height: {
        navbar: {
            desktop: 80,
            mobile: 50
        }
    }
};
exports.theme = (0, _1.overwriteDefaultTheme)(themeProject);
var extraGlobalCss = "\n  .cortina-klk {\n    height: 150vh;\n    position: fixed;\n    top: -10%;\n    left: 0;\n    width: 100vw;\n    opacity: 1;\n    background-color: #FBFBFB;\n    z-index: 1000000000;\n    pointer-events: none;\n    user-select: none;\n    transition: opacity 300ms ease;\n  }\n\n  .cortina-klk.load {\n    opacity: 0;\n  }\n\n  html {\n    &.anchor-behave {\n      scroll-behavior: smooth;\n    }    \n  }\n\n  body {\n    color: ".concat(themeProject.colors.text, ";\n    font-family: ").concat(themeProject.fonts.family, ";\n\n    &.slider-being-dragged {\n      .slider--flickity {\n        button,\n        a,\n        article {\n          pointer-events: none !important;\n        }\n      }\n    }\n  }\n  \n  .slider--flickify {\n    outline: none;\n  }\n\n  .flickify-viewport {\n    overflow: hidden;\n    position: relative;\n  }\n");
exports.Reset = (0, _1.withGlobalStyle)(exports.theme, extraGlobalCss);
