import { memo, useCallback, useState, useEffect, ReactNode, useMemo, isValidElement, useRef, forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

import { getTransition, hex2Rgba } from '@/styles'
import { useMediaType } from '@/hooks'

import { mediaQuery, getRelative, getP18, getP12 } from '@/styles'

export interface InputState {
  hover: boolean,
  focus: boolean,
  activated: boolean,
}

interface IInput {
  initialInputState?: InputState,
  initialValue?: string,
  onChange?: (value: string, showValue: string) => void,
  onBlur?: (e: any, value: string, showValue: string) => void | null,
  disabled?: boolean,
  error?: boolean,
  label?: string,
  placeholder?: string,
  className?: string,
  name?: string,
  image?: boolean,
  autofocus?: boolean,
  type?: string,
  reset?: boolean,
  onKeyUp?: any,
  placeHolderAlwaysActive?: boolean,
  autoComplete?: string,
  spellcheck?: boolean,
  icon?: ReactNode | string,
  children?: ReactNode,
  onFocus?: any,
  errorMessage?: string
  infoMessage?: string,
  ref?: any,
  readOnly?: any,
  id?: any,
  registerProps?: any,
  hook_setValue?: any
}

export const Div = styled.div<any>`
  display: flex;
  opacity: ${({ disable }) => disable ? .5 : 1};
  position: relative;
  transition: opacity 300ms ${({ theme }) => theme.easing};
  width: 100%;
  margin-bottom: ${getRelative(55, 'mobile')};

  input, span, label {
    ${getP18()}
    font-family: ${({ theme }) => theme.fonts.family};
  }

  input, label { cursor: inherit; }

  ${mediaQuery.greaterThan('tablet')`
    margin-bottom: ${getRelative(60, 'desktop')};
  `}

  ${({ disable }) => disable ? css`cursor: not-allowed;` : ''};
`

export const Span = styled(motion.span)<any>`
  position: absolute;
  bottom: 0;
  height: 1px;
  background-color: ${({ theme, error }) => error ? theme.colors.input_error : theme.colors.black};
  transition: background-color 300ms ${({ theme }) => theme.easing};
  left: 0;
  width: 0%;
`

export const Label = styled(motion.label)<any>`
  position: absolute;
  letter-spacing: 0;
  color: ${({ theme: { colors }, error }) => error ? colors.input_error : colors.input_black};
  bottom: 0;
  user-select: none;
  pointer-events: none;
  transition: color 300ms ${({ theme }) => theme.ease};
`

export const P = styled(motion.p)<any>`
  ${getP12()}
  color: ${({ theme, error }) => error ? theme.colors.input_error : 'rgba(0,0,0, .5)'};
  position: absolute;
  opacity: 0;
  pointer-events: none;
  margin-top: ${getRelative(35, 'mobile')};

  ${mediaQuery.greaterThan('tablet')`
    margin-top: ${getRelative(25, 'desktop')};
  `}
`

const defaultPlaceHolderStyles = (color) => css<any>`
  transition: opacity 300ms ${({ ease }) => ease};
  color: ${({ theme }) => color ? color : theme.colors.input_black};
`

export const parsePlaceHolder = (styles, color = null) => (css`
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    ${defaultPlaceHolderStyles(color)}
    ${styles}
  }
  ::-moz-placeholder { /* Firefox 19+ */
    ${defaultPlaceHolderStyles(color)}
    ${styles}
  }
  :-ms-input-placeholder { /* IE 10+ */
    ${defaultPlaceHolderStyles(color)}
    ${styles}
  }
  :-moz-placeholder { /* Firefox 18- */
    ${defaultPlaceHolderStyles(color)}
    ${styles}
  }
`)

const InputBase = styled.input<any>`
  width: 100%;
  border-bottom: 1px solid ${({ theme, error }) => error ? theme.colors.input_error : hex2Rgba(theme.colors.black, .4)};
  background-color: transparent;
  color: ${({ theme, error }) => error ? theme.colors.input_error : theme.colors.black};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-bottom: ${getRelative(9.5, 'mobile')};
  padding-right: ${getRelative(20, 'mobile')};

  ${mediaQuery.greaterThan('tablet')`
    padding-bottom: ${getRelative(10, 'desktop')};
    padding-right: 0;
  `}

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-internal-autofill-selected {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: none;
    -webkit-text-fill-color: ${({ theme, error }) => error ? theme.colors.input_error : 'none'};
    transition: background-color 5000s ease-in-out 0s;
  }

  ${({ theme, placeholderActive }) => parsePlaceHolder(`opacity: ${placeholderActive ? .3 : 0};`, theme.colors.input_text_black)}
`

export const DEFAULT_INPUT_STATE: InputState = {
  hover: false,
  focus: false,
  activated: false,
}

export const variantsSpan = {
  active: { width: '100%' },
  inactive: { width: '0%' },
}

export const variantsLabel = {
  active: (mobile) => ({
    opacity: 1,
    y: mobile ? getRelative(-37, 'mobile') : getRelative(-37, 'desktop'),
    // textTransform: 'uppercase',
    transition: {
      type: 'tween',
      duration: .3,
      ease: [.25, .1, .25, 1],
    }
  }),
  inactive: (mobile) => ({
    opacity: .4,
    y: mobile ? getRelative(-16, 'mobile') : getRelative(-16, 'desktop'),
    textTransform: 'initial',
    transition: {
      type: 'tween',
      duration: .3,
      ease: [.25, .1, .25, 1],
    }
  })
}

export const variantsError = {
  active: (mobile) => ({ pointerEvents: 'initial', opacity: 1, y: mobile ? getRelative(25, 'mobile') : getRelative(19, 'desktop') }),
  inactive: (mobile) => ({ pointerEvents: 'none', opacity: 0, y: mobile ? getRelative(20, 'mobile') : getRelative(19, 'desktop') }),
}

export const transition = {
  ...getTransition(),
  type: 'tween',
}

export const Input = memo<IInput>(forwardRef(({ name, reset = false, type = 'text', label = '', placeholder = '', initialInputState = DEFAULT_INPUT_STATE, initialValue = '', onChange = null, onBlur = null, disabled = false, autofocus = false, error = false, className = '', icon = null, children = null, onFocus = null, onKeyUp = null, errorMessage = '', infoMessage = '', autoComplete = 'new-password', spellcheck = false, placeHolderAlwaysActive = false, registerProps =  null, hook_setValue = null, readOnly = false }, ref) => {
  const [value, setValue] = useState(initialValue ? initialValue : '')
  const [inputState, setState] = useState(initialInputState)
  const [currentInputType, setInputType] = useState(type)
  const baseRef = useRef(null)

  const mediaType = useMediaType()

  const { hover, focus, activated } = inputState

  const handleChange = useCallback(({ target }) => {
    onChange && onChange(target.value, target.value)
    setValue(target.value)
    handleStateChange('activated', target.value.length > 0 )()
  }, [onChange])

  const handleStateChange = useCallback((keys, values) => () => {
    let newState = { ...inputState }

    if (typeof keys === 'string') {
      newState = { ...newState, [keys]: values }
    } else {
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        const value = values[i]
        newState = { ...newState, [key]: value }
      }
    }

    setState(newState)
  }, [ inputState ])

  const handleInputFocus = useCallback(() => {
    onFocus && onFocus()
    handleStateChange('focus', true)()
  }, [])

  const handleInputBlur = useCallback((e) => {
    onBlur && onBlur(e, value, value)
    handleStateChange(['focus', 'activated'], [false, value.length > 0])()
  }, [value])

  useEffect(() => {
    if (initialValue || initialValue === '') {
      setValue(initialValue)
      handleStateChange('activated', initialValue.length > 0)()
    }
  }, [initialValue])

  useEffect(() => {
    if (reset) {
      setValue(initialValue)
      handleStateChange('activated', initialValue.length > 0)()
    }
  }, [reset])

  useEffect(() => {
    if (autofocus) {
      baseRef.current.getElementsByTagName('input')[0].focus()
      handleInputFocus()
    } else {
      handleStateChange('activated', value.length > 0 )()
    }

    return () => handleStateChange('activated', value.length > 0)()
  }, [autofocus])

  useEffect(() => {
    hook_setValue && hook_setValue(name, initialValue)
  }, [initialValue])

  const [Icon, hasIcon] = useMemo(() => {
    if (isValidElement(icon)) {
      if (type === 'password') {
        const Icon = <button type='button' className='input-pws-btn' onClick={(e) => {
          e.preventDefault()
          setInputType(currentInputType === 'password' ? 'text' : 'password')
        }}>{icon}</button>
        return [Icon, true]
      }

      return [icon, true]
    }

    return [null, false]
  } ,[icon, currentInputType])

  const labelActive = !disabled && focus || activated ? 'active' : 'inactive'
  const spanActive = !disabled &&  hover || focus || error ? 'active' : 'inactive'
  const placeHolderActive = placeHolderAlwaysActive || focus || activated
  const mobile = mediaType === 'mobile'

  return (
  <Div ref={baseRef} hasIcon={hasIcon} className={className} disable={disabled} onMouseEnter={handleStateChange('hover', true)} onMouseLeave={handleStateChange('hover', false)}>
    <Label error={error} custom={mobile} active={labelActive === 'active' && focus} variants={variantsLabel} initial={['inactive', 'active', 'inactive']} animate={labelActive}>{ label }</Label>
    <InputBase error={error} readOnly={readOnly} data-status={activated} name={name} autoCorrect='nope' spellCheck={spellcheck} autoComplete={autoComplete} type={currentInputType} disabled={disabled} onFocus={handleInputFocus} onBlur={handleInputBlur} placeholder={placeholder} onChange={handleChange} placeholderActive={placeHolderActive} defaultValue={initialValue} ref={ref} {...(onKeyUp && { onKeyUp })} {...(registerProps && { ...registerProps })}/>
    <Span custom={mobile} error={error} transition={transition} variants={variantsSpan} animate={spanActive} />
    {hasIcon && Icon}
    {children && children}
  </Div>)
}))
