import 'flickity/css/flickity.css';
import Head from 'next/head'
import { AppProps } from 'next/app'
import React, { useMemo, useRef, useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import { useRouter } from 'next/router';
import { RecoilRoot } from 'recoil'
import { Layout } from '../components'
import { getTransition, theme, Reset } from '@/styles'
import { Provideri18n, DEFAULT_LOCALES } from '@/i18n'
import { ProviderModal } from '@/modals'

interface IProps {
  layoutProps?: any,
}

const cortina_variants = {
  initial: {
    scaleY: 1,
    transformOrigin: 'top',
    transition: {
      ...getTransition(),
      duration: .5,
    }
  },
  active: {
    scaleY: 0,
    transition: {
      ...getTransition(),
      duration: .5,
      delay: .5,
      ease: 'easeOut'
    },
    transitionEnd: {
      transformOrigin: 'bottom'
    }
  },
  exit: {
    scaleY: 1,
    transition: {
      ...getTransition(),
      duration: .5,
      ease: 'easeIn'
    }
  }
}

const Cortina =  styled(motion.div)<any>`
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.creme};
  z-index: 1000000000;
  pointer-events: none;
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  transform-origin: bottom;
`

const variantsWrapper = {
  animate: {
    opacity: 1,
    transition: {
      ...getTransition(),
      delay: 1
    }
  },
  initial: {
    opacity: 0,
    transition: {
      ...getTransition(),
    }
  }
}

const MyApp = ({ Component, pageProps }: AppProps & IProps) => {
  const { pathname, asPath, events } = useRouter()
  const { layout_data, locales } = pageProps
  const controls = useAnimation()
  const shouldAnimate = useRef(true)

  const motionKey = useMemo(() => {
    return pathname === '/products/[slug]' || pathname === '/usa/products/[slug]' || pathname === '/projects/[slug]' || asPath.includes('/collection/') || asPath.includes('/about/') || asPath.includes('/article/') || asPath.includes('/service/') ? pathname : asPath
  }, [pathname, asPath])

  useEffect(() => {
    document.getElementById('cortina').classList.add('load')
  }, [])

  useEffect(() => {
    const handleStart = (url) => {
      if (url.includes('/products/')) {
        if (shouldAnimate.current) {
          controls.start('initial')
        }
        shouldAnimate.current = false
      } else {
        shouldAnimate.current = true
        controls.start('initial')
      }
    }
    const handleStop = () => {
      setTimeout(() => {
        controls.start('animate')
      }, 600)
    }

    events.on('routeChangeStart', handleStart)
    events.on('routeChangeComplete', handleStop)
    events.on('routeChangeError', handleStop)

    return () => {
      events.off('routeChangeStart', handleStart)
      events.off('routeChangeComplete', handleStop)
      events.off('routeChangeError', handleStop)
    }
  }, [events])

  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=2.0' />
      </Head>
      <Reset/>
      <Provideri18n value={locales ? { ...locales } : { ...DEFAULT_LOCALES } }>
        <ProviderModal>
          <RecoilRoot>
            <ThemeProvider theme={theme}>
              <Layout data={layout_data}>
                <AnimatePresence exitBeforeEnter initial={false}>
                  <Cortina key={motionKey + 'curtain'} variants={cortina_variants} initial='initial' animate='active' exit='exit' />
                </AnimatePresence>
                <motion.div animate={controls} variants={variantsWrapper} >
                  <Component {...pageProps} />
                </motion.div>
              </Layout>
            </ThemeProvider>
          </RecoilRoot>
        </ProviderModal>
      </Provideri18n>
    </>
  )
}

//@ts-ignore
export default MyApp
