import { memo, useMemo, useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { Figure } from '@dy/commons/components'
import { parserMedia } from '@dy/commons/utils'
import { mediaQuery, createGrid, getRelative, getTransition, hideScrollbar, getP24, getP18 } from '@/styles'
import { Anchor } from './AnchorPoints'
import { callAPI } from 'api/urls'
import { PieColors } from './PieColors'
import { useI18n } from '@/i18n'
import { useRouter } from 'next/router'
interface ISectionProductDetail {
  data: any,
  onInView: any
}

const Section = styled.section`
  ${createGrid()}

  ${mediaQuery.greaterThan('tablet')`
    position: relative;
  `}

  .detail-info {
    grid-area: 2 / 1 / span 1 / span 6;

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 1 / 1 / span 1 / span 5;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-auto-rows: minmax(min-content, max-content);
      position: sticky;
      top: 0;
      height: max-content;
    `}
  }

  > div:last-of-type {
    grid-area: 1 / 1 / span 1 / span 6;

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 1 / 7 / span 1 / span 5;
    `}
  }

  p.description {
    ${getP24()}
    margin-bottom: ${getRelative(30, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 1 / 1 / span 1 / span 5;
      margin-top: ${getRelative(108, 'desktop')};
      margin-bottom: ${getRelative(40, 'desktop')};
      margin-left: ${getRelative(40, 'desktop')};
    `}
  }

  dl {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: ${getRelative(30, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 2 / 1 / span 1 / span 4;
      grid-row-gap: ${getRelative(30, 'desktop')};

      > * {
        margin-left: ${getRelative(40, 'desktop')};
      }

      > div:nth-child(even) {
        margin-left: ${getRelative(25, 'desktop')};
      }
    `}
  }

  dt, dd {
    ${getP18()}
  }

  .dimensions:only-child {
    ${mediaQuery.greaterThan('tablet')`
      grid-area: 1 / 1 / span 1 / span 2;
    `}
  }

  .color {
    max-width: 88%;

    ${mediaQuery.greaterThan('tablet')`
      max-width: 70%;
    `}

    dt {
      display: block;
      margin-bottom: ${getRelative(15, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        margin-bottom: ${getRelative(18, 'desktop')};
      `}
    }

    .pie-color {
      transition: opacity 300ms ${({ theme }) => theme.ease};
      cursor: pointer;

      &.active,
      &:hover {
        opacity: .4;
      }

      &.disabled {
        a {
          cursor: auto;
          pointer-events: none !important;
        }
      }
    }

    dd {
      display: inline-block;
      vertical-align: top;
      margin-right: ${getRelative(10, 'mobile')};
      margin-bottom: ${getRelative(10, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        margin-right: ${getRelative(6, 'desktop')};
        margin-bottom: ${getRelative(8, 'desktop')};
      `}

      > a { line-height: 0; }
    }

    svg, figure {
      width: ${getRelative(40, 'mobile')};
      height: ${getRelative(40, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        width: ${getRelative(25, 'desktop')};
        height: ${getRelative(25, 'desktop')};
      `}
    }
  }

  .gallery {
    max-width: 99%;
    overflow-x: scroll;
    display: flex;
    margin-left: -${getRelative(23, 'mobile')};
    margin-right: -${getRelative(23, 'mobile')};
    margin-bottom: ${getRelative(40, 'mobile')};
    ${hideScrollbar()}

    ${mediaQuery.greaterThan('tablet')`
      display: block;
      margin-right: 0;
      margin-bottom: 0;
      margin-left: 0;
    `}

    figure {
      height: ${getRelative(200, 'mobile')};
      margin-right: ${getRelative(10, 'mobile')};

      &:first-of-type {
        margin-left: ${getRelative(23, 'mobile')};
      }

      ${mediaQuery.greaterThan('tablet')`
        height: unset;
        width: ${getRelative(550, 'desktop')};
        margin-left: ${getRelative(14, 'desktop')};
        margin-bottom: ${getRelative(90, 'desktop')};
        margin-right: 0;

        &:first-of-type {
          margin-left: ${getRelative(14, 'desktop')};
        }
      `}

      > span {
        position: relative !important;
      }

      img {
        height: 100% !important;
        object-fit: contain;
        position: relative !important;
        width: auto !important;

        ${mediaQuery.greaterThan('tablet')`
          width: 100% !important;
        `}
      }
    }
  }
`

const imageVariants = {
  initial: {
    opacity: 0,
    transition: {
      ...getTransition(),
      delay: 0,
    }
  },
  animate: {
    opacity: 1,
    transition: {
      ...getTransition(),
      duration: 1,
      delay: .3,
    }
  },
  exit: {
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .2,
      delay: 0,
    }
  },
}

const getColors = (colors, setColor, selectedColor) => {
  const Colors = []
  const [centinella, too_much] = colors.length <= 11 ? [colors.length, false] : [10, true]

  for (let idx = 0; idx < centinella; idx++) {
    let _colors = ['#ffffff']
    let ids = 'disabled'
    if (!!colors[idx] && typeof colors[idx] === 'object') {
      _colors = colors[idx].colors
      ids = colors[idx].ids
    }

    const onClick = e => {
      if (ids === 'disabled') {
        e?.preventDefault()
        return
      }

      setColor(idx, ids.toString())
    }

    Colors.push(
      <dd className={`pie-color ${selectedColor === idx ? 'active' : ''}${ids === 'disabled' ? ' disabled' : ''}`} key={idx} >
      <a href='#overview' onClick={onClick}>
        <PieColors colors={_colors}></PieColors>
      </a>
    </dd>)
  }

  if (too_much) {
    Colors.push(
      <dd key={'too-much'}>
        <a href='#configurator'>
          <figure>
            <img src='/images/svg/i--color-plus.svg' alt='Icon plus sign'/>
          </figure>
        </a>
      </dd>)
  }

  return Colors
}

const getImages = (default_images, currentImages) => {
  if (currentImages.length) {
    return currentImages.map((img, idx) => {
      return (
        <Figure key={idx + img.pdp?.alt || 'klk'} media={parserMedia(img.pdp)} lazy={false}/>
      )
    })
  } else {
    return default_images.map((img, idx) => {
      return (
        <Figure key={idx + img.pdp?.alt || 'klk'} media={parserMedia(img.pdp)} lazy={false}/>
      )
    })
  }
}


const getText = (texts, value) => {

  // TEMP PRICE DISABLED
  if (value === 'price') {
    return (<dd key={'price-klk'}></dd>)
    // return  (
    //   <dd key={'price-klk'}>
    //     <p>{`${texts.toFixed(2).replace('.', ',')} EUR`}</p>
    //   </dd>
    // )
  }

  if (value === 'techno') {
    if (typeof texts === 'string') {
      return  (
        <dd key={'price-klk'}>
          <p>{`${texts}`}</p>
        </dd>
      )
    }

    return <></>
  }

  return texts.map((obj, idx) => {
    const text = obj[value]

    return (
      <dd key={idx}>
        <p>{text}</p>
      </dd>
    )
  })
}

export const SectionProductDetail = memo<ISectionProductDetail>(({ data, onInView }) => {
  const { description, colors, dimensions, technologies, price, default_images, slug, category_type } = data
  // console.log('colors', colors)
  const [selectedColor, setColor] = useState(null)
  const [currentImages, setImages] = useState([])
  const { locales: { product: { available_color, available_dimensions, avaialble_wattage, price_title } } } = useI18n()
  const { asPath, locale, pathname } = useRouter()

  const dimensionIds = useMemo(() => dimensions.map(dimension => dimension.id), [dimensions])

  const setColors = useCallback((idx, _ids) => {
    setColor(idx)

    const colorImages = []
    let counter = 0
    dimensionIds.map(async (dimensionId) => {
      const ids = `${dimensionId},${_ids}`
      const type = pathname.includes('/usa') ? 'usa-configurator-color' : 'configurator-color'
      const [data, error] = await callAPI({ type, params: { locale, slug }, query: { ids }  })

      if (!error && data.length) {
        colorImages.push({...data[0], objId: dimensionId})
        counter++
      } else {
        console.info('error no images detail pdp -> ', ids, data)
        counter++
      }

      if (counter === dimensionIds.length || counter > 7) {
        if (colorImages.length > 0) {

          colorImages.sort((a, b) => {
            return a.objId - b.objId;
          })

          setImages(colorImages)
        } else {
          setImages([])
        }
      }
    })
  }, [pathname])

  const Colors = useMemo(() => getColors(colors, setColors, selectedColor), [colors, selectedColor])
  const Images = useMemo(() => getImages(default_images, currentImages), [currentImages, default_images])
  const Dimensions = useMemo(() => getText(dimensions, 'name'), [dimensions])
  const Technologies = useMemo(() => getText(technologies, 'techno'), [technologies])
  // const Price = useMemo(() => getText(price, 'price'), [price])

  const [ref, inView] = useInView({
    rootMargin: '40% 0% 0% 0%',
    threshold: 0.3,
    triggerOnce: false
  })

  useEffect(() => {
    if (inView) {
      onInView('#overview')
    }
  }, [inView])

  return (<>
    <Section ref={ref}>
      <Anchor top={-90} id={'overview'}/>
      <div className='detail-info'>
        <p className='description'>{description}</p>
        <dl>
          {Colors.length && <div className='color'>
            <dt>{available_color}</dt>
            {Colors}
          </div>}
          {Dimensions.length && <div className='dimensions'>
            <dt>{category_type === 'led_strip' ? avaialble_wattage : available_dimensions}</dt>
            {Dimensions}
          </div>}
          {Technologies?.length && <div>
            {Technologies}
          </div>}
          {/*  // TEMP PRICE DISABLED */}
          {/* <div className='price-wrapper'>
            <dt></dt>
            <dd></dd>
            <dt>{price_title}</dt>
            {Price}
          </div> */}
        </dl>
      </div>
      <AnimatePresence exitBeforeEnter>
        <motion.div className='gallery' key={selectedColor + asPath} variants={imageVariants} initial='initial' exit='exit' animate='animate'>
          {Images}
        </motion.div>
      </AnimatePresence>
    </Section></>
  )
})
