import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { useForm } from 'react-hook-form'
import { mediaQuery, getRelative, createGrid, getP12, getP18, getTransition } from '@/styles'
import { callAPI } from '@/api'
import { useI18n } from '@/i18n'
import { RadioCheckBoxes, Input ,Select, INPUT_PATTERNS } from '../components/commons'
import { Header, Body, Main } from './Layout'
import { useModal } from './'
import { useMount } from '@/hooks'
import { useRouter } from 'next/router'

const Overlay = styled(motion.div)<any>`
  ${createGrid()}
  display: ${({ $isActive }) => $isActive ? 'grid' : 'none'};
  place-content: center;
  background: ${({ theme, $isActive }) => $isActive ? theme.colors.creme : ''};
  bottom: 0;
  left: 0;
  opacity: 1;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity .3s ${({ theme }) => theme.ease};
  user-select: none;
  z-index: 2000;

  &.loaded {
    opacity: 0;
  }
`

const _Body = styled(Body)`
  .email-country {
    display: grid;

    ${mediaQuery.greaterThan('tablet')`
      grid-template-columns: 1fr 1fr;
      grid-column-gap: ${getRelative(25, 'desktop')};
    `}

  }

  .checkboxes {
    display: grid;
    margin-bottom: ${getRelative(60, 'mobile')};
    grid-row-gap: ${getRelative(55, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      grid-template-columns: 1fr 2fr;
      grid-column-gap: ${getRelative(25, 'desktop')};
      margin-bottom: ${getRelative(60, 'desktop')};
    `}

    fieldset {
      position: relative;
    }

    legend {
      margin-bottom: ${getRelative(20, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        margin-bottom: ${getRelative(20, 'desktop')};
        margin-left: ${getRelative(24, 'desktop')};
      `}
    }
  }

  input[type='submit'] {
    ${getP18()}
    font-family: ${({ theme }) => theme.fonts.family};
  }
`

const variants_overlay = {
  enter: {
    opacity: 0,
    y: '10px',
    transition: { ...getTransition(), delay: .2 }
  },
  animate: {
    opacity: 1,
    y: '0px',
    transition: { ...getTransition(),
    delay: .2 }
  },
  exit: {
    opacity: 0,
    y: '10px',
    transition: { ...getTransition() }
  }
}

const variants_error = {
  initial: {
    opacity: 0,
    y: '-10px',
    transition: { ...getTransition() }
  },
  animate: {
    opacity: 1,
    y: '0px',
    transition: { ...getTransition() }
  },
  exit: {
    opacity: 0,
    y: '10px',
    transition: { ...getTransition() }
  }
}

export const P = styled(motion.p)<any>`
  ${getP12()}
  color: ${({ theme, error }) => error ? theme.colors.input_error : 'rgba(0,0,0, .5)'};
  position: absolute;
  opacity: 0;
  pointer-events: none;
  margin-top: ${getRelative(5, 'mobile')};

  ${mediaQuery.greaterThan('tablet')`
    margin-top: -${getRelative(10, 'desktop')};
    margin-left: ${getRelative(24, 'desktop')};
  `}
`

export const variantsError = {
  active: (mobile) => ({ pointerEvents: 'initial', opacity: 1, y: mobile ? getRelative(25, 'mobile') : getRelative(19, 'desktop') }),
  inactive: (mobile) => ({ pointerEvents: 'none', opacity: 0, y: mobile ? getRelative(20, 'mobile') : getRelative(19, 'desktop') }),
}

export const transition = {
  ...getTransition(),
  type: 'tween',
}

export const modalNewsletterId = 'modal-newsletter'
export const ModalNewsletter = () => {
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [countries, setCountries] = useState([])
  // const [countrySelected, setCountrySelected] = useState('')
  const { locale } = useRouter()
  const { isActive, close } = useModal(modalNewsletterId)
  const { locales: { modales: { newsletter } } } = useI18n()
  const { register, handleSubmit, formState: { errors }, setValue, watch, reset } = useForm({
    defaultValues: {
      email: newsletter.email,
      country: null,
      professions: null,
      legal: null
    }
  })
  const [ newsletterError, setNewsletterError ] = useState('')
  const watchCountry = watch('country')
  const watchEmail = watch('email')
  const watchProfessions = watch('professions')
  const watchLegal = watch('legal')
  const isMount = useMount()
  // const countryOptions = countries.map(country => country)

  const closeAndReset = () => {
    close()
    setButtonDisabled(true)
    reset()
  }

  const onSubmit = async (data) => {
    const { email, country, professions } = data
    const [subscribeData, subscribeError] = await callAPI({ type: 'newsletter-2022', params: { locale }, body: { email, country: country, professions, lang: locale, legal: true }})
    if (subscribeError) {
      setNewsletterError(subscribeData.title)
    } else {
      setNewsletterError('')
    }

    closeAndReset()
  }

  useEffect(() => {
    async function getLocalCountriesData() {
      const [res, error] = await callAPI({ type: 'countries' })

      if(!error && res) {
        const countries = res.data.map((item) => {
          item.name = item.name[locale]
          item.value = item.code
          return item
        })
        const countriesOrdered = countries.sort((a, b) => a.name.localeCompare(b.name))
        setCountries(countriesOrdered)
      }
    }

    getLocalCountriesData()
  }, [])

  useEffect(() => {
    if((watchEmail && watchEmail !== newsletter.email) && watchProfessions !== null && watchLegal !== null) {
      setButtonDisabled(false)
    }
  }, [watchEmail, watchCountry, watchProfessions, watchLegal])

  return (
    isMount && <AnimatePresence exitBeforeEnter>
      <Overlay $isActive={isActive} initial='enter' exit='exit' animate={isActive ? 'animate' : 'exit'} variants={variants_overlay} key={`${isActive}`}>
      <Header onClick={closeAndReset} />
      <_Body isActive={isActive}>
        <Main>
          <h2 dangerouslySetInnerHTML={{ __html: newsletter.title }} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='email-country'>
              <Input name='email' error={errors.email ? true : false } hook_setValue={setValue} errorMessage={newsletter?.errorMessages?.email} registerProps={register('email',{ pattern: INPUT_PATTERNS.email, required: true })} placeholder={newsletter.email} placeHolderAlwaysActive/>
              <Select name='country' register={register} placeholder={newsletter.country} options={countries} setFormValue={setValue} placeholderVisible/>
            </div>
            <div className='checkboxes'>
              <fieldset>
                <legend>{newsletter.professions.title}</legend>
                <RadioCheckBoxes registerProps={register('professions', { required: true })} option={newsletter.professions.professional} />
                <RadioCheckBoxes registerProps={register('professions', { required: true })} option={newsletter.professions.distributor} />
                <RadioCheckBoxes registerProps={register('professions', { required: true })} option={newsletter.professions.other} />
              </fieldset>
              <fieldset>
                <legend>{newsletter.legal.title}</legend>
                <RadioCheckBoxes option={newsletter.legal.checkbox} registerProps={register('legal', { required: true })} />
              </fieldset>
            </div>
            <input type='submit' value={newsletter.button} disabled={buttonDisabled} />
          </form>
          <AnimatePresence exitBeforeEnter>
            {newsletterError && <motion.p className='error-message' variants={variants_error}  initial='initial' exit='exit' animate={'animate'}>{newsletterError}</motion.p>}
          </AnimatePresence>
        </Main>
      </_Body>
      </Overlay>
    </AnimatePresence>
  )
}
