import { memo, useMemo, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { WhereToBuyFilter } from './WhereToBuyFilter'

import { mediaQuery, createGrid, getRelative, getTransition, getButtonUnderline, getP24, getP18 } from '@/styles'

interface ISectionWhereToBuy {
  stores: any
}

const TRANSLATIONS = {
  "en": {
    "country": "Country",
    "city": "City",
    "distributors": "Distributors",
    "online": "Online"
  },
  "es": {
    "country": "País",
    "city": "Ciudad",
    "distributors": "Distribuidores",
    "online": "En línea"
  },
  "fr": {
    "country": "Pays",
    "city": "Cité",
    "distributors": "Distributeurs",
    "online": "En ligne"
  },
  "it": {
    "country": "Nazione",
    "city": "Cittá",
    "distributors": "Distributori",
    "online": "Online"
  }
}

const Section = styled.section`
  ${createGrid()}
  margin-bottom: ${getRelative(100, 'mobile')};
  padding-top: ${getRelative(50, 'mobile')};

  ${mediaQuery.greaterThan('tablet')`
    grid-row-gap: ${getRelative(90, 'desktop')};
    padding-top: ${getRelative(140, 'desktop')};
    margin-bottom: ${getRelative(130, 'desktop')};
  `}

  li, button, a, p, div {
    ${getP18()}
  }

  button {
    ${getButtonUnderline()}
  }

  .variants {
    grid-area: 1 / 1 / span 1 / span 6;
    padding: ${getRelative(19, 'mobile')} 0 ${getRelative(33, 'mobile')};
    position: sticky;
    top: ${getRelative(50, 'mobile')};
    background-color: ${({ theme }) => theme.colors.background};
    z-index: 10;

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 1 / 1 / span 1 / span 3;
      margin: 0 0 0 ${getRelative(40, 'desktop')};
      padding: 0;
      position: static;
    `}

    li {
      display: inline-block;
      margin-right: ${getRelative(20, 'mobile')};
      opacity: .4;
      transition: opacity 300ms ${({ theme }) => theme.easing};

      ${mediaQuery.greaterThan('tablet')`
        display: block;
        margin-right: 0;
      `}

      &.active,
      &:hover {
        opacity: 1;
      }

      button {
        ${getP24()}
      }
    }

    + .variant-info {
      margin-top: ${getRelative(11, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        margin-top: 0;
      `}
    }
  }

  .variant-info {
    opacity: 0;
    grid-area: 3 / 1 / span 1 / span 6;
    margin: ${getRelative(30, 'mobile')} 0 -${getRelative(20, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      column-count: 2;
      grid-area: 2 / 5 / span 1 / span 6;
      margin: 0 ${getRelative(21, 'desktop')} -${getRelative(30, 'desktop')};
    `}

    li {
      padding-bottom: ${getRelative(20, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        position: relative;
        break-inside: avoid-column;
        padding-bottom: ${getRelative(30, 'desktop')};
      `}

      p:not(:first-of-type) {
        opacity: 0.4;
      }
    }
  }
`

const imageVariants = {
  'initial': {
    opacity: 0,
    transition: {
      ...getTransition(),
    }
  },
  'animate': {
    opacity: 1,
    transition: {
      ...getTransition(),
    }
  },
  'exit': {
    opacity: 0,
    transition: {
      ...getTransition(),
    }
  }
}

const getVariants = (variants, setVariant, selectedVariant, locale) => variants.map((variant, idx) => {
  const name = variant === 'distributors' || variant === 'online' ? TRANSLATIONS[locale][variant] : variant
  return (
    <li key={idx} className={idx === selectedVariant ? 'active' : ''}>
      <button onClick={() => setVariant(idx)}>{name}</button>
    </li>
  )
})

const getVariantInfos = (infos) => infos.map((info, idx) => {
  const { name, street, zip_code, city, phone, website, country } = info

  return (
    <li key={idx}>
      <p>{name}</p>
      <p>{street}</p>
      <p>{zip_code} {city}</p>
      <p>{country}</p>
      <p>
        <button><a href={`tel:${phone}`}>{phone}<span></span></a></button>
      </p>
      <p>
        <button><a href={website.includes('http') ? website : `https://${website}`} rel='noreferrer' target='_blank' title={website}>{website}<span></span></a></button>
      </p>
    </li>
  )
})

const isNotPlaceholder = (value, keyName) => {
  const languages = Object.keys(TRANSLATIONS)
  let notPlaceholder = true
  for(let locale of languages) {
    if(TRANSLATIONS[locale][keyName] === value) notPlaceholder = false
  }

  return notPlaceholder
}

export const SectionWhereToBuy = memo<ISectionWhereToBuy>(({ stores }) => {
  const variantKeys = Object.keys(stores)

  const { locale } = useRouter()
  const [selectedVariant, setVariant] = useState(0)
  const [selectedCountry, setCountry] = useState(locale ? TRANSLATIONS[locale].country : 'Country')
  const [selectedCity, setCity] = useState(locale ? TRANSLATIONS[locale].city : 'City')
  const storesInfo = stores[variantKeys[selectedVariant]].data
  const [filteredVariants, setFilteredVariants] = useState(storesInfo)
  const Variants = useMemo(() => getVariants(variantKeys, setVariant, selectedVariant, locale), [stores, selectedVariant, locale])
  const VariantInfo = useMemo(() => getVariantInfos(filteredVariants), [filteredVariants])

  useEffect(() => {
    setFilteredVariants(storesInfo)
    if(isNotPlaceholder(selectedCountry, 'country') && isNotPlaceholder(selectedCity, 'city')) {
      const filteredByCountry = storesInfo.filter(distributor => distributor.country === selectedCountry)
      const filteredByCity = filteredByCountry.filter(distributor => distributor.city === selectedCity)
      setFilteredVariants(filteredByCity)
    } else if (isNotPlaceholder(selectedCity, 'city')) {
      const filteredByCity = storesInfo.filter(distributor => distributor.city === selectedCity)
      setFilteredVariants(filteredByCity)
    } else if(isNotPlaceholder(selectedCountry, 'country')) {
      const filteredByCountry = storesInfo.filter(distributor => distributor.country === selectedCountry)
      setFilteredVariants(filteredByCountry)
    } else {
      setFilteredVariants(storesInfo)
    }
  }, [selectedCity, selectedCountry, selectedVariant])

  useEffect(() => {
    setCountry(locale ? TRANSLATIONS[locale].country : 'Country')
    setCity(locale ? TRANSLATIONS[locale].city : 'City')
  }, [selectedVariant])

  return (
    <Section>
      <ul className='variants'>
        {Variants}
      </ul>
      <AnimatePresence exitBeforeEnter>
        <WhereToBuyFilter data={stores[variantKeys[selectedVariant]].meta} key={selectedVariant} setCountry={setCountry} selectedCountry={selectedCountry} setCity={setCity} selectedCity={selectedCity} filterResults={filteredVariants.length} online={selectedVariant > 0 ? true : false}/>
      </AnimatePresence>
      <AnimatePresence exitBeforeEnter>
        <motion.ul className='variant-info' key={selectedCity + selectedCountry + selectedVariant} variants={imageVariants} initial='initial' exit='exit' animate='animate'>
          {(selectedCountry === 'Country' && selectedCity === 'City') ? <li></li> : VariantInfo}
        </motion.ul>
      </AnimatePresence>
    </Section>
  )
})
