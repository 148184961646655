import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { Header, Body, Main } from './Layout'
import { useModal } from './'
import { useMount } from '@/hooks'
import { useI18n } from '@/i18n'
import { FormContact } from 'components/FormContact'
import { createGrid, getTransition, mediaQuery, getRelative } from '@/styles'

const Overlay = styled(motion.div)<any>`
  ${createGrid()}
  display: ${({ $isActive }) => $isActive ? 'grid' : 'none'};
  place-content: center;
  background: ${({ theme, $isActive }) => $isActive ? theme.colors.creme : ''};
  bottom: 0;
  left: 0;
  opacity: 1;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity .3s ${({ theme }) => theme.ease};
  user-select: none;
  z-index: 2000;
  overflow: hidden;

  &.loaded {
    opacity: 0;
  }
`

const _Body = styled(Body)`
  main {
    width: 100%;
    margin: ${getRelative(130, 'mobile')} 0;

    ${mediaQuery.greaterThan('tablet')`
      margin: ${getRelative(130, 'desktop')} 0;
    `}
  }

  .title,
  .legal {
    text-align: center;
    margin: 0 auto;

    ${mediaQuery.greaterThan('tablet')`
      max-width: ${getRelative(435, 'desktop')};
    `}
  }

  .title {
    margin-bottom: ${getRelative(60, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      margin-bottom: ${getRelative(60, 'desktop')};
    `}
  }
`

const variants_overlay = {
  enter: {
    opacity: 0,
    y: '10px',
    transition: { ...getTransition(), delay: .2 }
  },
  animate: {
    opacity: 1,
    y: '0px',
    transition: { ...getTransition(),
    delay: .2 }
  },
  exit: {
    opacity: 0,
    y: '10px',
    transition: { ...getTransition() }
  }
}

export const modalContactId = 'modal-contact'
export const ModalContact = () => {
  const { isActive, close } = useModal(modalContactId)
  const isMount = useMount()
  const { locales: { modales: { contact } } } = useI18n()

  return (
    isMount && <AnimatePresence exitBeforeEnter>
      <Overlay $isActive={isActive} initial='enter' exit='exit' animate={isActive ? 'animate' : 'exit'} variants={variants_overlay} key={`${isActive}`}>
        <Header onClick={close} />
        <_Body isActive={isActive}>
          <Main>
            <p dangerouslySetInnerHTML={{ __html: contact.title }} className='title'/>
            <FormContact inModal={true} />
          </Main>
        </_Body>
      </Overlay>
    </AnimatePresence>
  )
}
