"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.article = void 0;
var article = function (data) {
    if (!data)
        return null;
    return JSON.stringify(__assign(__assign({ "@context": "https://schema.org", "@type": "NewsArticle", "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://google.com/article"
        }, "headline": "".concat(data.title) }, (data.subtitle && { "description": "".concat(data.subtitle) })), { "image": data.contents.map(function (content) {
            var _a, _b, _c, _d, _e;
            if (content.columns[0].type === 'media') {
                var image = (_e = (_d = (_c = (_b = (_a = content.columns) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.media) === null || _c === void 0 ? void 0 : _c.default) === null || _d === void 0 ? void 0 : _d['2x']) === null || _e === void 0 ? void 0 : _e.regular;
                if (image)
                    return "".concat(image);
            }
        }).filter(Boolean), 
        // "datePublished": "2021-02-05T08:00:00+08:00",
        // "dateModified": "2021-02-05T09:20:00+08:00",
        "author": {
            "@type": "Person",
            "name": "Nexia Team"
        }, "publisher": {
            "@type": "Organization",
            "name": "Nexia",
            "logo": {
                "@type": "ImageObject",
                "url": "https://s3-eu-west-1.amazonaws.com/production.storage.hellomamma.com/assets/logo-hellomamma-2x.png"
            }
        } }));
};
exports.article = article;
