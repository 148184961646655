import { memo } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useModal, modalContactId } from '@/modals'
import { mediaQuery, createGrid, getRelative, getButtonUnderline, getTransition, getP50, getP18 } from '@/styles'

interface IHeaderContent {
  data: any
}

const titleVariants = {
  initial: {
    y: '20px',
    opacity: 0,
    transition: {
      ...getTransition()
    }
  },
  animateText: {
    y: '0px',
    opacity: 1,
    transition: {
      ...getTransition(),
      ease: [.32,0,.29,.99],
      duration: .5,
      staggerChildren: 2
    }
  },
  exit: {
    y: '20px',
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .5
    }
  },
}

const Header = styled(motion.header)`
  ${createGrid()}
  grid-row-gap: ${getRelative(40, 'mobile')};
  padding-top: ${getRelative(90, 'mobile')};
  padding-bottom: ${getRelative(62, 'mobile')};

  ${mediaQuery.greaterThan('tablet')`
    grid-row-gap: ${getRelative(60, 'desktop')};
    padding-top: ${getRelative(140, 'desktop')};
    padding-bottom: ${getRelative(90, 'desktop')};
  `}

  h1 {
    ${getP50()}
    grid-area: 1 / 1 / span 1 / span 6;

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 1 / 4 / span 1 / span 6;
      margin: 0 ${getRelative(26, 'desktop')};
    `}
  }

  p {
    ${getP18()}
    grid-area: 2 / 1 / span 1 / span 6;
    text-align: center;
    opacity: .4;

    ${mediaQuery.greaterThan('tablet')`
      grid-area: 2 / 5 / span 1 / span 4;
      margin: 0 ${getRelative(21, 'desktop')};
    `}
  }

  a {
    text-decoration: none;
    ${getButtonUnderline()}
  }
`

export const HeaderContent = memo<IHeaderContent>(({ data }) => {
  const { open } = useModal(modalContactId)
  const { title, subtitle } = data

  return (
    <Header variants={titleVariants} initial='initial' exit='exit' animate='animateText'>
      <h1>{title}</h1>
      <p dangerouslySetInnerHTML={{ __html: subtitle }} />
    </Header>
  )
})
