import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import Link from 'next/link'
import { motion, useAnimation } from 'framer-motion'
import styled from 'styled-components'
import { mediaQuery, getRelative, getP50 } from '@dy/commons/styles'
import { Figure as FigureBase } from '@dy/commons/components'
import { parserMedia } from '@dy/commons/utils'
import { imgVariants } from '../../commons'
import { ContentVideo } from './'

const Figure = styled(FigureBase)`
  height: ${getRelative(233, 'mobile')};
  width: 100%;

  ${mediaQuery.greaterThan('tablet')`
    height: ${getRelative(810, 'desktop')};
  `}

  > span {
    position: relative !important;
    height: 100% !important;
  }

  img {
    height: 100% !important;
    object-fit: cover;
    position: relative !important;
    width: 100% !important;
  }
`

const Div = styled.div`
  color: white;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  top: 50%;

  p {
    font-weight: inherit;
    text-align: center;
  }
`

const Wrapper = styled.div`
  overflow: hidden;
  height: fit-content;
  background-color: #EFEFEF;
  width: inherit;
  height: inherit;

  > div {
    width: inherit;
    height: inherit;
    position: relative;
  }

  a {
    width: 100%;
    height: 100%;
  }

  figure {
    img {
      transition: 1000ms transform ${({ theme: { ease } }) => ease};
    }
  }

  &:hover {
    a img {
      transform: scale(1.1);
    }
  }

  .text-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    > span {
      color: white;
      ${getP50()}
    }
  }
`

export const ContentFullScreen = ({ data }) => {
  const { media, text, link_path, link_title } = data
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: '0px 0px 0px 0px'
  })

  useEffect(() => {
    if(inView) {
      controls.start('animate')
    }
  }, [inView])

  return (
    <>
      {media.type === 'video' ?
        <ContentVideo data={data} /> :
        link_path ?
          <Wrapper>
            <Link scroll={false} href={link_path}>
              <a>
                <motion.div ref={ref} variants={imgVariants} animate={controls} initial={'initial'}>
                  <Figure media={parserMedia(media)} lazy={false} />
                  {(!text && link_title) &&
                    <div className='text-wrapper'>
                      <span>{link_title}</span>
                    </div>
                  }
                </motion.div>
              </a>
            </Link>
          </Wrapper> :
          <Wrapper>
            <motion.div ref={ref} variants={imgVariants} animate={controls} initial={'initial'}>
              <Figure media={parserMedia(media)} lazy={false} />
              {(!text && link_title) &&
                <div className='text-wrapper'>
                  <span>{link_title}</span>
                </div>
              }
            </motion.div>
          </Wrapper>
      }
      <Div dangerouslySetInnerHTML={{ __html: text }}/>
    </>
  )
}
