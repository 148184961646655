import { memo, useMemo } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { mediaQuery, getRelative, getButtonUnderline, getP18 } from '@/styles'

const Section = styled.section`
  grid-area: 2 / 1 / span 1 / span 6;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${getRelative(23, 'mobile')};
  grid-row-gap: ${getRelative(40, 'mobile')};

  a {
    ${getP18()}
    ${getButtonUnderline()}
  }

  .one {
    grid-column-start: 1;
  }
  .two {
    grid-column-start: 2;
  }
  .three {
    grid-column-start: 1;
    grid-row-start: 2;
  }

  ${mediaQuery.greaterThan('tablet')`
    grid-area: 1 / 7 / span 1 / span 6;
    grid-template-columns: ${getRelative(205, 'desktop')} ${getRelative(205, 'desktop')} ${getRelative(205, 'desktop')};
    grid-column-gap: ${getRelative(26, 'desktop')};
    grid-row-gap: 0;
    padding: ${getRelative(128, 'desktop')} 0 0 ${getRelative(13, 'desktop')};

    .one {
      grid-column-start: 1;
    }
    .two {
      grid-column-start: 2;
    }
    .three {
      grid-column-start: 3;
      grid-row-start: 1;
    }
  `}
`

interface ISectionPages {
  data: any
}

const getPagesLink = (pages) => pages.map((singlePage, idx) => {
  const { title, url } = singlePage
  if(!title || title === 'FAQs') return null

  const _url = typeof url === 'string' ? url : url ? `/${url.page}${url.slug ? '/' + url.slug: ''}` : ''
  return (
    <li key={idx}>
      <Link scroll={false} href={_url}>
        <a title={title}>
          {title}
          <span />
        </a>
      </Link>
    </li>
  )
})

export const SectionPages = memo(({ data }:ISectionPages) => {
  const { cols } = data
  const ColumnOne = useMemo(() => getPagesLink(cols[0]), [cols[0]])
  const ColumnTwo = useMemo(() => getPagesLink(cols[1]), [cols[1]])
  const ColumnThree = useMemo(() => getPagesLink(cols[2]), [cols[2]])

  return (
    <Section>
      <ul className='one'>
        {ColumnOne}
      </ul>
      <ul className='two'>
        {ColumnTwo}
      </ul>
      <ul className='three'>
        {ColumnThree}
      </ul>
    </Section>
  )
})
