const DY_CMS_URL = process.env.DY_CMS_URL
const DY_API_URL = `${DY_CMS_URL}${process.env.DY_API_VERSION}`

type TypeParams = {
  locale?: string,
  slug?: string,
  customer_id?: string | number,
  category_id?: string,
}

interface ICallAPI {
  type: string,
  params?: TypeParams,
  body?: any,
  accept?: string,
  contentType?: string,
  query?: any,
}


// timestamp = Math.floor((Date.now() / (1000 * 60 * 60 * 24)))
export const URLS = (params: TypeParams, query) => ({
  'layout': {
    url: `${DY_API_URL}/${params.locale}/layout`,
    method: 'GET'
  },
  'downloads': {
    url: `${DY_API_URL}/${params.locale}/pages/downloads`,
    method: 'GET'
  },
  'home': {
    url: `${DY_API_URL}/${params.locale}/pages/home`,
    method: 'GET'
  },
  'pages': {
    url: `${DY_API_URL}/${params.locale}/pages`,
    method: 'GET'
  },
  'page': {
    url: `${DY_API_URL}/${params.locale}/pages/${params.slug}`,
    method: 'GET'
  },
  'products-categories': {
    url: `${DY_API_URL}/${params.locale}/categories/product`,
    method: 'GET'
  },
  // NOT US INCLUDED
  'products-category': {
    url: `${DY_API_URL}/${params.locale}/categories/product/${params.slug}.json`,
    method: 'GET'
  },
  'usa-products-category': {
    url: `${DY_API_URL}/${params.locale}/categories/product/all/US.json`,
    method: 'GET'
  },
  'usa-products': {
    url: `${DY_API_URL}/${params.locale}/products?usa=true`,
    method: 'GET'
  },
  'usa-product': {
    url: `${DY_API_URL}/${params.locale}/products/${params.slug}?usa=true`,
    method: 'GET'
  },
  'products': {
    url: `${DY_API_URL}/${params.locale}/products`,
    method: 'GET'
  },
  'product': {
    url: `${DY_API_URL}/${params.locale}/products/${params.slug}`,
    method: 'GET'
  },
  'projects-categories': {
    url: `${DY_API_URL}/${params.locale}/categories/project`,
    method: 'GET'
  },
  'projects-category': {
    url: `${DY_API_URL}/${params.locale}/categories/project/${params.slug}`,
    method: 'GET'
  },
  'search': {
    url: `${DY_API_URL}/${params.locale}/search?q=${query.q}`,
    method: 'GET'
  },
  'search-products': {
    url: `${DY_API_URL}/${params.locale}/products/search?q=${query.q}`,
    method: 'GET'
  },
  'projects': {
    url: `${DY_API_URL}/${params.locale}/projects`,
    method: 'GET'
  },
  'project': {
    url: `${DY_API_URL}/${params.locale}/projects/${params.slug}`,
    method: 'GET'
  },
  'distributor': {
    url: `${DY_API_URL}/${params.locale}/stores/${params.slug}`,
    method: 'GET'
  },
  'online': {
    url: `${DY_API_URL}/${params.locale}/stores/${params.slug}`,
    method: 'GET'
  },
  'configurator': {
    url: `${DY_API_URL}/${params.locale}/products/${params.slug}/variant?options=${query.ids}`,
    method: 'GET'
  },
  'configurator-color': {
    url: `${DY_API_URL}/${params.locale}/products/${params.slug}/media?colors=${query.ids}`,
    method: 'GET'
  },
  'usa-configurator': {
    url: `${DY_API_URL}/${params.locale}/products/${params.slug}/variant?options=${query.ids}&usa=true`,
    method: 'GET'
  },
  'usa-configurator-color': {
    url: `${DY_API_URL}/${params.locale}/products/${params.slug}/media?colors=${query.ids}&usa=true`,
    method: 'GET'
  },
  'subscribe': {
    url: `${DY_API_URL}/${params.locale}/subscribers`,
    method: 'POST'
  },
  'newsletter-2022': {
    url: `${DY_API_URL}/${params.locale}/subscribers/newsletter`,
    method: 'POST'
  },
  'contact-us-2022': {
    url: `${DY_API_URL}/${params.locale}/subscribers/contact`,
    method: 'POST'
  },
  'countries': {
    url: '/locales/countries.json',
    method: 'GET'
  }
})

const urlFor = ({ type,  body,  params,  accept, contentType, query }): [string, any] => {
  if (!URLS(params, query)[type]) {
    console.warn(`api/urls.ts -> No URL of type ${type}`)
    throw new Error(`api/urls.ts -> No URL of type ${type}`)
  }
  const { url, method } = URLS(params, query)[type]
  const options = {
    ...(method === 'GET' && { cache: 'no-cache' }), // *default, no-cache, reload, force-cache, only-if-cached
    method,
    body: method === 'GET' ? null : contentType === 'application/json' ? JSON.stringify(body) : body,
    headers: {}
  }

  if(accept) options.headers['Accept'] = accept
  if (contentType) options.headers['Content-Type'] = contentType
  return [url, options]
}


const defaultParams = {
  locale: process.env.NEXT_PUBLIC_LOCALE
}


export const callAPI = async ({ type, body = {}, params = defaultParams, accept = 'application/json', contentType = 'application/json', query = {} }: ICallAPI): Promise<[any, boolean]> => {
  // console.log('callAPI params', params)

  try {
    const [url, options] = urlFor({
      type,
      body,
      params,
      accept,
      contentType,
      query
    })

    const res: Response = await fetch(url, options)

    if (res.status == 204) return [null, false]
    const json = await res.json()
    return [json, !res.ok]

  } catch (error) {
    return [{status: error.status, message: error.message || error.title, error: 'server_error'}, true]
  }
}
