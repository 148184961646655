export * from './Select'
export * from './Input'
export * from './Input2'
export * from './InputSelect'
export * from './RadioCheckBoxes'
export * from './FileUpload'

export const INPUT_PATTERNS = {
  'password': /(?=.*[a-zA-Z])((?=.*[0-9])|(?=.*([[:print:]][^[[:alnum:]]]))).{8,}/,
  'email': /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
  'phone': /([+][3][4][679]{1}[0-9]{8})/,
  'password_message': 'Debe tener entre más de 6 caracteres, y debe incluir al menos un número y una letra',
  'email_message': 'Por favor, introduzca un email válido',
  'phone_message': 'Por favor, introduzca un teléfono español sin espacios (p.e. +346xxxxxxxx)'
}
