import { memo } from 'react'
import styled from 'styled-components'
import { AnimateSharedLayout } from 'framer-motion'
import { useScrollViewer } from '@/hooks'
import { mediaQuery, getRelative } from '@/styles'
import { useRecoilState } from 'recoil'
import { navbarColorState } from '@/recoil'
import { Dropdown } from '../commons/dropdown'
import { useEffect } from 'react'

interface IAnchorsPoints {
  anchors: any,
  className?: string,
  currentAnchor: string | null | any,
  setAnchor: any,
}

const AnchorsPointsStyled = styled.div`
  display: none;

  &.light {
    span {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  ${mediaQuery.greaterThan('tablet')`
    top: ${getRelative(25, 'desktop')};
    padding-left: ${getRelative(130, 'desktop')};
    z-index: 1001;
    position: sticky;
    display: block;
    height: 0;
  `}

  .detail-dropdown {
    ul {
      ${mediaQuery.greaterThan('tablet')`
        margin-top: ${getRelative(32, 'desktop')};
      `}
    }
  }
`

interface IAnchor {
  id: string,
  top?: number
}

const AnchorStyled = styled.div<any>`
  position: absolute;
  top: ${({ top }) => top ? top : -160}px;
  pointer-events: none;
  opacity: 0;
  user-select: none;
`

export const Anchor = memo<IAnchor>(({ id, top = null })  => {
  return <AnchorStyled top={top} id={id} />
})

export const AnchorsPoints = memo<IAnchorsPoints>(({ anchors, className = '', currentAnchor, setAnchor }) => {
  const [navbarColor] = useRecoilState(navbarColorState)
  const scrollState = useScrollViewer(5)

  useEffect(() => {
    document.querySelector('html').classList.add('anchor-behave')

    return () => {
      document.querySelector('html').classList.remove('anchor-behave')
    }
  }, [])

  return (
  <AnchorsPointsStyled className={`${className} anchorpoints ${scrollState !== 'TOP' ? '' : navbarColor}`}>
    <AnimateSharedLayout>
      <Dropdown className='detail-dropdown' trigger={'Escoge tamaño:'} options={anchors} currentValue={currentAnchor} onChange={setAnchor} textColor={navbarColor}/>
    </AnimateSharedLayout>
  </AnchorsPointsStyled>)
})
