"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.collection = void 0;
var collection = function (data) {
    var seo = data.seo, projects = data.projects, locale = data.locale, slug = data.slug;
    var collectionPage = {
        "@context": "http://schema.org",
        "@type": "CollectionPage",
        "url": "https://nexia.es/projects/".concat(slug[locale]),
        "name": seo.name,
        "description": seo.description,
        "mainEntity": {
            "@type": "ItemList",
            "ItemListElement": [
                projects.map(function (item, idx) { return ({
                    "@type": "ItemPage",
                    "position": "".concat(idx + 1),
                    "url": "https://nexia.es/".concat(locale === 'es' ? '' : "".concat(locale, "/"), "project/").concat(item.slug[locale])
                }); })
            ]
        }
    };
    return JSON.stringify(collectionPage);
};
exports.collection = collection;
