"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Picture = exports.createImageMediaType = exports.createSVGMediaType = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var schema_1 = require("../../schema");
var styles_1 = require("../../styles");
var _1 = require("./");
var Video_1 = require("./Video");
var getMediaQuery = function (min, max) { return "(".concat(min ? 'min' : 'max', "-width: ").concat(min > 1000 ? '1440' : min ? min : max, "px)"); };
var getAllMediaQueries = function (data) { return data.map(function (_a) {
    var _b = _a.mq, min = _b.min, max = _b.max, size = _a.size;
    return (min < 1440 ? { mq: getMediaQuery(min, max), size: { height: (0, styles_1.getRelative)(size.height, min ? 'desktop' : 'mobile'), width: (0, styles_1.getRelative)(size.width, min ? 'desktop' : 'mobile') } } : {});
}).reverse(); };
var renderSources = function (data, contentType) { return data.map(function (source, idx) {
    var _a = source.mq, min = _a.min, max = _a.max;
    var mq = getMediaQuery(min, max);
    return ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("source", { type: "image/webp", media: mq, srcSet: "".concat(source['2x'].compressed) }), (0, jsx_runtime_1.jsx)("source", { type: contentType ? contentType : 'image/jpg', media: mq, srcSet: "".concat(source['2x'].regular) })] }, idx));
}); };
var createSVGMediaType = function (_a) {
    var alt = _a.alt, url = _a.url, size = _a.size;
    return ({
        type: schema_1.MediaType.svg,
        url: url,
        alt: alt,
        size: size,
    });
};
exports.createSVGMediaType = createSVGMediaType;
var createImageMediaType = function (_a) {
    var alt = _a.alt, url = _a.url, size = _a.size;
    return ({
        type: schema_1.MediaType.image,
        url: url,
        alt: alt,
        size: size,
    });
};
exports.createImageMediaType = createImageMediaType;
var getSpecificSize = function (size, relativeScreen, relative) { return !relative ? size + 'px' : (0, styles_1.getRelative)(size, relativeScreen) + 'vw'; };
var getSize = function (data, relative) {
    var size = data.size ? data.size : data.default.size;
    var mobile = size.mobile, desktop = size.desktop;
    return {
        mobile: {
            height: getSpecificSize(mobile ? mobile.height : size.height, 414, relative),
            width: getSpecificSize(mobile ? mobile.width : size.width, 414, relative)
        },
        desktop: {
            height: getSpecificSize(desktop ? desktop.height : size.height, 1440, relative),
            width: getSpecificSize(desktop ? desktop.width : size.width, 1440, relative)
        }
    };
};
var defaultSize = {
    mobile: { height: '100%', width: '100%' },
    desktop: { height: '100%', width: '100%' }
};
exports.Picture = (0, react_1.memo)((0, react_1.forwardRef)(function (_a, ref) {
    var _b = _a.initialInView, initialInView = _b === void 0 ? false : _b, _c = _a.absoluteSize, absoluteSize = _c === void 0 ? false : _c, _d = _a.className, className = _d === void 0 ? '' : _d, data = _a.data, _e = _a.lazyLoading, lazyLoading = _e === void 0 ? true : _e, _f = _a.relative, relative = _f === void 0 ? true : _f, _g = _a.cancelAnimation, cancelAnimation = _g === void 0 ? false : _g, _h = _a.onLoad, onLoad = _h === void 0 ? null : _h, _j = _a.onClick, onClick = _j === void 0 ? null : _j;
    var type = data.type, content_type = data.content_type;
    (0, react_1.useEffect)(function () {
        if (!lazyLoading)
            onLoad && onLoad();
    }, []);
    if (!data)
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
    if (type === 'video')
        return ((0, jsx_runtime_1.jsx)(Video_1.Video, { data: data }));
    var isLocalImage = (!data.default && type === 'image');
    var size = (0, react_1.useMemo)(function () { return absoluteSize ? data.size : type === 'svg' || isLocalImage ? data.size ? getSize(data, relative) : defaultSize : null; }, [data]);
    if (type === 'svg' || isLocalImage) {
        return ((0, jsx_runtime_1.jsx)(_1.FigureOLD, __assign({ initialInView: initialInView, outref: ref, lazyLoading: lazyLoading, className: className, size: size }, { children: (0, jsx_runtime_1.jsx)("picture", { children: (0, jsx_runtime_1.jsx)(_1.ImageLoader, { lazy: lazyLoading ? 'lazy' : 'auto', animation: !cancelAnimation, src: data.url, alt: data.alt }) }) })));
    }
    var retina = data.default['2x'];
    var mediaQueries = getAllMediaQueries(data.sources);
    var _k = __read((0, react_1.useState)("".concat(data.default['1x'].regular)), 2), src = _k[0], setSrc = _k[1];
    var _l = __read((0, react_1.useState)(data.sources), 2), sources = _l[0], setSources = _l[1];
    var Sources = (0, react_1.useMemo)(function () { return renderSources(sources, content_type); }, [sources]);
    return ((0, jsx_runtime_1.jsxs)(_1.FigureOLD, __assign({ initialInView: initialInView, outref: ref, lazyLoading: lazyLoading, className: className, mediaQueries: mediaQueries }, { children: [(0, jsx_runtime_1.jsxs)("picture", { children: [Sources, (0, jsx_runtime_1.jsx)(_1.ImageLoader, { onClick: onClick, onLoad: lazyLoading ? onLoad : null, src: src, retina: retina, alt: data.alt })] }), data.media_caption && (0, jsx_runtime_1.jsx)("figcaption", { children: data.media_caption })] })));
}));
