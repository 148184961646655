import { getTransition } from '@/styles'

export const imgVariants = {
  initial: {
    scale: 1.05,
    opacity: 0,
    transition: {
        ...getTransition()
    }
  },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
        ...getTransition(),
        ease: [0.32, 0, 0.29, 0.99],
        delay: .3,
        duration: 1
    }
  },
  exit: {
    scale: 1.05,
    opacity: 0,
    transition: {
        ...getTransition(),
        duration: .2
    }
  },
}

export const staggerWrapperVariants = {
    animate: {
        transition: {
            staggerChildren: 0.5,
        }
    },
}
  
export const staggerChildVariants = {
    initial: {
        y: '20px',
        opacity: 0,
        transition: {
            ...getTransition()
        }
    },
    animate: {
        y: '0px',
        opacity: 1,
        transition: {
            ...getTransition(),
            ease: [.32,0,.29,.99],
            duration: .5,
        }
    },
}

export const titleVariants = {
    initial: {
        y: '20px',
        opacity: 0,
        transition: {
            ...getTransition()
        }
    },
    animate: {
        y: '0px',
        opacity: 1,
        transition: {
            ...getTransition(),
            ease: [.32,0,.29,.99],
            delay: .5,
            duration: 1
        }
    },
    exit: {
        y: '20px',
        opacity: 0,
        transition: {
            ...getTransition(),
            duration: .5
        }
    }
}

export const basicVariantsProps = {
    'initial': 'initial',
    'animate': 'animate',
    'exit': 'exit',
}