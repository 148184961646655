"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSlider = exports.SLIDE_TIME = void 0;
var react_1 = require("react");
var react_intersection_observer_1 = require("react-intersection-observer");
exports.SLIDE_TIME = 5000;
var useSlider = function (maxSize, no_auto) {
    if (no_auto === void 0) { no_auto = false; }
    var _a = __read((0, react_1.useState)(0), 2), currentSlide = _a[0], setSlide = _a[1];
    var TIMEOUT_ID = (0, react_1.useRef)(null);
    var _b = __read((0, react_intersection_observer_1.useInView)({
        rootMargin: '0px',
        threshold: 0,
    }), 2), ref = _b[0], inView = _b[1];
    var nSlides = (0, react_1.useMemo)(function () { return maxSize; }, [maxSize]);
    var tick = (0, react_1.useCallback)(function (current) {
        TIMEOUT_ID.current && clearTimeout(TIMEOUT_ID.current);
        var newSlideIndex = current + 1;
        var nextSlide = newSlideIndex < 0 ? nSlides - 1 : newSlideIndex % nSlides;
        TIMEOUT_ID.current = setTimeout(function () { return tick(nextSlide); }, exports.SLIDE_TIME);
        setSlide(nextSlide);
    }, [nSlides]);
    var startAnimation = (0, react_1.useCallback)(function () {
        tick(currentSlide);
    }, [currentSlide, tick]);
    var stopAnimation = (0, react_1.useCallback)(function () {
        TIMEOUT_ID.current && clearTimeout(TIMEOUT_ID.current);
    }, []);
    var nextSlide = (0, react_1.useCallback)(function () {
        tick(currentSlide);
    }, [currentSlide, tick]);
    var prevSlide = (0, react_1.useCallback)(function () {
        tick(currentSlide - 2);
    }, [currentSlide, tick]);
    (0, react_1.useEffect)(function () {
        if (inView && !no_auto) {
            TIMEOUT_ID.current && clearTimeout(TIMEOUT_ID.current);
            TIMEOUT_ID.current = setTimeout(function () { return startAnimation(); }, exports.SLIDE_TIME);
        }
        else {
            TIMEOUT_ID.current && clearTimeout(TIMEOUT_ID.current);
            TIMEOUT_ID.current = null;
        }
        return function () {
            TIMEOUT_ID.current && clearTimeout(TIMEOUT_ID.current);
        };
    }, [inView]);
    return { currentSlide: currentSlide, triggerRef: ref, nextSlide: nextSlide, prevSlide: prevSlide, setSlide: setSlide, startAnimation: startAnimation, stopAnimation: stopAnimation };
};
exports.useSlider = useSlider;
