import styled from 'styled-components'
import { mediaQuery, getRelative, createGrid } from '@/styles'
import { SectionRrss } from './SectionRrss'
import { CallUs } from './CallUs'
import { PreFooter } from './PreFooter'
import { SectionPages } from './SectionPages'

const FooterStyled = styled.footer`
  ${createGrid()}
  background-color: ${({ theme }) => theme.colors.creme};

  ${mediaQuery.greaterThan('tablet')`
    grid-row-gap: ${getRelative(98, 'desktop')};
  `}
`
interface IFooter {
  data: any
}

export const Footer = ({ data }:IFooter) => {
  return (
    <>
      <PreFooter />
      <FooterStyled>
        <CallUs />
        <SectionPages data={data} />
        <SectionRrss />
      </FooterStyled>
    </>
  )
}
