import {  useState, useRef, useEffect } from 'react'
import type { ChangeEvent, ReactNode } from 'react'
import type { UseFormRegister as TypeRegister, FieldError, RegisterOptions } from 'react-hook-form'
import styled from 'styled-components'


type onChangeType = (event: ChangeEvent<HTMLInputElement>) => void

interface iInput {
  readonly type?: string
  readonly name: string
  readonly label?: string
  placeholder?: string
  defaultValue?: string | number | string[] | null
  readonly required?: boolean | RegisterOptions
  readonly disabled?: boolean
  readonly autoComplete?: string
  readonly readOnly?: boolean
  readonly onChange?: onChangeType
  readonly register?:TypeRegister<any>
  error?: FieldError | any
  readonly icon?: string | boolean | ReactNode
  readonly className?: string
  readonly placeholderVisible?: boolean

}

interface iLabelProps {
  $hasContent: boolean
  $hidden: boolean
  $hasError?: boolean
  $placeholderVisible: boolean
  $buttonIcon: boolean

}



const Label = styled.label<any>`
  

`


export const noRegister = () => ({ onChange: null, onBlur: null, ref: null, name: null })
// Attributes https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input

export const Input = ({ type = 'text', name = '', defaultValue = '', label, autoComplete, placeholder, placeholderVisible = false, required = false, disabled = false, readOnly = null, onChange = null,  register = noRegister, icon, className,  error }:iInput):JSX.Element => {
  const requireProps:RegisterOptions = typeof required === 'boolean' ? { required } : required
  const { onChange:registerOnChange, onBlur:registerOnBlur, name:registerName, ref:registerRef } = register(name, requireProps)
  const ref = useRef<HTMLInputElement | null>(null)

  const [inputType, setInputType] = useState(type)
  const [inputValue, setInputValue] = useState(defaultValue)

  const handleChange = e => {
    registerOnChange && registerOnChange(e)
    onChange && onChange(e)
    setInputValue(e?.target?.value)
  }

  const handleBlur = e => {
    registerOnBlur && registerOnBlur(e)
    setInputValue(e?.target?.value)
  }

  // const ButtonIcon = useMemo(() => getIcon(icon, type, setInputType), [icon, type])

  useEffect(() => {
    if(error) console.log(`input ${name} error`, error)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])


  return (
    <Label $hasContent={!!inputValue} $hidden={inputType === 'hidden'} $placeholderVisible={placeholderVisible}  $hasError={!!error} {...className && { className }}>
      <input type={inputType} name={registerName ?? name} disabled={disabled} readOnly={readOnly} placeholder={placeholder ?? label} onChange={handleChange} onBlur={handleBlur} aria-invalid={!!error}
        {...typeof required === 'boolean' && { required }}
        ref={(e) => {
          ref.current = e
          registerRef && registerRef(e)
        }}
        {...autoComplete && { autoComplete }}
        {...defaultValue && { defaultValue }}
        {...(!defaultValue && !registerRef && inputValue && { value: inputValue })}
      />
      {label && <span>{label}</span>}
      {/* {ButtonIcon && ButtonIcon} */}
      {error?.message && <span role='alert'>{error.message}</span>}
    </Label>
  )
}
