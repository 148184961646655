import { memo, useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { getTransition, mediaQuery, getRelative } from '@/styles'
import { TransitionSlider } from '../commons'
import { useMount } from '@dy/commons/hooks'

export const Overlay = styled.div`
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  opacity: 0;
  background-color: rgba(0,0,0,.6);
  user-select: none;
  pointer-events: none;
  transition: opacity 300ms ${({ theme }) => theme.ease};
  z-index: 1000;

  &.active {
    opacity: 1;
    pointer-events: initial;
    user-select: initial;
  }
`

const Aside = styled(motion.aside)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.background};
  top: 0;
  left: 0;
  z-index: 10000;
  opacity: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  button.close {
    top: 0;
    right: 0;
    position: absolute;

    ${mediaQuery.greaterThan('tablet')`
      padding: ${getRelative(30, 'desktop')};
    `}
  }

  .transition-slider {
    width: 100%;

    figure {
      display: grid;
      place-items: center;
      margin: auto;

      > span {
        position: absolute !important;
      }
    }

    img {
      object-fit: contain;
      margin: 0px auto !important;
    }  

    ${mediaQuery.greaterThan('tablet')`
      figure {
        width: 85vw;
        height: 90vh;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    `}
  }
`

const textVariants: any = {
  active: {
    opacity: 1,
    y:'0px',
    userSelect: 'initial',
    pointerEvents: 'initial',
    transition: {
      ...getTransition()
    }
  },
  inactive: {
    opacity: 0,
    y:'-20px',
    userSelect: 'none',
    pointerEvents: 'none',
    transition: {
      ...getTransition()
    }
  },
}

interface IZoom {
  data: any,
  onClick: any,
  isActive: boolean,
}

export const Zoom = memo<IZoom>(({ data, isActive, onClick }) => {
  const isMounted = useMount()
  const ref = useRef(null)

  useEffect(() => {
    if (!isMounted) return
    if (isActive) {
      disableBodyScroll(ref.current)
    } else {
      enableBodyScroll(ref.current);
    }

    return () => clearAllBodyScrollLocks()
  }, [isActive, isMounted])

  const [parentState, setParentState] = useState();

  const wrapperSetParentState = useCallback(val => {
    setParentState(val);
  }, [setParentState]);

  return (
    <>
      <Aside ref={ref} variants={textVariants} animate={isActive ? 'active' : 'inactive'}>
        <TransitionSlider zoom={true} data={data} parentState={parentState} parentStateSetter={wrapperSetParentState} />
        <button className='close' onClick={onClick}>
          <img alt='close' src={'/images/svg/close.svg'}/>
        </button>
      </Aside>
      <Overlay className={isActive ? 'active' : ''} onClick={onClick} />
    </>
  )
})
